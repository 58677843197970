import styled from "styled-components"
import { useEffect } from "react";

export const MainCard = ({ id, image, name, param1, param2, param3, price, priceButton = false }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CardWrapper>
            <ProductItem>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details>{param1}</Details>}
                    {param2 && <Details>{param2}</Details>}
                    {param3 && <Details>{param3}</Details>}
                    <Margin>
                        {price && <Price>Від {price} грн.</Price>}</Margin>
                        {priceButton && priceButton}
                </div>
            </ProductItem>
        </CardWrapper>
    );
};

export const Main_Rolet_Card = ({ id, image, name, param1, price, priceButton = false }) => {
    return (
        <CardWrapper>
            <ProductItem>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details style={{ marginBottom: '10px' }}>{param1}</Details>}
                    <div >{price && <Price>{price} грн/м²</Price>}</div>
                    {priceButton && priceButton}               
                     </div>
            </ProductItem>
        </CardWrapper>
    );
};
export const Vidlivy_Card = ({ id, image, name, param1, price, priceButton = false }) => {
    return (
        <CardWrapper>
            <ProductItemVidlivy>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details style={{ marginBottom: '10px' }}>{param1}</Details>}
                    <div >{price && <Price>{price} грн/м²</Price>}</div>
                    {priceButton && priceButton}               
                     </div>
            </ProductItemVidlivy>
        </CardWrapper>
    );
};

export const MainCardSmall = ({ id, image, name, param1, param2, param3, price, priceButton = false}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CardWrapper>
            <ProductItemSmall>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details>{param1}</Details>}
                    {param2 && <Details>{param2}</Details>}
                    {param3 && <Details>{param3}</Details>}
                        {price && <Price>{price} грн/м²</Price>}</div>
                        {priceButton && priceButton}
            </ProductItemSmall>
        </CardWrapper>
    );
};

export const MainWindowsillCard = ({ id, image, name, param1, param2, param3, price, priceButton = false }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CardWrapper>
            <ProductItemWindowsill>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details>{param1}</Details>}
                    {param2 && <Details>{param2}</Details>}
                    {param3 && <Details>{param3}</Details>}
                    <Margin>
                        {price && <Price>Від {price} грн.</Price>}</Margin>
                        {priceButton && priceButton}
                </div>
            </ProductItemWindowsill>
        </CardWrapper>
    );
};

export const CardWithoutButton = ({ id, image, name, param1, param2, price }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CardWrapper>
            <ProductItemFurnitura>
                <ImageWrapper><Image src={image} /></ImageWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Name>{name}</Name>
                    {param1 && <Details>{param1}</Details>}
                    {param2 && <Details>{param2}</Details>}
                    <Margin>
                        {price && <Price>Вартість: {price} грн.</Price>}</Margin>
                </div>
            </ProductItemFurnitura>
        </CardWrapper>
    );
};
const CardWrapper = styled.div`
    width: 300px;
    margin: 0 5px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
    width: 185px;
    margin-bottom: 0px;
    margin-right: 0px;
    }
`;
 const ProductItem = styled.div`
    display: flex;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 20px;
    padding: 16px;
    &:last-child {
    margin-right: 0px;}
    @media (max-width: 768px) {
    margin-right: 10px;
    padding: 8px 0px 8px 8px;
    }
`
const ProductItemSmall = styled(ProductItem)`
height: 480px;
@media (max-width: 768px) {
    height: 330px;
    }
`
const ProductItemVidlivy = styled(ProductItem)`
@media (max-width: 768px) {
    height: 305px;
    }
`
const ProductItemWindowsill = styled(ProductItem)`
@media (max-width: 768px) {
    height: 340px;
    }
`
const ProductItemFurnitura = styled(ProductItem)`
height: 445px;
padding: 5px;
@media (max-width: 768px) {
    height: 340px;
    }
`

export const CardHeight = styled.div`
height: 445px;
@media (max-width: 768px) {
    height: 340px;
    }
`
const Image = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    width: -webkit-fill-available;
`
export const Name = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #5A5B5D;
    margin-top: 16px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 6px;
    }  
`
const Margin = styled.div`
    margin-bottom: 10px;
    @media (max-width: 768px) {
    margin-bottom: 6px;
    }  
`
const Details = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #595757;
    margin-bottom: 2px;
    &:last-child{
        margin-bottom:10px
    }
    @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
    }
`
const Price = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 48px;
    color: #5A5B5D;
    @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    }
`
const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 240px;
    @media (max-width: 768px) {
    height: 120px;
    }
`