import { Header } from "../header/header"
import { useState } from "react";
import { BlockName } from "../shared/blockName/blockName";
import styled from "styled-components";
import { HorizontalInput, VerticalInput } from "./range_input";
import { CallBackForm } from "../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../landing_page/secondary_actions/secondary_actions";
import { Footer } from "../footer/footer";
import { Selector } from "./selector/selector";
import b1 from "../images/calculator/b1.png"

export const Calculator = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(b1);
    const handleOptionClick = (option) => {
        setSelectedImage(option.image);
    };
    return (
        <div>
            <Header />
            <Background>
                <BlockName>Калькулятор вікон</BlockName>
                <CalculatorBlock>
                    <RangeInputBlock>
                        <VerticalInput />
                        <VerticalInputWrapper>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Image>
                                    {selectedImage && (
                                        <ImageSection src={selectedImage} />
                                    )}
                                </Image>
                                <HorizontalInput />
                            </div>
                        </VerticalInputWrapper>
                    </RangeInputBlock>
                    <SelectBlock>
                        <Selector onOptionClick={handleOptionClick}></Selector>
                    </SelectBlock>
                </CalculatorBlock>
                <SecondaryActions></SecondaryActions>
                <CallBackForm></CallBackForm>
                <Footer></Footer>
            </Background>
        </div>
    );
};


const Background = styled.div`
    display:flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
   
`
const RangeInputBlock = styled.div`
    display:flex;
    width: 50%;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 768px) {
    width:100%;
    justify-content:normal;
    flex-direction:row-reverse;
    }
`
const SelectBlock = styled(RangeInputBlock)`
    justify-content: flex-start;
    padding-left: 40px;
    @media (max-width: 768px) {
    padding-left: 0px;
    justify-content: center;
    margin-top: 30px;
    }
`
const Image = styled.div`
    height:450px;
    display:flex;
    justify-content: center;
    @media (max-width: 768px) {
    height: 250px;
    }
`
const CalculatorBlock = styled.div`
    display:flex;
    flex-direction: row;
    margin: 0px 40px 60px 40px;
    @media (max-width: 768px) {
    flex-direction: column;
    margin: 0px 20px 30px 20px;
    align-items: flex-start;
    }
`
const VerticalInputWrapper = styled.div`
    display:flex;
    flex-direction: column ;
    align-items: center ; 
    width: 'calc(100% - 200px)';
    @media (max-width: 768px) {
    width:100%
    }
`
const ImageSection = styled.img`
    width: 100%;
    height: 450px;
    object-fit: contain;
    @media (max-width: 768px) {
    height:250px;
    }
`