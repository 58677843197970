// Column1
export const Im1=({ color })=>(
    <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a1" uk-tooltip="title: Глухе; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M26 27.705L25.295 27L22.5 29.795L19.705 27L19 27.705L21.795 30.5L19 33.295L19.705 34L22.5 31.205L25.295 34L26 33.295L23.205 30.5L26 27.705Z" fill={color} stroke={color}></path>
    </svg>);

export const Im2 = ({color}) => (
  <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a2" uk-tooltip="title: Поворотньо відкидне; pos: right" aria-expanded="false">
    <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2" />
    <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2" />
    <path d="M6 56L38 30.9393L6 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Im3 = ({color}) => (
  <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a3" uk-tooltip="title: Поворотне ліве; pos: right" aria-expanded="false">
    <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2" />
    <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2" />
    <path d="M38 56L6 30.9393L38 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export const Im4 = ({color})=>(
    <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a4" uk-tooltip="title: Поворотньо-відкидне праве; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>
);
export const Im5 = ({color})=>(
    <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a5" uk-tooltip="title: Поворотньо-відкидне ліве; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M37.5 55.5L21.5 7.5L6 56.5L38 6L6 31.5L37.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>
    );

export const Im6 = ({color})=>(
    <svg width="44" height="62" viewBox="0 0 44 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="a6" uk-tooltip="title: Відкидне; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 57L22.0389 6L38 57" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

// Column 2
export const Im7 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b1" uk-tooltip="title: Дві глухі створки; pos: right" aria-expanded="false">
        <path d="M44 62H0V0H44V62ZM2 60H42V2H2V60ZM40 58H4V4H40V58ZM6 56H38V6H6V56ZM26 27.7L25.3 27L22.5 29.8L19.7 27L19 27.7L21.8 30.5L19 33.3L19.7 34L22.5 31.2L25.3 34L26 33.3L23.2 30.5L26 27.7ZM26.7 33.3L23.9 30.5L26.7 27.7L25.3 26.3L22.5 29.1L19.7 26.3L18.3 27.7L21.1 30.5L18.3 33.3L19.7 34.7L22.5 31.9L25.3 34.7L26.7 33.3ZM86 62H42V0H86V62ZM44 60H84V2H44V60ZM82 58H46V4H82V58ZM48 56H80V6H48V56ZM68 27.7L67.3 27L64.5 29.8L61.7 27L61 27.7L63.8 30.5L61 33.3L61.7 34L64.5 31.2L67.3 34L68 33.3L65.2 30.5L68 27.7ZM68.7 33.3L65.9 30.5L68.7 27.7L67.3 26.3L64.5 29.1L61.7 26.3L60.3 27.7L63.1 30.5L60.3 33.3L61.7 34.7L64.5 31.9L67.3 34.7L68.7 33.3Z" fill={color}></path>
    </svg>);
export const Im8 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b2" uk-tooltip="title: Поворотньо-відкидне з глухою створкою справа; pos: right" aria-expanded="false">
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M68 27.705L67.295 27L64.5 29.795L61.705 27L61 27.705L63.795 30.5L61 33.295L61.705 34L64.5 31.205L67.295 34L68 33.295L65.205 30.5L68 27.705Z" fill={color} stroke={color}></path>
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>);
export const Im9 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b3" uk-tooltip="title: Поворотньо-відкидне з глухою створкою зліва; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M26 27.705L25.295 27L22.5 29.795L19.705 27L19 27.705L21.795 30.5L19 33.295L19.705 34L22.5 31.205L25.295 34L26 33.295L23.205 30.5L26 27.705Z" fill={color} stroke={color}></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M79.5 55.5L63.5 7.5L48 56.5L80 6L48 31.5L79.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>);
export const Im10 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b4" uk-tooltip="title: Поворотньо-відкидне с поворотною створкою справа; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 56L48 30.9393L80 6" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>);
export const Im11 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b5" uk-tooltip="title: Поворотньо-відкидне с поворотною створкою; pos: right" aria-expanded="false">
        <path d="M43 2V1H44H84H85V2V60V61H84H44H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M48 5L47 5L47 6L47 56L47 57L48 57L80 57L81 57L81 56L81 6L81 5L80 5L48 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M79.5 55.5L63.5 7.5L48 56.5L80 6L48 31.5L79.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M1 2V1H2H42H43V2V60V61H42H2H1V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 5L5 5L5 6L5 56L5 57L6 57L38 57L39 57L39 56L39 6L39 5L38 5L6 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 56L38 30.9393L6 6" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>);
export const Im12 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b6" uk-tooltip="title: Поворотне з глухою створкою справа; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 56L38 30.9393L6 6" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M68 27.705L67.295 27L64.5 29.795L61.705 27L61 27.705L63.795 30.5L61 33.295L61.705 34L64.5 31.205L67.295 34L68 33.295L65.205 30.5L68 27.705Z" fill={color} stroke={color}></path>
    </svg>);
export const Im13 = ({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b7" uk-tooltip="title: Поворотне з глухою створкою зліва; pos: right" aria-expanded="false">
        <path d="M43 2V1H44H84H85V2V60V61H84H44H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M48 5L47 5L47 6L47 56L47 57L48 57L80 57L81 57L81 56L81 6L81 5L80 5L48 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 56L48 30.9393L80 6" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M1 2V1H2H42H43V2V60V61H42H2H1V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 5L5 5L5 6L5 56L5 57L6 57L38 57L39 57L39 56L39 6L39 5L38 5L6 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M18 27.705L18.705 27L21.5 29.795L24.295 27L25 27.705L22.205 30.5L25 33.295L24.295 34L21.5 31.205L18.705 34L18 33.295L20.795 30.5L18 27.705Z" fill={color} stroke={color}></path>
    </svg>);
export const Im14=({color})=>(
    <svg width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="b8" uk-tooltip="title: Дві поворотньо-відкидні створки; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M79.5 55.5L63.5 7.5L48 56.5L80 6L48 31.5L79.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>);
// Column3
export const Im15=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d1" uk-tooltip="title: Три глухі створки; pos: right" aria-expanded="false">
        <path d="M40 4H4V58H40V4ZM38 56H6V6H38V56ZM18.3 33.3L21.1 30.5L18.3 27.7L19.7 26.3L22.5 29.1L25.3 26.3L26.7 27.7L23.9 30.5L26.7 33.3L25.3 34.7L22.5 31.9L19.7 34.7L18.3 33.3ZM82 4H46V58H82V4ZM80 56H48V6H80V56ZM60.3 33.3L63.1 30.5L60.3 27.7L61.7 26.3L64.5 29.1L67.3 26.3L68.7 27.7L65.9 30.5L68.7 33.3L67.3 34.7L64.5 31.9L61.7 34.7L60.3 33.3ZM86 0H84H44H42H0V62H42H44H84H86H128V0H86ZM42 60H2V2H42V60ZM84 60H44V2H84V60ZM126 60H86V2H126V60ZM124 4H88V58H124V4ZM122 56H90V6H122V56ZM102.3 33.3L105.1 30.5L102.3 27.7L103.7 26.3L106.5 29.1L109.3 26.3L110.7 27.7L107.9 30.5L110.7 33.3L109.3 34.7L106.5 31.9L103.7 34.7L102.3 33.3Z" fill={color}></path>
    </svg>);
export const Im16=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d2" uk-tooltip="title: Поворотньо-відкидна створка з двома крайніми глухими; pos: right" aria-expanded="false">
        <path d="M127 2V1H126H86H85V2V60V61H86H126H127V60V2Z" stroke={color} strokeWidth="2"></path><path d="M122 5L123 5L123 6L123 56L123 57L122 57L90 57L89 57L89 56L89 6L89 5L90 5L122 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M110 27.705L109.295 27L106.5 29.795L103.705 27L103 27.705L105.795 30.5L103 33.295L103.705 34L106.5 31.205L109.295 34L110 33.295L107.205 30.5L110 27.705Z" fill={color} stroke={color}></path>
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M26 27.705L25.295 27L22.5 29.795L19.705 27L19 27.705L21.795 30.5L19 33.295L19.705 34L22.5 31.205L25.295 34L26 33.295L23.205 30.5L26 27.705Z" fill={color} stroke={color}></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M48.5 55.5L64.5 7.5L80 56.5L48 6L80 31.5L48.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>);
export const Im17=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d3" uk-tooltip="title: Поворотна створка з двома крайніми глухими; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M26 27.705L25.295 27L22.5 29.795L19.705 27L19 27.705L21.795 30.5L19 33.295L19.705 34L22.5 31.205L25.295 34L26 33.295L23.205 30.5L26 27.705Z" fill={color} stroke={color}></path>
        <path d="M127 2V1H126H86H85V2V60V61H86H126H127V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M122 5L123 5L123 6L123 56L123 57L122 57L90 57L89 57L89 56L89 6L89 5L90 5L122 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M110 27.705L109.295 27L106.5 29.795L103.705 27L103 27.705L105.795 30.5L103 33.295L103.705 34L106.5 31.205L109.295 34L110 33.295L107.205 30.5L110 27.705Z" fill={color} stroke={color}></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path><path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M48 56L80 30.9393L48 6" stroke={color} strokeWidth="2" stroke-miterlimit="10" stroke-linecap="round" strokeLinejoin="round"></path>
    </svg>);
export const Im18=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d4" uk-tooltip="title: Три поворотньо-відкидні створки; pos: right" aria-expanded="false">
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M79.5 55.5L63.5 7.5L48 56.5L80 6L48 31.5L79.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M127 2V1H126H86H85V2V60V61H86H126H127V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M122 5L123 5L123 6L123 56L123 57L122 57L90 57L89 57L89 56L89 6L89 5L90 5L122 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M121.5 55.5L105.5 7.5L90 56.5L122 6L90 31.5L121.5 55.5Z" stroke={color} strokeWidth="2"></path>
    </svg>);
export const Im19=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d5" uk-tooltip="title: Поворотня зліва, глуха в центрі, поворотньо-відкидна справа; pos: right" aria-expanded="false">
        <path d="M43 2V1H44H84H85V2V60V61H84H44H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M48 5L47 5L47 6L47 56L47 57L48 57L80 57L81 57L81 56L81 6L81 5L80 5L48 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M67 27.705L66.295 27L63.5 29.795L60.705 27L60 27.705L62.795 30.5L60 33.295L60.705 34L63.5 31.205L66.295 34L67 33.295L64.205 30.5L67 27.705Z" fill={color} stroke={color}></path>
        <path d="M85 2V1H86H126H127V2V60V61H126H86H85V60V2Z" stroke={color} strokeWidth="2"></path><path d="M90 5L89 5L89 6L89 56L89 57L90 57L122 57L123 57L123 56L123 6L123 5L122 5L90 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M121.5 55.5L105.5 7.5L90 56.5L122 6L90 31.5L121.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M1 2V1H2H42H43V2V60V61H42H2H1V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 5L5 5L5 6L5 56L5 57L6 57L38 57L39 57L39 56L39 6L39 5L38 5L6 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6 56L38 30.9393L6 6" stroke={color} strokeWidth="2" stroke-miterlimit="10" stroke-linecap="round" strokeLinejoin="round"></path>
    </svg>);
export const Im20=({color})=>(
    <svg width="128" height="62" viewBox="0 0 128 62" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="d6" uk-tooltip="title: Поворотньо-відкидна зліва, глуха в центрі, поворотна справа; pos: right" aria-expanded="false">
        <path d="M85 2V1H84H44H43V2V60V61H44H84H85V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M80 5L81 5L81 6L81 56L81 57L80 57L48 57L47 57L47 56L47 6L47 5L48 5L80 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M61 27.705L61.705 27L64.5 29.795L67.295 27L68 27.705L65.205 30.5L68 33.295L67.295 34L64.5 31.205L61.705 34L61 33.295L63.795 30.5L61 27.705Z" fill={color} stroke={color}></path>
        <path d="M43 2V1H42H2H1V2V60V61H2H42H43V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M38 5L39 5L39 6L39 56L39 57L38 57L5.99998 57L4.99998 57L4.99998 56L4.99998 6L4.99998 5L5.99998 5L38 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M6.5 55.5L22.5 7.5L38 56.5L6 6L38 31.5L6.5 55.5Z" stroke={color} strokeWidth="2"></path>
        <path d="M127 2V1H126H86H85V2V60V61H86H126H127V60V2Z" stroke={color} strokeWidth="2"></path>
        <path d="M122 5L123 5L123 6L123 56L123 57L122 57L90 57L89 57L89 56L89 6L89 5L90 5L122 5Z" stroke={color} strokeWidth="2"></path>
        <path d="M122 56L90 30.9393L122 6" stroke={color} strokeWidth="2" stroke-miterlimit="10" stroke-linecap="round" strokeLinejoin="round"></path>
    </svg>);

//Column4
export const Im21=({color})=>(
    <svg width="40" height="85" viewBox="0 0 40 85" fill="none" xmlns="http://www.w3.org/2000/svg" data-svg="e1">
        <path d="M0 0V85H40V0H0ZM38 83H2V2H38V83ZM36 81V57H24L26.3 54H36V4H4V54H23.8L21.5 57H4V81H36ZM27.9 52L34 44V52H27.9ZM34 40.8L7 6H34V40.8ZM6 52V7.9L32.7 42.4L25.3 52H6ZM34 59V79H7L22.4 59H34ZM19.9 59L6 77.1V59H19.9Z" fill={color}></path>
    </svg>);
