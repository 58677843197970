import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { WoodWindowsillProducts, defaultWoodWindowsillType, defaultWoodWindowsillColor } from "../../product_data/wood_windowsill";
import { WoodWindowsillCard } from "../../../landing_page/products/cards";
import { useState } from "react";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { ArtDenDrawer } from "../../drower";

export const WoodWindowsill = () => {
    const [type, setType] = useState(defaultWoodWindowsillType.map(t => t.value))
    const [color, setColor] = useState(defaultWoodWindowsillColor.map(d => d.value))

    const filteredProducts = WoodWindowsillProducts.filter((product) => {
        return WoodWindowsillProducts
    }).filter(p => type.includes(p.type)).filter(p => color.includes(p.color));

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Дерев’яне підвіконня</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{}}>Декор/колір</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(color) => setColor(color)} options={defaultWoodWindowsillColor}></Filter>
                                    </FilterBlock>

                                    <TextFilter style={{}}>Тип</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(type) => setType(type)} options={defaultWoodWindowsillType}></Filter>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{}}>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultWoodWindowsillColor}></Filter>
                                </FilterBlock>

                                <TextFilter style={{}}>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultWoodWindowsillType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}

                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <WoodWindowsillCard
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                param2={p.param2}
                                                param3={p.param3}
                                                price={p.price}
                                            ></WoodWindowsillCard>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};