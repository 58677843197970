import {
    CardHeight,
    CardWithoutButton,
    Main_Rolet_Card,
    MainCard,
    MainCardSmall,
    MainWindowsillCard,
    Vidlivy_Card
} from "./product_card";
import {Link} from "react-router-dom";

export const WindowCard = ({id, image, name, param1, param2, param3, price}) => {
    // const button = (
    //     <Link to={`/products/windows/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/windows/${id}`} style={{textDecoration: 'none'}}>
            <MainCard
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={param3}
                price={price}
                // priceButton={button}
            />
        </Link>

    );
};

export const InteriorDoorCard = ({id, image, name, param1, param2, param3, price}) => {
    // const button = (
    //     <Link to={`/products/interior_door/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/interior_door/${id}`} style={{textDecoration: 'none'}}>
            <MainCard
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={param3}
                price={price}
                // priceButton={button}
            />
        </Link>

    );
};

export const EntranceDoorCard = ({id, image, name, param1, param2, param3, price}) => {
    // const button = (
    //     <Link to={`/products/entrance_door/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/entrance_door/${id}`} style={{textDecoration: 'none'}}>
            <MainCard
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={param3}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const WindowsillCard = ({id, image, name, param1, param2, param3, price}) => {
    // const button = (
    //
    //         <ButtonDetails></ButtonDetails>
    // );
    return (
        <Link to={`/products/pidvikonnya/${id}`} style={{textDecoration: 'none'}}>
            <MainWindowsillCard
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={param3}
                price={price}
                // priceButton={button}
            />
        </Link>

    );
};

export const WoodWindowsillCard = ({id, image, name, param1, param2, param3, price}) => {
    // const button = (
    //     <Link to={`/products/derevyane_pidvikonnya/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/derevyane_pidvikonnya/${id}`} style={{textDecoration: 'none'}}>
            <MainWindowsillCard
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={param3}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const VodovidlivyCard = ({id, image, name, param1, price}) => {
    // const button = (
    //     <Link to={`/products/vodovidlivy/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/vodovidlivy/${id}`} style={{textDecoration: 'none'}}>
            <Vidlivy_Card
                id={id}
                image={image}
                name={name}
                param1={param1}
                price={price}
                // priceButton={button}
            />
        </Link>

    );
};

export const Den_Rolet_Card = ({id, image, name, price}) => {
    // const button = (
    //     <Link to={`/products/den_nich_rolety/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/den_nich_rolety/${id}`} style={{textDecoration: 'none'}}>
            <Main_Rolet_Card
                id={id}
                image={image}
                name={name}
                param1={null}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const Screen_Rolet_Card = ({id, image, name, price}) => {
    // const button = (
    //     <Link to={`/products/screen_rolety/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/screen_rolety/${id}`} style={{textDecoration: 'none'}}>
            <Main_Rolet_Card
                id={id}
                image={image}
                name={name}
                param1={null}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const Tkaninny_Rolet_Card = ({id, image, name, price}) => {
    // const button = (
    //     <Link to={`/products/tkanynni_rolety/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/tkanynni_rolety/${id}`} style={{textDecoration: 'none'}}>
            <Main_Rolet_Card
                id={id}
                image={image}
                name={name}
                param1={null}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const Moskitni_sitky_Card = ({id, image, name, param1, param2, price}) => {
    // const button = (
    //     <Link to={`/products/moskitni_sitki/${id}`} style={{textDecoration: 'none'}}>
    //         <ButtonDetails></ButtonDetails>
    //     </Link>
    // );
    return (
        <Link to={`/products/moskitni_sitki/${id}`} style={{textDecoration: 'none'}}>
            <MainCardSmall
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                param3={null}
                price={price}
                // priceButton={button}
            />
        </Link>
    );
};

export const Furnitura_Card = ({id, image, name, param1, param2, price}) => {
    return (
        <Link to={`/products/furnitura/${id}`} style={{textDecoration: 'none'}}>
        <CardHeight>
            <CardWithoutButton
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                price={price}/>
        </CardHeight>
        </Link>
    )
}

export const Montaz_Card = ({id, image, name, param1, param2 ,price}) => {
    return (
        <Link to={`/products/materialy_dlya_montazhu/${id}`} style={{textDecoration: 'none'}}>
        <CardHeight>
            <CardWithoutButton
                id={id}
                image={image}
                name={name}
                param1={param1}
                param2={param2}
                price={price}/>
        </CardHeight>
        </Link>
    )
}

