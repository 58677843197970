import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { Screen_Rolet_Card } from "../../../landing_page/products/cards";
import { useState } from "react";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { PriceRangeSlider } from "../../filterBlock/price_slider";
import { defaultScreenRollColor, screenRollPrices, screenRollProducts } from "../../product_data/scrin_roleta";
import { ArtDenDrawer } from "../../drower";

export const ScreenRollety = () => {
    const [color, setColor] = useState(defaultScreenRollColor.map(d => d.value))
    const [price, setPrice] = useState([screenRollPrices.min, screenRollPrices.max]);
    const [minPrice, maxPrice] = price
    const filteredProducts = screenRollProducts.filter((product) => {
        const price = parseInt(product.price, 10);
        return price >= minPrice && price <= maxPrice;
    }).filter(p => color.includes(p.color));

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Скрін ролети</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                    <PriceRangeSlider min={screenRollPrices.min} max={screenRollPrices.max} onPriceChange={setPrice} />
                                    <TextFilter>Декор/колір</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(color) => setColor(color)} options={defaultScreenRollColor}></Filter>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                <PriceRangeSlider min={screenRollPrices.min} max={screenRollPrices.max} onPriceChange={setPrice} />
                                <TextFilter>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultScreenRollColor}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}
                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <Screen_Rolet_Card
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                price={p.price}
                                            ></Screen_Rolet_Card>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};