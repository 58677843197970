export const menuItems = [
    {
        label: 'Продукція',
        children: [
            {
                category: 'Металопластикові вироби',
                items: [
                    { label: 'Металопластикові вікна', link: '/catalog/plastic_windows' },
                    { label: 'Металопластикові двері', link: '/details/door' },
                    { label: 'Балкони', link: '/details/balcon' },
                    { label: 'Розсувні системи', link: '/details/rozsuvni_systemy' },
                ]
            },
            {
                category: 'Алюмінієві вироби',
                items: [
                    { label: 'Алюмінієві вікна', link: '/details/alyuminiyevi_vikna' },
                    { label: 'Алюмінієві двері', link: '/details/alyuminiyevi_dvery' },
                    { label: 'Панорамні вікна', link: '/details/panoramni_vikna' },
                ]
            },
            {
                category: 'Двері',
                items: [
                    { label: 'Вхідні двері', link: '/catalog/entrance_doors' },
                    { label: 'Міжкімнатні двері', link: '/catalog/interior_doors' },
                    { label: 'Двері прихованого монтажу', link: '/details/dveri_prykhovanoho_montazhu' },
                    ]
            },
            {
                category: 'Ролети',
                items: [
                    { label: 'Тканинні', link: '/catalog/tkanynni_rolety' },
                    { label: 'День і ніч', link: '/catalog/den-nich_rolety' },
                    { label: 'Cкрін ролети', link: '/catalog/screen_rolety' },
                    ]
            },{
                category: 'Підвіконня',
                items: [
                    { label: 'Дерев’яне підвіконня', link: '/catalog/wood_windowsill' },
                    { label: 'Підвіконня', link: '/catalog/windowsill' },
                    { label: 'Відливи', link: '/catalog/vidlyv' },
                    ]
            },{
                category: 'Все для вікон та дверей',
                items: [
                    { label: 'Фурнітура', link: '/catalog/furnitura' },
                    { label: 'Москітні сітки', link: '/catalog/moskitni_sitki' },
                    { label: 'Матеріали для монтажу', link: '/catalog/materialy_dlya_montazhu' },
                    ]
            },],
    },
    {
        label: 'Проекти',
        link: '/#our_projects',
    },
    {
        label: 'Про нас',
        link: '/#about_us',
    },
    {
        label: 'Контакти',
        link: '/#contacts',
    },
];