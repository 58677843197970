import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { VodovidlivyCard } from "../../../landing_page/products/cards";
import { Filter } from "../../filterBlock/filter";
import { defaultVidlyvType, vidlivyProducts } from "../../product_data/vidlyv_data"
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { useState } from "react";
import { ArtDenDrawer } from "../../drower";


export const Vidlyv = () => {
    const [type, setType] = useState(defaultVidlyvType.map(t => t.value))

    const filteredProducts = vidlivyProducts.filter((product) => {
        return vidlivyProducts
    }).filter(t => type.includes(t.type));

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Водовідлив</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{}}>Тип</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(type) => setType(type)} options={defaultVidlyvType}></Filter>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{}}>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultVidlyvType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}

                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <VodovidlivyCard
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                price={p.price}
                                                id={p.id} ></VodovidlivyCard>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};