import styled from "styled-components"
import { BlockName } from "../../shared/blockName/blockName"
import Slider from "react-slick";
import { WindowCard } from "./cards";
import '../../shared/slider/slider_settings.scss';
import { windowProducts } from "../../catalog/product_data/plastic_window_data";
import { useState } from "react";
import { SlickArrowLeft, SlickArrowRight } from "../../shared/slider/slider_arrows";


export const Products = () => {
    const [currentSlide, setCurrentSlide] = useState(0); 
    const slideCount = windowProducts.length; 
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SlickArrowRight currentSlide={currentSlide} slideCount={slideCount} />,
        prevArrow: <SlickArrowLeft currentSlide={currentSlide} slideCount={slideCount} />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 1.65,
                },
            },
        ],
    };
    
    return (
        <CategoryBlock>
            <BlockName>Популярні товари</BlockName>
            <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <ScrollBlock >
                    <Slider {...settings}>
                        {windowProducts.map((p) => (<WindowCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></WindowCard>))}
                    </Slider>
                </ScrollBlock>
            </div>
        </CategoryBlock>

    )
}


export const CategoryBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    background: #ffffff;
    justify-content: center;
    flex-direction: column;
`
export const ScrollBlock = styled.div`
    width: 1300px;
    @media (max-width: 768px) {
    width: 330px;
    }
`
