import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, Image, Text, ItemName, settings, Margin60, ImageBlock, CardBlockMargin, TypeKripleniy, SliderBlock, Margin16, } from "../../card"
import { Tkaninny_Rolet_Card } from "../../../landing_page/products/cards"
import { SmallItem } from "../windowsill/windowsill"
import { tkanevRollProducts } from "../../../catalog/product_data/rolety_tkaniny_data"
import { SecondaryModal } from "../../../modal/modal"
import { Kriplennya } from "./kriplennya"
import { MyButton } from "../../../shared/button/button"

export const Tkaninny_Rolety_Product_Card = () => {
    const { id } = useParams()
    const product = tkanevRollProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = tkanevRollProducts.filter((p) => p.id !== parseInt(id as string));
    const [showComponent0, setShowComponent0] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            </Background>
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <SliderBlock>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {similarProducts.map((p) => (<Tkaninny_Rolet_Card key={p.id} id={p.id} image={p.image} name={p.name} price={p.price}></Tkaninny_Rolet_Card>))}
                        </Slider>
                    </ScrollBlock>
                </SliderBlock>
            </CategoryBlock>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CardBlock = ({ product }) => {
    return (
        <CardBlockMargin>
            <ItemName>{product.name}</ItemName>
            <ItemName>Вартість: {product.price} грн/м²</ItemName>
            <Margin16>
                <Text>Ідеальним варіантом для захисту вікон від сонячних променів і настирливих поглядів є тканинні ролети (рулонні штори). Це оптимальне рішення як для декору оселі, так і для офісу, ТЦ або салонів. Цей тип захисту від сонця відрізняється оригінальним зовнішнім виглядом і простотою використання. Рулонна штора - це намотана на вал тканина з підйомним механізмом і обтяжуючою планкою знизу. Завдяки особливості конструкції, полотно з легкістю скручується в рулон. Зазвичай тканинні ролети використовують як окремий елемент декору, так і в поєднанні з іншими елементами оформлення вікон.</Text>
                <SmallItem>Допустима похибка в ширині виробу +/- 5 мм!</SmallItem>
                <SmallItem>Готові вироби не підлягають поверненню!</SmallItem>
                <SmallItem>Монтаж ролет потрібно проводити на чисту, попередньо знежирену поверхню важливо!</SmallItem>
                <SmallItem>Колір товару може відрізнятись від представленого на екрані залежно від Ваших налаштувань монітора.</SmallItem>
                <SmallItem>Термін виготовлення 3-4 дні.</SmallItem>
                <SmallItem>У роботу приймаються розміри на зовнішній стороні штапика!</SmallItem>
            </Margin16>
            <TypeKripleniy>
                <ItemName>Типи кріплення</ItemName>
                <Kriplennya></Kriplennya>
            </TypeKripleniy>
        </CardBlockMargin>
    );
}
