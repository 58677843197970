import styled from "styled-components"
import Slider from "react-slick";
import { Project } from "./projects";
import project1 from "../../images/projects/project1.jpg"
import project2 from "../../images/projects/project2.jpg"
import project3 from "../../images/projects/project3.jpg"
import project4 from "../../images/projects/project4.jpg"
import project5 from "../../images/projects/project5.jpg"
import project6 from "../../images/projects/project6.jpg"
import project7 from "../../images/projects/project7.jpg"
import project8 from "../../images/projects/project8.jpg"
import project9 from "../../images/projects/project9.jpg"
import '../../shared/slider/slider_settings.scss'
import { BlockName } from "../../shared/blockName/blockName";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { CentralPark, CityVillaClub, Hmelnitsky, Lipky, Opryshkivska, Pelmenna, Stozary, Uvileyniy2_3, Uvileyniy_1 } from "./pages_with_photos/projects_photo_data";
import { useState } from "react";
import { SlickArrowLeft, SlickArrowRight } from "../../shared/slider/slider_arrows";


export const OurProjects = ({ linkRef }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideCount = Project.length;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SlickArrowRight currentSlide={currentSlide} slideCount={slideCount} />,
        prevArrow: <SlickArrowLeft currentSlide={currentSlide} slideCount={slideCount} />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    
    return (
        <div id="our_projects" ref={linkRef}>
            <CategoryBlock>
                <BlockName>Наші проекти</BlockName>
                <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', padding: '5px 0' }}>
                    <ScrollBlock>
                        <Slider {...settings}>
                            <Nav.Link as={Link} to="/project/city_villa_club" style={{ textDecoration: 'none' }}>
                                <Project image={project1} name={CityVillaClub.title} year={CityVillaClub.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/central_park" style={{ textDecoration: 'none' }}>
                                <Project image={project2} name={CentralPark.title} year={CentralPark.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/yuvileynyy_2" style={{ textDecoration: 'none' }}>
                                <Project image={project3} name={Uvileyniy2_3.title} year={Uvileyniy2_3.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/stozary" style={{ textDecoration: 'none' }}>
                                <Project image={project4} name={Stozary.title} year={Stozary.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/opryshivska_sloboda" style={{ textDecoration: 'none' }}>
                                <Project image={project5} name={Opryshkivska.title} year={Opryshkivska.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/khmelnytskyy" style={{ textDecoration: 'none' }}>
                                <Project image={project6} name={Hmelnitsky.title} year={Hmelnitsky.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/pelmenna" style={{ textDecoration: 'none' }}>
                                <Project image={project7} name={Pelmenna.title} year={Pelmenna.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/yuvileynyy_1" style={{ textDecoration: 'none' }}>
                                <Project image={project8} name={Uvileyniy_1.title} year={Uvileyniy_1.year} />
                            </Nav.Link>
                            <Nav.Link as={Link} to="/project/lipky" style={{ textDecoration: 'none' }}>
                                <Project image={project9} name={Lipky.title} year={Lipky.year} />
                            </Nav.Link>

                        </Slider>
                    </ScrollBlock>
                </div>
            </CategoryBlock>
        </div>
    )
}

const ScrollBlock = styled.div`
    height: 565px;
    width: 1300px;
    @media (max-width: 768px) {
    width:330px;
    height: 325px;
    }
`
const CategoryBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    background: #ffffff;
    justify-content: center;
    flex-direction: column;
    text-decoration:none;
`
