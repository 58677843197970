import { useState } from "react";
import { MyButton } from "../button/button";
import './contactform.scss';
import { ModalSuccess } from "../../modal/modal_success";
import { sendEmail } from "../../API/send_email";
import '../input/input.scss';

export const Contactform = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleFocus = () => {
        if (!phone) {
            setPhone('+380');
        }
    };

    const phoneChange = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^\d]/g, '');
        newValue = '+' + newValue;
        setPhone(newValue);
    };

    const nameChange = (e) => {
        const newName = e.target.value
        setName(newName)
    }
    const handleShowSuccessModal = async () => {
        await sendEmail(name, phone);
        setShowSuccessModal(true);
    };
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    };


    return (
        <div className="contactform">
            <div className="text">Залишайте свої контактні дані та ми допоможемо розрахувати вартість</div>
            <div className="contactform_wrapper">
                <input type='text' value={name} placeholder="Ім’я" onChange={nameChange} className='myinput' />
                <input type='text' value={phone} placeholder="Номер телефону" onFocus={handleFocus} onChange={phoneChange} className='myinput' />
                <MyButton name='Розрахувати вартість' disabled={!phone || !name} onClick={handleShowSuccessModal} />
            </div>
            <ModalSuccess
                show={showSuccessModal}
                onHide={handleCloseSuccessModal}
                className="modal-success modal-dialog-centered"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            />
        </div>
    )
};



