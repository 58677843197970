import wind19 from "../../images/catalog/windowsill/alber/dub_trufel.jpeg"
import wind20 from "../../images/catalog/windowsill/alber/dub_breston.jpeg"
import wind21 from "../../images/catalog/windowsill/alber/белий.webp"
import wind22 from "../../images/catalog/windowsill/alber/темнийдуб.webp"
import wind23 from "../../images/catalog/windowsill/alber/галифакснатуральний.webp"
import wind24 from "../../images/catalog/windowsill/alber/галифакстабак.webp"
import wind25 from "../../images/catalog/windowsill/alber/дубгамільтон.jpeg"
import wind26 from "../../images/catalog/windowsill/alber/дубгікорі.jpeg"
import wind27 from "../../images/catalog/windowsill/alber/білакора.jpeg"
import wind28 from "../../images/catalog/windowsill/alber/дубхантон.jpeg"
import wind29 from "../../images/catalog/windowsill/alber/дубхантонтемний.jpeg"
import wind30 from "../../images/catalog/windowsill/alber/світледерево.jpeg"
import wind31 from "../../images/catalog/windowsill/alber/старедерево.jpeg"
import wind32 from "../../images/catalog/windowsill/alber/бетончекаго.jpeg"
import wind33 from "../../images/catalog/windowsill/alber/дубрівер.jpeg"
import wind34 from "../../images/catalog/windowsill/alber/керамікатессина.jpeg"
import wind35 from "../../images/catalog/windowsill/alber/сланець.jpeg"
import wind36 from "../../images/catalog/windowsill/alber/хроміксбілий.jpeg"
import wind37 from "../../images/catalog/windowsill/alber/хроміксбронза.jpeg"

// Колір
export enum WoodWindowsillColor {
    WHITE = 'white',
    KAMIN = 'kamin',
    HEITEK = 'heitek',
    DEREVO = 'derevo',
}
export const defaultWoodWindowsillColor = [
    { value: WoodWindowsillColor.WHITE, label: 'Білий' },
    { value: WoodWindowsillColor.KAMIN, label: 'Під камінь' },
    { value: WoodWindowsillColor.HEITEK, label: 'Хай-тек' },
    { value: WoodWindowsillColor.DEREVO, label: 'Під дерево' },
]
//Тип

export enum WoodWindowsillType {
    PRYAMIY = 'pryamiy',
    ZAOKRYGLENIY = 'zaokrugleniy',
    NEOTYPOVIY = 'neotypoviy',
}
export const defaultWoodWindowsillType = [
    { value: WoodWindowsillType.PRYAMIY, label: 'Прямий капінос' },
    { value: WoodWindowsillType.ZAOKRYGLENIY, label: 'Заокруглений капінос' },
    { value: WoodWindowsillType.NEOTYPOVIY, label: 'Нетиповий капінос' },
]
export enum AlberCollections {
    STANDART = 'Standart',
    PREMIUM = 'Premium',
    ELITE = 'Elite',
  }

  export const brandParameters = {
    [AlberCollections.STANDART]: {
        text1: "Alber - сучасне дерев'яне підвіконня нового типу. Виробляється на модерному німецькому обладнанні. Вологостійка плита, вироблена з натурального дерева (береза, вільха королівська), гарантує екологічну безпеку та високу міцність! Комбінована структура основи надійно захищає від деформацій. Покриття Egger, Австрійського виробництва надає підвіконню дерев'яну текстуру і забезпечує захист від пошкоджень. Надзвичайно стійке до забруднень і впливу ультрафіолету. Підвіконня буде радувати Вас і слугувати багато років!",
        text2: "Підвіконня на вікні суттєво знизить витрати на опалення, надасть вікну оригінального зовнішнього вигляду, захистить від віконного грибка і появи сирості в приміщенні. Характерна риса підвіконь Alber - вологостійкість і термостійкість. Завдяки цим якостям підвіконня можна застосовувати як стільниці, декоративні столики, барні стійки.Варто відзначити спеціальний лак німецького виробництва, який забезпечує підвіконню стійкість до подряпин, вологи, хімічного і механічного впливу.",
        text3:"Підвіконня Alber ідеально впишеться в Ваш інтер'єр, не тільки по колірних відтінках, але й за формою. Це на 100% - екологічно чистий матеріал, який не містить шкідливих речовин.",
        text4:"Широка кольорова гама не залишить байдужим жодного клієнта.",
        values: [
          { width: '150 мм', price: '1998 грн' },
          { width: '200 мм', price: '2408 грн' },
          { width: '250 мм', price: '2828 грн' },
          { width: '300 мм', price: '3228 грн' },
          { width: '350 мм', price: '3648 грн' },
          { width: '400 мм', price: '4068 грн' },
          { width: '450 мм', price: '4468 грн' },
          { width: '500 мм', price: '4878 грн' },
          { width: '600 мм', price: '5978 грн' },
          { width: '700 мм', price: '6838 грн' },
          { width: '750 мм', price: '7268 грн' },
          { width: '1000 мм', price: '10228 грн' },
          { width: '1100 мм', price: '10668 грн' },
          { width: '1200 мм', price: '498 грн' },
        ],
        kraikyvannia:'498'
      },
      [AlberCollections.PREMIUM]: {
        text1: "Alber - сучасне дерев'яне підвіконня нового типу. Виробляється на модерному німецькому обладнанні. Вологостійка плита, вироблена з натурального дерева (береза, вільха королівська), гарантує екологічну безпеку та високу міцність! Комбінована структура основи надійно захищає від деформацій. Покриття Egger, Австрійського виробництва надає підвіконню дерев'яну текстуру і забезпечує захист від пошкоджень. Надзвичайно стійке до забруднень і впливу ультрафіолету. Підвіконня буде радувати Вас і слугувати багато років!",
        text2: "Підвіконня на вікні суттєво знизить витрати на опалення, надасть вікну оригінального зовнішнього вигляду, захистить від віконного грибка і появи сирості в приміщенні. Характерна риса підвіконь Alber - вологостійкість і термостійкість. Завдяки цим якостям підвіконня можна застосовувати як стільниці, декоративні столики, барні стійки.Варто відзначити спеціальний лак німецького виробництва, який забезпечує підвіконню стійкість до подряпин, вологи, хімічного і механічного впливу.",
        text3:"Підвіконня Alber ідеально впишеться в Ваш інтер'єр, не тільки по колірних відтінках, але й за формою. Це на 100% - екологічно чистий матеріал, який не містить шкідливих речовин.",
        text4:"Широка кольорова гама не залишить байдужим жодного клієнта.",
        values: [
          { width: '150 мм', price: '2198 грн' },
          { width: '200 мм', price: '2658 грн' },
          { width: '250 мм', price: '3109 грн' },
          { width: '300 мм', price: '3559 грн' },
          { width: '350 мм', price: '3998 грн' },
          { width: '400 мм', price: '4468 грн' },
          { width: '450 мм', price: '4918 грн' },
          { width: '500 мм', price: '5358 грн' },
          { width: '600 мм', price: '6574 грн' },
          { width: '700 мм', price: '7514 грн' },
          { width: '750 мм', price: '7998 грн' },
          { width: '1000 мм', price: '11248 грн' },
          { width: '1100 мм', price: '11928 грн' },
          { width: '1200 мм', price: '12214 грн' },
        ],
        kraikyvannia:'558'
      },
      [AlberCollections.ELITE]: {
        text1: "Alber - сучасне дерев'яне підвіконня нового типу. Виробляється на модерному німецькому обладнанні. Вологостійка плита, вироблена з натурального дерева (береза, вільха королівська), гарантує екологічну безпеку та високу міцність! Комбінована структура основи надійно захищає від деформацій. Покриття Egger, Австрійського виробництва надає підвіконню дерев'яну текстуру і забезпечує захист від пошкоджень. Надзвичайно стійке до забруднень і впливу ультрафіолету. Підвіконня буде радувати Вас і слугувати багато років!",
        text2: "Підвіконня на вікні суттєво знизить витрати на опалення, надасть вікну оригінального зовнішнього вигляду, захистить від віконного грибка і появи сирості в приміщенні. Характерна риса підвіконь Alber - вологостійкість і термостійкість. Завдяки цим якостям підвіконня можна застосовувати як стільниці, декоративні столики, барні стійки.Варто відзначити спеціальний лак німецького виробництва, який забезпечує підвіконню стійкість до подряпин, вологи, хімічного і механічного впливу.",
        text3:"Підвіконня Alber ідеально впишеться в Ваш інтер'єр, не тільки по колірних відтінках, але й за формою. Це на 100% - екологічно чистий матеріал, який не містить шкідливих речовин.",
        text4:"Широка кольорова гама не залишить байдужим жодного клієнта.",
        values: [
          { width: '150 мм', price: '2638 грн' },
          { width: '200 мм', price: '3175 грн' },
          { width: '250 мм', price: '3714 грн' },
          { width: '300 мм', price: '4258 грн' },
          { width: '350 мм', price: '4788 грн' },
          { width: '400 мм', price: '5350 грн' },
          { width: '450 мм', price: '5890 грн' },
          { width: '500 мм', price: '6698 грн' },
          { width: '600 мм', price: '7834 грн' },
          { width: '700 мм', price: '8958 грн' },
          { width: '750 мм', price: '9528 грн' },
          { width: '1000 мм', price: '13398 грн' },
          { width: '1100 мм', price: '13968 грн' },
          { width: '1200 мм', price: '14534 грн' },
        ],
        kraikyvannia:'599'
      }
    }

export const WoodWindowsillProducts = [
    //Alber
    { id: 132, image: wind19, name: "Дуб трюфельний", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null, type: WoodWindowsillType.PRYAMIY , param4:'Прямий капінос',collection:AlberCollections.STANDART,},
    { id: 133, image: wind20, name: "Дуб бренсон", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null, type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.STANDART,},
    { id: 134, image: wind21, name: "МДФ білий",color:WoodWindowsillColor.WHITE, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.STANDART,},
    { id: 135, image: wind22, name: "Дуб темний", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.STANDART,},
    { id: 136, image: wind23, name: "Дуб натуральний",color:WoodWindowsillColor.DEREVO, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM,},
    { id: 137, image: wind24, name: "Дуб галіфакс табак",color:WoodWindowsillColor.DEREVO, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос' ,collection:AlberCollections.PREMIUM},
    { id: 138, image: wind25, name: "Дуб гамільтон", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос' ,collection:AlberCollections.PREMIUM},
    { id: 139, image: wind26, name: "Дуб гікорі", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM},
    { id: 140, image: wind27, name: "Біла кора",color:WoodWindowsillColor.WHITE, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM},
    { id: 141, image: wind28, name: "Дуб хантон",color:WoodWindowsillColor.DEREVO, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM},
    { id: 142, image: wind29, name: "Дуб хантон темний",color:WoodWindowsillColor.DEREVO, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM },
    { id: 143, image: wind30, name: "Світле дерево",color:WoodWindowsillColor.DEREVO, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM},
    { id: 144, image: wind31, name: "Старе дерево", color:WoodWindowsillColor.DEREVO,param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.PREMIUM},
    { id: 145, image: wind32, name: "Бетон Чикаго",color:WoodWindowsillColor.HEITEK, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
    { id: 146, image: wind33, name: "Дуб рівер",color:WoodWindowsillColor.HEITEK, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
    { id: 147, image: wind34, name: "Кераміка тесина",color:WoodWindowsillColor.KAMIN, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null, type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
    { id: 148, image: wind35, name: "Сланець",color:WoodWindowsillColor.HEITEK, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
    { id: 149, image: wind36, name: "Хромікс білий",color:WoodWindowsillColor.HEITEK, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null,  type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
    { id: 150, image: wind37, name: "Хромікс бронза",color:WoodWindowsillColor.HEITEK, param1: 'Виробник: Alber', param2: 'Матеріал: дерево', param3: 'Вид поверхні: Глянець', price: null, type: WoodWindowsillType.PRYAMIY, param4:'Прямий капінос',collection:AlberCollections.ELITE},
]