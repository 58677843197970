import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import {MyButton} from "../../../shared/button/button"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, Image, Text, ItemName, settings, Item, Margin60, ImageBlock, CardBlockMargin, Margin16, CardItem, TextItem, } from "../../card"
import { Furnitura_Card, Montaz_Card } from "../../../landing_page/products/cards"
import { SecondaryModal } from "../../../modal/modal"
import { SmallItem } from "../windowsill/windowsill"
import { mantazProducts } from "../../../catalog/product_data/material_for_montaz"

export const Montaz_Product_Card = () => {
    const { id } = useParams()
    const product = mantazProducts.find(w => w.id === parseInt(id as string))
    const [showComponent0, setShowComponent0] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            </Background>
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', marginBottom: '60px' }}>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {mantazProducts.map((p) => ( <Montaz_Card id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} price={p.price}></ Montaz_Card>))}
                        </Slider>
                    </ScrollBlock>
                </div>
            </CategoryBlock>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CardBlock = ({ product }) => {
    return (
        <CardBlockMargin>
            <ItemName>{product.name}</ItemName>
            {product.param1&&<SmallItem>{product.param1}</SmallItem>}
            {product.param2&&<SmallItem>{product.param2}</SmallItem>}
            <Item>Вартість: {product.price} грн.</Item>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
                    <CardItem key={product.id}>
                        <Item>Властивості:</Item>
                        <Margin16>
                            {product.details1&&<TextItem> ● {product.details1}</TextItem>}
                            {product.details2&&<TextItem> ● {product.details2}</TextItem>}
                            {product.details3&&<TextItem> ● {product.details3}</TextItem>}
                            {product.details4&&<TextItem> ● {product.details4}</TextItem>}
                            {product.details5&&<TextItem> ● {product.details5}</TextItem>}
                            {product.details6&&<TextItem> ● {product.details6}</TextItem>}
                            {product.details7&&<TextItem> ● {product.details7}</TextItem>}
                            {product.details8&&<TextItem> ● {product.details8}</TextItem>}
                            {product.details9&&<TextItem> ● {product.details9}</TextItem>}
                        </Margin16>
                    </CardItem>
                    <Text>{product.text}</Text>
                </div>
        </CardBlockMargin>

    );
}