import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import {MyButton} from "../../../shared/button/button"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, Image, Text, ItemName, settings, Item, Margin60, ImageBlock, CardBlockMargin, Margin16, } from "../../card"
import { Moskitni_sitky_Card } from "../../../landing_page/products/cards"
import { SecondaryModal } from "../../../modal/modal"
import { moskitni_sitkyProducts } from "../../../catalog/product_data/moskitni_sitky_data"
import { SmallItem, TextRed } from "../windowsill/windowsill"

export const Moskitni_Sitky_Product_Card = () => {
    const { id } = useParams()
    const product = moskitni_sitkyProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = moskitni_sitkyProducts.filter((p) => p.id !== parseInt(id as string));
    const [showComponent0, setShowComponent0] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            </Background>
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', marginBottom: '60px' }}>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {similarProducts.map((p) => (<Moskitni_sitky_Card key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} price={p.price}></Moskitni_sitky_Card>))}
                        </Slider>
                    </ScrollBlock>
                </div>
            </CategoryBlock>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CardBlock = ({ product }) => {
    return (
        <CardBlockMargin>
            <ItemName>{product.name}</ItemName>
            {product.param1&&<SmallItem>{product.param1}</SmallItem>}
            {product.param2&&<SmallItem>{product.param2}</SmallItem>}
            <Item> {product.price} грн/м²</Item>
            <Margin16>
                <Text>{product.text1}</Text>
                <Text>{product.text2}</Text>
                <Text>{product.text3}</Text>
                {product.text4 && <Text>{product.text4}</Text>}
                {product.text5 && <Text>{product.text5}</Text>}
                {product.param3&&<TextRed>{product.param3}</TextRed>}
            </Margin16>
        </CardBlockMargin>

    );
}