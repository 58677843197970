import styled from "styled-components"
import { Container } from "../shared/container"
import { MenuDesktop, MenuMobile } from "./menu/menu"
import Telephone from "./telephone/telephone"
import { Link, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"
import { FiMenu, FiX } from "react-icons/fi"
import { Logo } from "./logo/Logo"


export const Header = () => {
  return (
    <div>
      <HeaderDesctop />
      <HeaderMobile />
    </div>
  )
}

export const HeaderDesctop = () => {
  return (
    <div className="d-none d-lg-block">
      <Back>
        <App_header>
          <div style={{width:'1440px', display:'flex', justifyContent:'space-between'}}>
            <Container color='#F5F5F5'>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Logo></Logo>
              </Link>
              <MenuDesktop />
              <Telephone />
            </Container>
          </div>
        </App_header>
      </Back>
    </div>
  )
}

export const HeaderMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);
  return (
    <div className="d-block d-lg-none">
      {isMenuOpen && <Backdrop onClick={handleMenuToggle} />}
      <MobileHeaderContainer>
        <MobileMenuButton onClick={handleMenuToggle}>
          {isMenuOpen ? <FiX size={"40px"} color="#5A5B5D" /> : <FiMenu size={"40px"} color="#5A5B5D" />}
        </MobileMenuButton>
        {isMenuOpen && <MenuMobile />}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Logo></Logo>
        </Link>
      </MobileHeaderContainer>
    </div>
  );
};


const App_header = styled.div`
    position: fixed;
    z-index: 1000;
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
`
const Back = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.15);
`

const MobileHeaderContainer = styled.div`
  background-color: #F5F5F5;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const MobileMenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  &:focus {
    outline: none;
  }
  z-index: 10;
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 8; 
`;

