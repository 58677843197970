import './App.scss';
import "./font/Montserrat-VariableFont_wght.ttf";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LandingPage } from './landing_page/landing_page';
import { Calculator } from './calculator/calculator';
import { PlasticWindowCatalog } from './catalog/catalog_pages/window/window';
import { InteriorDoorsCatalog } from './catalog/catalog_pages/doors/interior_door';
import { EntranceDoorsCatalog } from './catalog/catalog_pages/doors/entrance_door';
import { Windowsill } from './catalog/catalog_pages/windowsill/windowsill';
import { InformationPage } from './information_page/information_page';
import { BalconInformation, DoorInformation, DveriPryhovani, RozsyvniSistemi, coldAlum, rozpashniDoor, rozsyvniDoor, warmAlum } from './information_page/information_page_data';
import { WoodWindowsill } from './catalog/catalog_pages/windowsill/windowsill_wood';
import { TkanevRollety } from './catalog/catalog_pages/rolety/rolety_tkaniny';
import { DenNichRollety } from './catalog/catalog_pages/rolety/rolety_den_nich';
import { PhotoProjectPage } from './landing_page/our_project/pages_with_photos/photo_project';
import { CentralPark, CityVillaClub, Hmelnitsky, Lipky, Opryshkivska, Pelmenna, Stozary, Uvileyniy2_3, Uvileyniy_1 } from './landing_page/our_project/pages_with_photos/projects_photo_data';
import { ScreenRollety } from './catalog/catalog_pages/rolety/scrin';
import { Vidlyv } from './catalog/catalog_pages/vidlivy/vidlyv';
import {  ProductPageWindow } from './product_card/cards/window/window';
import { InteriorDoorProductPage } from './product_card/cards/door/interior_door';
import { EntranceDoorProductPage } from './product_card/cards/door/entrance_door';
import { WindowsillProductsCard } from './product_card/cards/windowsill/windowsill';
import { WoodWindowsillProductsCard } from './product_card/cards/windowsill/windowsill_wood';
import { VidlyvyCard } from './product_card/cards/vidlivy/vidlyvy';
import { Tkaninny_Rolety_Product_Card } from './product_card/cards/rolety/tkanninny_rolety';
import { Den_nich_Rolety_Product_Card } from './product_card/cards/rolety/den_nich_rolety';
import { Screen_Rolety_Product_Card } from './product_card/cards/rolety/screen_rolety';
import { Moskitni_sitky } from './catalog/catalog_pages/moskitni_sitky/moskitni_sitky';
import { Moskitni_Sitky_Product_Card } from './product_card/cards/moskitni_sitki/moskitni_sitki';
import { Furnitura } from './catalog/catalog_pages/furnitura/furnitura';
import { InformationAlumWIndowPage } from './information_page/pages/alumWindow';
import { AlumDoorCategories, InformationAlumDoorPage } from './information_page/pages/alumDoor';
import { InformationPanpramWindowPage } from './information_page/pages/panoramWindow';
import { Furnitura_Product_Card } from './product_card/cards/furnitura/furnitura';
import { Montaz } from './catalog/catalog_pages/montaz/montaz';
import { Montaz_Product_Card } from './product_card/cards/montaz/montaz';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/calculator" element={<Calculator />} />
        {/* Каталог */}
        <Route path="/catalog/plastic_windows" element={<PlasticWindowCatalog />} />
        <Route path="/catalog/interior_doors" element={<InteriorDoorsCatalog />} />
        <Route path="/catalog/entrance_doors" element={<EntranceDoorsCatalog />} />
        <Route path="/catalog/windowsill" element={<Windowsill />} />
        <Route path="/catalog/wood_windowsill" element={<WoodWindowsill />} />
        <Route path="/catalog/tkanynni_rolety" element={<TkanevRollety />} />
        <Route path="/catalog/den-nich_rolety" element={<DenNichRollety />} />
        <Route path="/catalog/screen_rolety" element={<ScreenRollety />} />
        <Route path="/catalog/vidlyv" element={<Vidlyv />} />
        <Route path="/catalog/moskitni_sitki" element={<Moskitni_sitky />} />
        <Route path="/catalog/furnitura" element={<Furnitura />} />
        <Route path="/catalog/materialy_dlya_montazhu" element={<Montaz />} />
        {/* Карточки товару */}
        <Route path="/products/windows/:id" element={<ProductPageWindow />} />
        <Route path="/products/interior_door/:id" element={<InteriorDoorProductPage />} />
        <Route path="/products/entrance_door/:id" element={<EntranceDoorProductPage />} />
        <Route path="/products/pidvikonnya/:id" element={<WindowsillProductsCard />} />
        <Route path="/products/derevyane_pidvikonnya/:id" element={<WoodWindowsillProductsCard />} />
        <Route path="/products/vodovidlivy/:id" element={<VidlyvyCard />} />
        <Route path="/products/tkanynni_rolety/:id" element={<Tkaninny_Rolety_Product_Card />} />
        <Route path="/products/den_nich_rolety/:id" element={<Den_nich_Rolety_Product_Card />} />
        <Route path="/products/screen_rolety/:id" element={<Screen_Rolety_Product_Card />} />
        <Route path="/products/moskitni_sitki/:id" element={<Moskitni_Sitky_Product_Card />} />
        <Route path="/products/furnitura/:id" element={<Furnitura_Product_Card />} />
        <Route path="/products/materialy_dlya_montazhu/:id" element={<Montaz_Product_Card />} />
        {/* InformationPage */}
        <Route path="/details/balcon" element={<InformationPage data={BalconInformation} />} />
        <Route path="/details/door" element={<InformationPage data={DoorInformation} />} />
        <Route path="/details/rozsuvni_systemy" element={<InformationPage data={RozsyvniSistemi} />} />
        <Route path="/details/dveri_prykhovanoho_montazhu" element={<InformationPage data={DveriPryhovani} />} />
        <Route path="/details/alyuminiyevi_vikna" element={<InformationAlumWIndowPage />} />
        <Route path="/details/alyuminiyevi_dvery" element={<InformationAlumDoorPage/>} />
        <Route path="/details/panoramni_vikna" element={< InformationPanpramWindowPage />} />
        {/* Типи алюмінію */}
        <Route path="/details/alyuminiyevi_dvery/tepliy_aluminiy" element={<AlumDoorCategories data={warmAlum}/>} />
        <Route path="/details/alyuminiyevi_dvery/khololniy_alyuminiy" element={<AlumDoorCategories data={coldAlum}/>} />
        <Route path="/details/alyuminiyevi_dvery/rozpashni_dveri" element={<AlumDoorCategories data={rozpashniDoor}/>} />
        <Route path="/details/alyuminiyevi_dvery/rozsuvni_dveri" element={<AlumDoorCategories data={rozsyvniDoor}/>} />
        {/* Наші проекти */}
        <Route path="/project/city_villa_club" element={<PhotoProjectPage data={CityVillaClub} />} />
        <Route path="/project/central_park" element={<PhotoProjectPage data={CentralPark} />} />
        <Route path="/project/yuvileynyy_2" element={<PhotoProjectPage data={Uvileyniy2_3} />} />
        <Route path="/project/stozary" element={<PhotoProjectPage data={Stozary} />} />
        <Route path="/project/opryshivska_sloboda" element={<PhotoProjectPage data={Opryshkivska} />} />
        <Route path="/project/khmelnytskyy" element={<PhotoProjectPage data={Hmelnitsky} />} />
        <Route path="/project/pelmenna" element={<PhotoProjectPage data={Pelmenna} />} />
        <Route path="/project/yuvileynyy_1" element={<PhotoProjectPage data={Uvileyniy_1} />} />
        <Route path="/project/lipky" element={<PhotoProjectPage data={Lipky} />} />
        
      </Routes>
    </BrowserRouter>
  );
}
export default App;
