import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { Montaz_Card } from "../../../landing_page/products/cards";
import { Background, CardWrapper, CardsBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage, FilterBlock, EmptyBlock } from "../../catalog_page_components";
import { ArtDenDrawer } from "../../drower";
import { mantazProducts, montazPrices } from "../../product_data/material_for_montaz";
import { useState } from "react";
import { PriceRangeSlider } from "../../filterBlock/price_slider";

export const Montaz = () => {
    const [price, setPrice] = useState([montazPrices.min, montazPrices.max]);
    const [minPrice, maxPrice] = price;
    const filteredProducts = mantazProducts.filter((product) => {
        const price = parseInt(product.price, 10);
        return price >= minPrice && price <= maxPrice;
    })

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Матеріали для монтажу</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                    <PriceRangeSlider min={montazPrices.min} max={montazPrices.max} onPriceChange={setPrice} />
                                    <FilterBlock>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                <PriceRangeSlider min={montazPrices.min} max={montazPrices.max} onPriceChange={setPrice} />
                                <FilterBlock style={{opacity:"0"}}></FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}
                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <Montaz_Card
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                param2={p.param2}
                                                price={p.price}
                                            ></Montaz_Card>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background><SecondaryActions></SecondaryActions><CallBackForm></CallBackForm><Footer></Footer>
        </div >
    );
};