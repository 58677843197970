import CityVillaClub1 from "../../../images/projects/cityVillaClub/pr1.jpg"
import CityVillaClub2 from "../../../images/projects/cityVillaClub/pr2.jpg"
import CityVillaClub3 from "../../../images/projects/cityVillaClub/pr3.jpg"
import CityVillaClub4 from "../../../images/projects/cityVillaClub/pr4.jpg"
import CityVillaClub5 from "../../../images/projects/cityVillaClub/pr5.jpg"
import CityVillaClub6 from "../../../images/projects/cityVillaClub/pr6.jpg"
import CentralPark1 from "../../../images/projects/centralPark/pr1.jpg"
import CentralPark2 from "../../../images/projects/centralPark/pr2.jpg"
import CentralPark3 from "../../../images/projects/centralPark/pr3.jpg"
import CentralPark4 from "../../../images/projects/centralPark/pr4.jpg"
import CentralPark5 from "../../../images/projects/centralPark/pr5.jpg"
import Uvileyniy1 from "../../../images/projects/uvileyniy_3-4/pr1.jpg"
import Uvileyniy2 from "../../../images/projects/uvileyniy_3-4/pr2.jpg"
import Uvileyniy3 from "../../../images/projects/uvileyniy_3-4/pr3.jpg"
import Uvileyniy4 from "../../../images/projects/uvileyniy_3-4/pr4.jpg"
import Uvileyniy5 from "../../../images/projects/uvileyniy_3-4/pr5.jpg"
import Stozary1 from "../../../images/projects/stozary/pr1.jpg"
import Stozary2 from "../../../images/projects/stozary/pr2.jpg"
import Stozary3 from "../../../images/projects/stozary/pr3.jpg"
import Stozary4 from "../../../images/projects/stozary/pr4.jpg"
import Stozary5 from "../../../images/projects/stozary/pr5.jpg"
import Opryshkivska1 from "../../../images/projects/opryshkivska/pr1.jpg"
import Opryshkivska2 from "../../../images/projects/opryshkivska/pr2.jpg"
import Opryshkivska3 from "../../../images/projects/opryshkivska/pr3.jpg"
import Opryshkivska4 from "../../../images/projects/opryshkivska/pr4.jpg"
import Opryshkivska5 from "../../../images/projects/opryshkivska/pr5.jpg"
import Hmelnitsky1 from "../../../images/projects/hmelnitskiy/pr1.jpg"
import Hmelnitsky2 from "../../../images/projects/hmelnitskiy/pr2.jpg"
import Hmelnitsky3 from "../../../images/projects/hmelnitskiy/pr3.jpg"
import Hmelnitsky4 from "../../../images/projects/hmelnitskiy/pr4.jpg"
import Hmelnitsky5 from "../../../images/projects/hmelnitskiy/pr5.jpg"
import Pelmenna1 from "../../../images/projects/pelmenna/pr1.jpg"
import Pelmenna2 from "../../../images/projects/pelmenna/pr2.jpg"
import Pelmenna3 from "../../../images/projects/pelmenna/pr3.jpg"
import Pelmenna4 from "../../../images/projects/pelmenna/pr4.jpg"
import Pelmenna5 from "../../../images/projects/pelmenna/pr5.jpg"
import Uvileyniy11 from "../../../images/projects/uvileyniy_1cherga/pr1.jpg"
import Uvileyniy12 from "../../../images/projects/uvileyniy_1cherga/pr2.jpg"
import Uvileyniy13 from "../../../images/projects/uvileyniy_1cherga/pr3.jpg"
import Uvileyniy14 from "../../../images/projects/uvileyniy_1cherga/pr4.jpg"
import Uvileyniy15 from "../../../images/projects/uvileyniy_1cherga/pr5.jpg"
import Lipky1 from "../../../images/projects/lipky/pr1.jpg"
import Lipky2 from "../../../images/projects/lipky/pr2.jpg"
import Lipky3 from "../../../images/projects/lipky/pr3.jpg"
import Lipky4 from "../../../images/projects/lipky/pr4.jpg"
import Lipky5 from "../../../images/projects/lipky/pr5.jpg"


export const CityVillaClub={
    title: "Котеджне містечко City Villa Club",
    year:"2022p.",
    data:[
    {id:2200,image: CityVillaClub1},
    {id:2201,image: CityVillaClub2},
    {id:2202,image: CityVillaClub3},
    {id:2203,image: CityVillaClub4},
    {id:2204,image: CityVillaClub5},
    {id:2205,image: CityVillaClub6},
]}

export const CentralPark={
    title: "ЖК “Central Park” ",
    year:"2022p.",
    data:[
    {id:2206,image: CentralPark1},
    {id:2207,image: CentralPark2},
    {id:2208,image: CentralPark3},
    {id:2209,image: CentralPark4},
    {id:2210,image: CentralPark5},
]}

export const Uvileyniy2_3={
    title: "ЖК “Ювілейний” 2-3 черга ",
    year:"2022p.",
    data:[
    {id:2211,image: Uvileyniy1},
    {id:2212,image: Uvileyniy2},
    {id:2213,image: Uvileyniy3},
    {id:2214,image: Uvileyniy4},
    {id:2215,image: Uvileyniy5},
]}

export const Stozary={
    title: "ЖК “Cтожари” ",
    year:"2022p.",
    data:[
    {id:2216,image: Stozary1},
    {id:2217,image: Stozary2},
    {id:2218,image: Stozary3},
    {id:2219,image: Stozary4},
    {id:2220,image: Stozary5},
]}

export const Opryshkivska={
    title: "ЖК “Опришівська Слобода” " ,
    year:"2020p.",
    data:[
    {id:2221,image: Opryshkivska1},
    {id:2222,image: Opryshkivska2},
    {id:2223,image: Opryshkivska3},
    {id:2224,image: Opryshkivska4},
    {id:2225,image: Opryshkivska5},
]}

export const Hmelnitsky={
    title: "ЖК “Хмельницький” " ,
    year:"2019p.",
    data:[
    {id:2226,image: Hmelnitsky1},
    {id:2227,image: Hmelnitsky2},
    {id:2228,image: Hmelnitsky3},
    {id:2229,image: Hmelnitsky4},
    {id:2230,image: Hmelnitsky5},
]}

export const Pelmenna={
    title: "Пельменна" ,
    year:"2021p.",
    data:[
    {id:2231,image: Pelmenna1},
    {id:2232,image: Pelmenna2},
    {id:2233,image: Pelmenna3},
    {id:2234,image: Pelmenna4},
    {id:2235,image: Pelmenna5},
]}

export const Uvileyniy_1={
    title: "ЖК “Ювілейний” 1 черга" ,
    year:"2020p.",
    data:[
    {id:2236,image: Uvileyniy11},
    {id:2237,image: Uvileyniy12},
    {id:2238,image: Uvileyniy13},
    {id:2239,image: Uvileyniy14},
    {id:2240,image: Uvileyniy15},
]}

export const Lipky={
    title: "ЖК “Липки” "  ,
    year:"2019p.",
    data:[
    {id:2241,image: Lipky1},
    {id:2242,image: Lipky2},
    {id:2243,image: Lipky3},
    {id:2244,image: Lipky4},
    {id:2245,image: Lipky5},
]}