import door_1 from "../../images/catalog/entrance_door/bulat/910.jpg"
import door_2 from "../../images/catalog/entrance_door/bulat/111.jpg"
import door_3 from "../../images/catalog/entrance_door/bulat/112.jpeg"
import door_4 from "../../images/catalog/entrance_door/bulat/116.avif"
import door_5 from "../../images/catalog/entrance_door/bulat/117.jpg"
import door_6 from "../../images/catalog/entrance_door/bulat/119.avif"
import door_7 from "../../images/catalog/entrance_door/bulat/127.avif"
import door_8 from "../../images/catalog/entrance_door/bulat/131.jpeg"
import door_9 from "../../images/catalog/entrance_door/bulat/137.avif"
import door_10 from "../../images/catalog/entrance_door/bulat/154.jpg"
import door_11 from "../../images/catalog/entrance_door/bulat/157.jpg"
import door_12 from "../../images/catalog/entrance_door/bulat/158.jpg"
import door_13 from "../../images/catalog/entrance_door/bulat/159.jpg"
import door_14 from "../../images/catalog/entrance_door/bulat/160.jpg"
import door_15 from "../../images/catalog/entrance_door/bulat/163.jpeg"
import door_16 from "../../images/catalog/entrance_door/bulat/165.jpg"
import door_17 from "../../images/catalog/entrance_door/bulat/166.jpg"
import door_18 from "../../images/catalog/entrance_door/bulat/167.jpg"
import door_19 from "../../images/catalog/entrance_door/bulat/168.jpg"
import door_20 from "../../images/catalog/entrance_door/bulat/169.jpg"
import door_21 from "../../images/catalog/entrance_door/bulat/170.jpg"
import door_22 from "../../images/catalog/entrance_door/bulat/171.jpeg"
import door_23 from "../../images/catalog/entrance_door/bulat/172.jpeg"
import door_24 from "../../images/catalog/entrance_door/bulat/173.jpg"
import door_25 from "../../images/catalog/entrance_door/bulat/176.jpeg"
import door_26 from "../../images/catalog/entrance_door/bulat/180.jpeg"
import door_27 from "../../images/catalog/entrance_door/bulat/181.jpg"
import door_28 from "../../images/catalog/entrance_door/bulat/184.jpg"
import door_29 from "../../images/catalog/entrance_door/bulat/185.jpg"
import door_30 from "../../images/catalog/entrance_door/bulat/186.jpg"
import door_31 from "../../images/catalog/entrance_door/bulat/228.jpg"
import door_32 from "../../images/catalog/entrance_door/bulat/231.jpg"
import door_33 from "../../images/catalog/entrance_door/bulat/232.jpg"
import door_34 from "../../images/catalog/entrance_door/bulat/233.jpg"
import door_35 from "../../images/catalog/entrance_door/bulat/234.jpeg"
import door_36 from "../../images/catalog/entrance_door/bulat/235.jpg"
import door_37 from "../../images/catalog/entrance_door/bulat/246.avif"
import door_38 from "../../images/catalog/entrance_door/bulat/247.avif"
import door_39 from "../../images/catalog/entrance_door/bulat/411.jpeg"
import door_40 from "../../images/catalog/entrance_door/bulat/412.jpeg"
import door_41 from "../../images/catalog/entrance_door/bulat/414.avif"
import door_42 from "../../images/catalog/entrance_door/bulat/418.avif"
import door_43 from "../../images/catalog/entrance_door/bulat/423.jpg"
import door_44 from "../../images/catalog/entrance_door/bulat/425.jpg"
import door_45 from "../../images/catalog/entrance_door/bulat/513.jpeg"
import door_46 from "../../images/catalog/entrance_door/bulat/514.jpeg"
import door_47 from "../../images/catalog/entrance_door/bulat/517.jpeg"
import door_48 from "../../images/catalog/entrance_door/bulat/522.jpeg"
import door_49 from "../../images/catalog/entrance_door/bulat/526.jpg"
import door_50 from "../../images/catalog/entrance_door/bulat/528.jpg"
import door_51 from "../../images/catalog/entrance_door/bulat/189.jpg"
import door_52 from "../../images/catalog/entrance_door/bulat/190.jpg"
import door_522 from "../../images/catalog/entrance_door/bulat/191.jpg"
import door_53 from "../../images/catalog/entrance_door/bulat/192.jpeg"
import door_54 from "../../images/catalog/entrance_door/bulat/193.jpg"
import door_55 from "../../images/catalog/entrance_door/bulat/194.jpg"
import door_56 from "../../images/catalog/entrance_door/bulat/195.jpg"
import door_57 from "../../images/catalog/entrance_door/bulat/196.jpg"
import door_58 from "../../images/catalog/entrance_door/bulat/197.jpg"
import door_59 from "../../images/catalog/entrance_door/bulat/198.jpg"
import door_61 from "../../images/catalog/entrance_door/bulat/206.avif"
import door_62 from "../../images/catalog/entrance_door/bulat/208.avif"
import door_63 from "../../images/catalog/entrance_door/bulat/215.avif"
import door_64 from "../../images/catalog/entrance_door/bulat/216.jpeg"
import door_65 from "../../images/catalog/entrance_door/bulat/217.jpg"
import door_66 from "../../images/catalog/entrance_door/bulat/219.jpg"
import door_67 from "../../images/catalog/entrance_door/bulat/220.jpg"
import door_68 from "../../images/catalog/entrance_door/bulat/222.jpg"
import door_69 from "../../images/catalog/entrance_door/bulat/225.jpg"
import door_70 from "../../images/catalog/entrance_door/bulat/604.avif"
import door_71 from "../../images/catalog/entrance_door/bulat/802.jpg"
import door_72 from "../../images/catalog/entrance_door/bulat/804.jpg"
import door_73 from "../../images/catalog/entrance_door/bulat/808.jpg"
import door_74 from "../../images/catalog/entrance_door/bulat/904.avif"
import door_75 from "../../images/catalog/entrance_door/bulat/907.jpg"
import door_76 from "../../images/catalog/entrance_door/bulat/909.avif"
import door_77 from "../../images/catalog/entrance_door/arma/810.avif"
import door_78 from "../../images/catalog/entrance_door/arma/840.avif"
import door_79 from "../../images/catalog/entrance_door/arma/105,1.webp"
import door_80 from "../../images/catalog/entrance_door/arma/105.1.1.webp"
import door_81 from "../../images/catalog/entrance_door/arma/116.webp"
import door_82 from "../../images/catalog/entrance_door/arma/119,7.avif"
import door_83 from "../../images/catalog/entrance_door/arma/121.webp"
import door_84 from "../../images/catalog/entrance_door/arma/126.webp"
import door_85 from "../../images/catalog/entrance_door/arma/127.webp"
import door_86 from "../../images/catalog/entrance_door/arma/129.avif"
import door_87 from "../../images/catalog/entrance_door/arma/153.avif"
import door_88 from "../../images/catalog/entrance_door/arma/157.avif"
import door_89 from "../../images/catalog/entrance_door/arma/160.avif"
import door_90 from "../../images/catalog/entrance_door/arma/203.webp"
import door_91 from "../../images/catalog/entrance_door/arma/209.webp"
import door_92 from "../../images/catalog/entrance_door/arma/210.webp"
import door_93 from "../../images/catalog/entrance_door/arma/213.webp"
import door_94 from "../../images/catalog/entrance_door/arma/215.webp"
import door_96 from "../../images/catalog/entrance_door/arma/216.webp"
import door_97 from "../../images/catalog/entrance_door/arma/217.webp"
import door_98 from "../../images/catalog/entrance_door/arma/218.webp"
import door_99 from "../../images/catalog/entrance_door/arma/219.webp"
import door_100 from "../../images/catalog/entrance_door/arma/314.webp"
import door_101 from "../../images/catalog/entrance_door/arma/327.avif"
import door_102 from "../../images/catalog/entrance_door/arma/332.avif"
import door_103 from "../../images/catalog/entrance_door/arma/503.avif"
import door_104 from "../../images/catalog/entrance_door/arma/504.webp"
import door_105 from "../../images/catalog/entrance_door/arma/600.avif"
import door_106 from "../../images/catalog/entrance_door/arma/601.avif"
import door_107 from "../../images/catalog/entrance_door/arma/612.avif"
import door_108 from "../../images/catalog/entrance_door/arma/615.avif"
import door_109 from "../../images/catalog/entrance_door/arma/711.webp"
import door_110 from "../../images/catalog/entrance_door/arma/720.webp"
import door_111 from "../../images/catalog/entrance_door/arma/750.webp"
import door_112 from "../../images/catalog/entrance_door/arma/760.webp"
import door_113 from "../../images/catalog/entrance_door/arma/800.webp"


//Вхідні двері 
// Виробник
export enum EntranceDoorBrands {
    ARMA = 'Arma',
    BULAT = 'Bulat',
}
export const defaultEntranceDoorBrands = [
    { value: EntranceDoorBrands.ARMA, label: 'Арма' },
    { value: EntranceDoorBrands.BULAT, label: 'Булат' },
]
// Колір
export enum EntranceDoorColor {
    WHITE = 'biliy',
    BEIGE = 'beige',
    BROWN = 'brown',
    GREY = 'grey',
    BLACK='black',
}
export const defaultEntranceDoorColor = [
    { value: EntranceDoorColor.BLACK, label: 'Чорний' },
    { value: EntranceDoorColor.WHITE, label: 'Білий' },
    { value: EntranceDoorColor.BEIGE, label: 'Молочний' },
    { value: EntranceDoorColor.BROWN, label: 'Коричневий' },
    { value: EntranceDoorColor.GREY, label: 'Сірий' }
]
//Тип
export enum EntranceDoorType {
    PROFIL = 'profil',
    TRUBA = 'truba',
    BOTH = 'obydva',
}
export const defaultEntranceDoorType = [
    { value: EntranceDoorType.PROFIL, label: 'Гнутий профіль ' },
    { value: EntranceDoorType.TRUBA, label: 'Труба профільна ' },
    { value: EntranceDoorType.BOTH, label: 'Обидва варіанта' },
]
export const entranceDoorProducts = [
    //  Булат
    { id: 1, image: door_1, brand: EntranceDoorBrands.BULAT, name: "910-1 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 2, image: door_2, brand: EntranceDoorBrands.BULAT, name: "111 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 3, image: door_3, brand: EntranceDoorBrands.BULAT, name: "112 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 4, image: door_4, brand: EntranceDoorBrands.BULAT, name: "116 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 5, image: door_5, brand: EntranceDoorBrands.BULAT, name: "117 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 6, image: door_6, brand: EntranceDoorBrands.BULAT, name: "119 Булат", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 7, image: door_7, brand: EntranceDoorBrands.BULAT, name: "127 Булат",color:EntranceDoorColor.WHITE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 8, image: door_8, brand: EntranceDoorBrands.BULAT, name: "131 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 9, image: door_9, brand: EntranceDoorBrands.BULAT, name: "137 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 10, image: door_10, brand: EntranceDoorBrands.BULAT, name: "154 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 11, image: door_11, brand: EntranceDoorBrands.BULAT, name: "157 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 12, image: door_12, brand: EntranceDoorBrands.BULAT, name: "158 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 13, image: door_13, brand: EntranceDoorBrands.BULAT, name: "159 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 14, image: door_14, brand: EntranceDoorBrands.BULAT, name: "160 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 15, image: door_15, brand: EntranceDoorBrands.BULAT, name: "163 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 16, image: door_16, brand: EntranceDoorBrands.BULAT, name: "165 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 17, image: door_17, brand: EntranceDoorBrands.BULAT, name: "166 Булат",color:EntranceDoorColor.WHITE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 18, image: door_18, brand: EntranceDoorBrands.BULAT, name: "167 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 19, image: door_19, brand: EntranceDoorBrands.BULAT, name: "168 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 20, image: door_20, brand: EntranceDoorBrands.BULAT, name: "169 Булат",color:EntranceDoorColor.WHITE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 21, image: door_21, brand: EntranceDoorBrands.BULAT, name: "170 Булат", color:EntranceDoorColor.BEIGE,type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 22, image: door_22, brand: EntranceDoorBrands.BULAT, name: "171 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 23, image: door_23, brand: EntranceDoorBrands.BULAT, name: "172 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 24, image: door_24, brand: EntranceDoorBrands.BULAT, name: "173 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 25, image: door_25, brand: EntranceDoorBrands.BULAT, name: "176 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 26, image: door_26, brand: EntranceDoorBrands.BULAT, name: "180 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 27, image: door_27, brand: EntranceDoorBrands.BULAT, name: "181 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 28, image: door_28, brand: EntranceDoorBrands.BULAT, name: "184 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 29, image: door_29, brand: EntranceDoorBrands.BULAT, name: "185 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 30, image: door_30, brand: EntranceDoorBrands.BULAT, name: "186 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 31, image: door_31, brand: EntranceDoorBrands.BULAT, name: "228 Булат",color:EntranceDoorColor.WHITE, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 32, image: door_32, brand: EntranceDoorBrands.BULAT, name: "231 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 33, image: door_33, brand: EntranceDoorBrands.BULAT, name: "232 Булат", color:EntranceDoorColor.BEIGE,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 34, image: door_34, brand: EntranceDoorBrands.BULAT, name: "233 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 35, image: door_35, brand: EntranceDoorBrands.BULAT, name: "234 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 36, image: door_36, brand: EntranceDoorBrands.BULAT, name: "235 Булат", color:EntranceDoorColor.BLACK,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 37, image: door_37, brand: EntranceDoorBrands.BULAT, name: "246 Булат",color:EntranceDoorColor.BEIGE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 38, image: door_38, brand: EntranceDoorBrands.BULAT, name: "247 Булат", color:EntranceDoorColor.BEIGE,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 39, image: door_39, brand: EntranceDoorBrands.BULAT, name: "411-2 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 40, image: door_40, brand: EntranceDoorBrands.BULAT, name: "412-2 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 41, image: door_41, brand: EntranceDoorBrands.BULAT, name: "414-2 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 42, image: door_42, brand: EntranceDoorBrands.BULAT, name: "418-2 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 43, image: door_43, brand: EntranceDoorBrands.BULAT, name: "423 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 44, image: door_44, brand: EntranceDoorBrands.BULAT, name: "425 Булат",color:EntranceDoorColor.BLACK, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 45, image: door_45, brand: EntranceDoorBrands.BULAT, name: "513-8 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 46, image: door_46, brand: EntranceDoorBrands.BULAT, name: "514 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 47, image: door_47, brand: EntranceDoorBrands.BULAT, name: "517-4 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 48, image: door_48, brand: EntranceDoorBrands.BULAT, name: "522-2 Булат",color:EntranceDoorColor.BEIGE, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 49, image: door_49, brand: EntranceDoorBrands.BULAT, name: "526 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 50, image: door_50, brand: EntranceDoorBrands.BULAT, name: "528 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 51, image: door_51, brand: EntranceDoorBrands.BULAT, name: "189 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 52, image: door_52, brand: EntranceDoorBrands.BULAT, name: "190 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 522, image: door_522, brand: EntranceDoorBrands.BULAT, name: "191 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 53, image: door_53, brand: EntranceDoorBrands.BULAT, name: "192 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 54, image: door_54, brand: EntranceDoorBrands.BULAT, name: "193 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 55, image: door_55, brand: EntranceDoorBrands.BULAT, name: "194 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 56, image: door_56, brand: EntranceDoorBrands.BULAT, name: "195 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів." },
    { id: 57, image: door_57, brand: EntranceDoorBrands.BULAT, name: "196 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 58, image: door_58, brand: EntranceDoorBrands.BULAT, name: "197 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 59, image: door_59, brand: EntranceDoorBrands.BULAT, name: "198 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 61, image: door_61, brand: EntranceDoorBrands.BULAT, name: "206 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 62, image: door_62, brand: EntranceDoorBrands.BULAT, name: "208 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 63, image: door_63, brand: EntranceDoorBrands.BULAT, name: "215 Булат",color:EntranceDoorColor.BLACK, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 64, image: door_64, brand: EntranceDoorBrands.BULAT, name: "216 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 65, image: door_65, brand: EntranceDoorBrands.BULAT, name: "217 Булат", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 66, image: door_66, brand: EntranceDoorBrands.BULAT, name: "219 Булат", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 67, image: door_67, brand: EntranceDoorBrands.BULAT, name: "220 Булат",color:EntranceDoorColor.BLACK, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 68, image: door_68, brand: EntranceDoorBrands.BULAT, name: "222 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 69, image: door_69, brand: EntranceDoorBrands.BULAT, name: "225 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 70, image: door_70, brand: EntranceDoorBrands.BULAT, name: "604 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Булат' , text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 71, image: door_71, brand: EntranceDoorBrands.BULAT, name: "802 Булат",color:EntranceDoorColor.GREY, type: EntranceDoorType.TRUBA, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 72, image: door_72, brand: EntranceDoorBrands.BULAT, name: "804 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.TRUBA, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 73, image: door_73, brand: EntranceDoorBrands.BULAT, name: "808 Булат",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 74, image: door_74, brand: EntranceDoorBrands.BULAT, name: "904 Булат",color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 75, image: door_75, brand: EntranceDoorBrands.BULAT, name: "907 Булат",color:EntranceDoorColor.BLACK, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    { id: 76, image: door_76, brand: EntranceDoorBrands.BULAT, name: "909 Булат", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Булат', text1:'Чернігівська фабрика вхідних дверей «Булат» є українським виробником.',text2:"Компанія «Двері Булат» виготовляє продукцію, використовуючи надійні та безпечні для здоров'я матеріали, що постачаються від провідних виробників. Серед них можна знайти плівку Vinorit WINSHIELD® (віншильд) з Ізраїлю, дверну фурнітуру від Mottura (Італія), Securemme (Італія), Kale (Туреччина), Mul-t-lock (Ізраїль), металопрокат від Метінвест (Україна), а також МДФ з Коростеня, Україна.", text3:"Ми пропонуємо широкий асортиментний ряд, який дозволяє запропонувати різні рішення для комплектації квартир, будинків, офісів, готелів, торгових і промислових приміщень, соціальних об’єктів."},
    // Арма
    { id: 78, image: door_77, brand: EntranceDoorBrands.ARMA, name: "810 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 79, image: door_78, brand: EntranceDoorBrands.ARMA, name: "840 Арма",color:EntranceDoorColor.BEIGE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 80, image: door_79, brand: EntranceDoorBrands.ARMA, name: "105,1 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 81, image: door_80, brand: EntranceDoorBrands.ARMA, name: "105,1-1 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 82, image: door_81, brand: EntranceDoorBrands.ARMA, name: "116 Арма", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 83, image: door_82, brand: EntranceDoorBrands.ARMA, name: "119 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 84, image: door_83, brand: EntranceDoorBrands.ARMA, name: "121 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 85, image: door_84, brand: EntranceDoorBrands.ARMA, name: "126 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 86, image: door_85, brand: EntranceDoorBrands.ARMA, name: "127 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 87, image: door_86, brand: EntranceDoorBrands.ARMA, name: "129 Арма", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 88, image: door_87, brand: EntranceDoorBrands.ARMA, name: "153 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 89, image: door_88, brand: EntranceDoorBrands.ARMA, name: "157 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 90, image: door_89, brand: EntranceDoorBrands.ARMA, name: "160 Арма",color:EntranceDoorColor.BEIGE, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 91, image: door_90, brand: EntranceDoorBrands.ARMA, name: "203 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 92, image: door_91, brand: EntranceDoorBrands.ARMA, name: "209 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.TRUBA, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 93, image: door_92, brand: EntranceDoorBrands.ARMA, name: "210 Арма", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 94, image: door_93, brand: EntranceDoorBrands.ARMA, name: "213 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 95, image: door_94, brand: EntranceDoorBrands.ARMA, name: "215 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 96, image: door_96, brand: EntranceDoorBrands.ARMA, name: "216 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 97, image: door_97, brand: EntranceDoorBrands.ARMA, name: "217 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 98, image: door_98, brand: EntranceDoorBrands.ARMA, name: "218 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 99, image: door_99, brand: EntranceDoorBrands.ARMA, name: "219 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 100, image: door_100, brand: EntranceDoorBrands.ARMA, name: "314 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 101, image: door_101, brand: EntranceDoorBrands.ARMA, name: "327 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 102, image: door_102, brand: EntranceDoorBrands.ARMA, name: "332 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 103, image: door_103, brand: EntranceDoorBrands.ARMA, name: "503 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 104, image: door_104, brand: EntranceDoorBrands.ARMA, name: "504 Арма", color:EntranceDoorColor.GREY,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 105, image: door_105, brand: EntranceDoorBrands.ARMA, name: "600 Арма", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 106, image: door_106, brand: EntranceDoorBrands.ARMA, name: "601 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 107, image: door_107, brand: EntranceDoorBrands.ARMA, name: "612 Арма", color:EntranceDoorColor.BLACK,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 108, image: door_108, brand: EntranceDoorBrands.ARMA, name: "615 Арма", color:EntranceDoorColor.BROWN,type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 109, image: door_109, brand: EntranceDoorBrands.ARMA, name: "711 Арма",color:EntranceDoorColor.BLACK, type: EntranceDoorType.PROFIL, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},
    { id: 110, image: door_110, brand: EntranceDoorBrands.ARMA, name: "720 Арма",color:EntranceDoorColor.GREY, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 111, image: door_111, brand: EntranceDoorBrands.ARMA, name: "750 Арма",color:EntranceDoorColor.BLACK, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 112, image: door_112, brand: EntranceDoorBrands.ARMA, name: "760 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null, virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта." },
    { id: 113, image: door_113, brand: EntranceDoorBrands.ARMA, name: "800 Арма",color:EntranceDoorColor.BROWN, type: EntranceDoorType.BOTH, param1: 'Покриття: квартирне / вуличне', param2: 'Броненакладка: так', param3: 'Верхній замок: На вибір', price: null , virobnyk:'Арма', text1:'АРМА - це провідний виробник металевих вхідних дверей високої якості в Україні.',text2:"Компанія ставить перед собою завдання використовувати тільки сертифіковані матеріали у виробничому процесі, що дозволяє досягати високих стандартів якості на кожному етапі виробництва. Автономне промислове підприємство АРМА забезпечує повний контроль якості та безперебійне постачання виробів в необхідних обсягах.", text3:"Компанія розуміє, що високі стандарти якості є обов'язковою складовою успіху. Основним завданням підприємства є виробництво надійних та теплих металевих дверей, які здатні ефективно захистити будинок від вторгнення сторонніх людей та проникнення холоду. АРМА пропонує споживачам унікальні позиції та моделі дверей, які мають різний дизайн та можуть задовольнити потреби будь-якого клієнта."},

]

