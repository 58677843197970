import rol1 from "../../images/catalog/rolety/den-nich/805.webp"
import rol2 from "../../images/catalog/rolety/den-nich/806.webp"
import rol3 from "../../images/catalog/rolety/den-nich/807.webp"
import rol4 from "../../images/catalog/rolety/den-nich/808.webp"
import rol5 from "../../images/catalog/rolety/den-nich/809.webp"
import rol6 from "../../images/catalog/rolety/den-nich/901.webp"
import rol7 from "../../images/catalog/rolety/den-nich/902.webp"
import rol8 from "../../images/catalog/rolety/den-nich/903.webp"
import rol9 from "../../images/catalog/rolety/den-nich/904.webp"
import rol10 from "../../images/catalog/rolety/den-nich/905.webp"
import rol11 from "../../images/catalog/rolety/den-nich/906.webp"
import rol12 from "../../images/catalog/rolety/den-nich/907.webp"
import rol13 from "../../images/catalog/rolety/den-nich/908.webp"
import rol14 from "../../images/catalog/rolety/den-nich/909.webp"
import rol15 from "../../images/catalog/rolety/den-nich/1001.webp"
import rol16 from "../../images/catalog/rolety/den-nich/1002.webp"
import rol17 from "../../images/catalog/rolety/den-nich/1003.webp"
import rol18 from "../../images/catalog/rolety/den-nich/1004.webp"
import rol19 from "../../images/catalog/rolety/den-nich/1005.webp"
import rol20 from "../../images/catalog/rolety/den-nich/601.webp"
import rol21 from "../../images/catalog/rolety/den-nich/107.webp"
import rol22 from "../../images/catalog/rolety/den-nich/201.webp"
import rol23 from "../../images/catalog/rolety/den-nich/202.webp"
import rol24 from "../../images/catalog/rolety/den-nich/203.webp"
import rol25 from "../../images/catalog/rolety/den-nich/204.webp"
import rol26 from "../../images/catalog/rolety/den-nich/205.webp"
import rol27 from "../../images/catalog/rolety/den-nich/206.webp"
import rol28 from "../../images/catalog/rolety/den-nich/301.webp"
import rol29 from "../../images/catalog/rolety/den-nich/302.webp"
import rol30 from "../../images/catalog/rolety/den-nich/303.webp"
import rol31 from "../../images/catalog/rolety/den-nich/304.webp"
import rol32 from "../../images/catalog/rolety/den-nich/305.webp"
import rol33 from "../../images/catalog/rolety/den-nich/306.webp"
import rol34 from "../../images/catalog/rolety/den-nich/401.webp"
import rol35 from "../../images/catalog/rolety/den-nich/402.webp"
import rol36 from "../../images/catalog/rolety/den-nich/403.webp"
import rol37 from "../../images/catalog/rolety/den-nich/404.webp"
import rol38 from "../../images/catalog/rolety/den-nich/405.webp"
import rol39 from "../../images/catalog/rolety/den-nich/406.webp"
import rol40 from "../../images/catalog/rolety/den-nich/407.webp"
import rol41 from "../../images/catalog/rolety/den-nich/501.webp"
import rol42 from "../../images/catalog/rolety/den-nich/502.webp"
import rol43 from "../../images/catalog/rolety/den-nich/503.webp"
import rol44 from "../../images/catalog/rolety/den-nich/505.webp"
import rol45 from "../../images/catalog/rolety/den-nich/602.webp"
import rol46 from "../../images/catalog/rolety/den-nich/603.webp"
import rol47 from "../../images/catalog/rolety/den-nich/604.webp"
import rol48 from "../../images/catalog/rolety/den-nich/605.webp"
import rol49 from "../../images/catalog/rolety/den-nich/606.webp"
import rol50 from "../../images/catalog/rolety/den-nich/607.webp"
import rol51 from "../../images/catalog/rolety/den-nich/608.webp"
import rol53 from "../../images/catalog/rolety/den-nich/610.webp"
import rol54 from "../../images/catalog/rolety/den-nich/611.webp"
import rol56 from "../../images/catalog/rolety/den-nich/612.webp"
import rol57 from "../../images/catalog/rolety/den-nich/613.webp"
import rol58 from "../../images/catalog/rolety/den-nich/614.webp"
import rol59 from "../../images/catalog/rolety/den-nich/615.webp"
import rol60 from "../../images/catalog/rolety/den-nich/616.webp"
import rol61 from "../../images/catalog/rolety/den-nich/617.png"
import rol63 from "../../images/catalog/rolety/den-nich/618.webp"
import rol64 from "../../images/catalog/rolety/den-nich/619.webp"
import rol65 from "../../images/catalog/rolety/den-nich/504.webp"
import rol66 from "../../images/catalog/rolety/den-nich/609.webp"
import rol67 from "../../images/catalog/rolety/den-nich/111.webp"
import rol68 from "../../images/catalog/rolety/den-nich/112.webp"
import rol69 from "../../images/catalog/rolety/den-nich/113.webp"
import rol70 from "../../images/catalog/rolety/den-nich/207.webp"
import rol71 from "../../images/catalog/rolety/den-nich/208.webp"
import rol73 from "../../images/catalog/rolety/den-nich/209.webp"
import rol74 from "../../images/catalog/rolety/den-nich/210.webp"
import rol75 from "../../images/catalog/rolety/den-nich/211.webp"
import rol76 from "../../images/catalog/rolety/den-nich/212.webp"
import rol77 from "../../images/catalog/rolety/den-nich/213.webp"
import rol78 from "../../images/catalog/rolety/den-nich/506.webp"
import rol79 from "../../images/catalog/rolety/den-nich/507.webp"
import rol80 from "../../images/catalog/rolety/den-nich/508.webp"
import rol81 from "../../images/catalog/rolety/den-nich/509.webp"
import rol82 from "../../images/catalog/rolety/den-nich/510.webp"
import rol83 from "../../images/catalog/rolety/den-nich/704.webp"
import rol84 from "../../images/catalog/rolety/den-nich/705.webp"
import rol85 from "../../images/catalog/rolety/den-nich/706.webp"
import rol86 from "../../images/catalog/rolety/den-nich/707.webp"
import rol87 from "../../images/catalog/rolety/den-nich/708.webp"
import rol88 from "../../images/catalog/rolety/den-nich/709.webp"
import rol89 from "../../images/catalog/rolety/den-nich/710.webp"
import rol90 from "../../images/catalog/rolety/den-nich/711.webp"
import rol91 from "../../images/catalog/rolety/den-nich/712.webp"
import rol92 from "../../images/catalog/rolety/den-nich/101.webp"
import rol93 from "../../images/catalog/rolety/den-nich/102.webp"
import rol94 from "../../images/catalog/rolety/den-nich/103.webp"
import rol95 from "../../images/catalog/rolety/den-nich/104.webp"
import rol96 from "../../images/catalog/rolety/den-nich/105.webp"
import rol97 from "../../images/catalog/rolety/den-nich/106.webp"
import rol98 from "../../images/catalog/rolety/den-nich/108.webp"
import rol99 from "../../images/catalog/rolety/den-nich/109.webp"
import rol100 from "../../images/catalog/rolety/den-nich/110.png"
import rol101 from "../../images/catalog/rolety/den-nich/408.webp"
import rol102 from "../../images/catalog/rolety/den-nich/409.webp"
import rol103 from "../../images/catalog/rolety/den-nich/410.webp"
import rol104 from "../../images/catalog/rolety/den-nich/411.webp"
import rol105 from "../../images/catalog/rolety/den-nich/701.webp"
import rol106 from "../../images/catalog/rolety/den-nich/702.webp"
import rol107 from "../../images/catalog/rolety/den-nich/703.webp"
import rol108 from "../../images/catalog/rolety/den-nich/801.webp"
import rol109 from "../../images/catalog/rolety/den-nich/802.webp"
import rol110 from "../../images/catalog/rolety/den-nich/803.webp"
import rol111 from "../../images/catalog/rolety/den-nich/804.webp"


// Колір
export enum DenNichRollColor {
    ODNOTONNA = 'odnotonna',
    VIZERYNKOVA = 'vizerunkova',
    SOLOMKA = 'solomka',
    SVITLONEPRONYKNA = 'svitlonepronykna',
    ZBLUSKOM = 'zbluskom',
}
export const defaultDenNichColor = [
    { value: DenNichRollColor.ODNOTONNA, label: 'Однотонна' },
    { value: DenNichRollColor.VIZERYNKOVA, label: 'Візерункова' },
    { value: DenNichRollColor.SOLOMKA, label: 'Соломка' },
    { value: DenNichRollColor.SVITLONEPRONYKNA, label: 'Світлонепроникна' },
    { value: DenNichRollColor.ZBLUSKOM, label: 'З блиском' },
]
export const denNichRollColorPrices = { min: 1500, max: 2500 };

export const denNichRollProducts = [
    
    { id: 300, image: rol1, name: "Ролета день-ніч DN 805", price: '2139',color:DenNichRollColor.VIZERYNKOVA},
    { id: 301, image: rol2, name: "Ролета день-ніч DN 806", price: '2139',color:DenNichRollColor.VIZERYNKOVA},
    { id: 302, image: rol3, name: "Ролета день-ніч DN 807", price: '2139' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 303, image: rol4, name: "Ролета день-ніч DN 808", price: '2139' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 304, image: rol5, name: "Ролета день-ніч DN 809", price: '2139' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 305, image: rol6, name: "Ролета день-ніч DN 901", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 306, image: rol7, name: "Ролета день-ніч DN 902", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 307, image: rol8, name: "Ролета день-ніч DN 903", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 308, image: rol9, name: "Ролета день-ніч DN 904", price: '2212',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 309, image: rol10, name: "Ролета день-ніч DN 905", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 310, image: rol11, name: "Ролета день-ніч DN 906", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 311, image: rol12, name: "Ролета день-ніч DN 907", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 312, image: rol13, name: "Ролета день-ніч DN 908", price: '2212',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 313, image: rol14, name: "Ролета день-ніч DN 909", price: '2212' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 314, image: rol15, name: "Ролета день-ніч DN 1001", price: '2286',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 315, image: rol16, name: "Ролета день-ніч DN 1002", price: '2286',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 316, image: rol17, name: "Ролета день-ніч DN 1003", price: '2286' ,color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 317, image: rol18, name: "Ролета день-ніч DN 1004", price: '2286',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 318, image: rol19, name: "Ролета день-ніч DN 1005", price: '2286',color:DenNichRollColor.SVITLONEPRONYKNA},
    { id: 319, image: rol20, name: "Ролета день-ніч DN 601", price: '1990', color:DenNichRollColor.ODNOTONNA},
    { id: 320, image: rol21, name: "Ролета день-ніч DN 107", price: '1546',color:DenNichRollColor.ODNOTONNA},
    { id: 321, image: rol22, name: "Ролета день-ніч DN 201", price: '1620', color:DenNichRollColor.ODNOTONNA},
    { id: 322, image: rol23, name: "Ролета день-ніч DN 202", price: '1620',color:DenNichRollColor.SOLOMKA},
    { id: 323, image: rol24, name: "Ролета день-ніч DN 203", price: '1620' ,color:DenNichRollColor.SOLOMKA},
    { id: 324, image: rol25, name: "Ролета день-ніч DN 204", price: '1620' ,color:DenNichRollColor.SOLOMKA},
    { id: 325, image: rol26, name: "Ролета день-ніч DN 205", price: '1620',color:DenNichRollColor.SOLOMKA},
    { id: 326, image: rol27, name: "Ролета день-ніч DN 206", price: '1620' ,color:DenNichRollColor.SOLOMKA},
    { id: 327, image: rol28, name: "Ролета день-ніч DN 301", price: '1694', color:DenNichRollColor.ODNOTONNA},
    { id: 328, image: rol29, name: "Ролета день-ніч DN 302", price: '1694' , color:DenNichRollColor.ODNOTONNA},
    { id: 329, image: rol30, name: "Ролета день-ніч DN 303", price: '1694', color:DenNichRollColor.ODNOTONNA },
    { id: 330, image: rol31, name: "Ролета день-ніч DN 304", price: '1694', color:DenNichRollColor.ODNOTONNA},
    { id: 331, image: rol32, name: "Ролета день-ніч DN 305", price: '1694', color:DenNichRollColor.ODNOTONNA},
    { id: 332, image: rol33, name: "Ролета день-ніч DN 306", price: '1694' , color:DenNichRollColor.ODNOTONNA},
    { id: 333, image: rol34, name: "Ролета день-ніч DN 401", price: '1768',color:DenNichRollColor.ZBLUSKOM},
    { id: 334, image: rol35, name: "Ролета день-ніч DN 402", price: '1768',color:DenNichRollColor.ZBLUSKOM },
    { id: 335, image: rol36, name: "Ролета день-ніч DN 403", price: '1768',color:DenNichRollColor.ZBLUSKOM},
    { id: 336, image: rol37, name: "Ролета день-ніч DN 404", price: '1768',color:DenNichRollColor.ZBLUSKOM},
    { id: 337, image: rol38, name: "Ролета день-ніч DN 405", price: '1768',color:DenNichRollColor.SOLOMKA},
    { id: 338, image: rol39, name: "Ролета день-ніч DN 406", price: '1768',color:DenNichRollColor.SOLOMKA},
    { id: 339, image: rol40, name: "Ролета день-ніч DN 407", price: '1768',color:DenNichRollColor.SOLOMKA},
    { id: 340, image: rol41, name: "Ролета день-ніч DN 501", price: '1916', color:DenNichRollColor.ODNOTONNA},
    { id: 341, image: rol42, name: "Ролета день-ніч DN 502", price: '1916', color:DenNichRollColor.ODNOTONNA},
    { id: 342, image: rol43, name: "Ролета день-ніч DN 503", price: '1916', color:DenNichRollColor.ODNOTONNA},
    { id: 343, image: rol44, name: "Ролета день-ніч DN 505", price: '1916', color:DenNichRollColor.ODNOTONNA},
    { id: 344, image: rol45, name: "Ролета день-ніч DN 602", price: '1990', color:DenNichRollColor.ODNOTONNA},
    { id: 345, image: rol46, name: "Ролета день-ніч DN 603", price: '1990', color:DenNichRollColor.ODNOTONNA},
    { id: 346, image: rol47, name: "Ролета день-ніч DN 604", price: '1990', color:DenNichRollColor.ODNOTONNA},
    { id: 347, image: rol48, name: "Ролета день-ніч DN 605", price: '1990', color:DenNichRollColor.ODNOTONNA},
    { id: 348, image: rol49, name: "Ролета день-ніч DN 606", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 349, image: rol50, name: "Ролета день-ніч DN 607", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 350, image: rol51, name: "Ролета день-ніч DN 608", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 352, image: rol53, name: "Ролета день-ніч DN 610", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 353, image: rol54, name: "Ролета день-ніч DN 611", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 355, image: rol56, name: "Ролета день-ніч DN 612", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 356, image: rol57, name: "Ролета день-ніч DN 613", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 357, image: rol58, name: "Ролета день-ніч DN 614", price: '1990' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 358, image: rol59, name: "Ролета день-ніч DN 615", price: '1990' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 359, image: rol60, name: "Ролета день-ніч DN 616", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 361, image: rol61, name: "Ролета день-ніч DN 617", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 363, image: rol63, name: "Ролета день-ніч DN 618", price: '1990',color:DenNichRollColor.SOLOMKA},
    { id: 364, image: rol64, name: "Ролета день-ніч DN 619", price: '1990',color:DenNichRollColor.SOLOMKA},
    { id: 365, image: rol65, name: "Ролета день-ніч DN 504", price: '1916',color:DenNichRollColor.ODNOTONNA},
    { id: 366, image: rol66, name: "Ролета день-ніч DN 609", price: '1990',color:DenNichRollColor.VIZERYNKOVA},
    { id: 367, image: rol67, name: "Ролета день-ніч DN 111", price: '1546', color:DenNichRollColor.ODNOTONNA},
    { id: 368, image: rol68, name: "Ролета день-ніч DN 112", price: '1546', color:DenNichRollColor.ODNOTONNA},
    { id: 369, image: rol69, name: "Ролета день-ніч DN 113", price: '1546', color:DenNichRollColor.ODNOTONNA},
    { id: 370, image: rol70, name: "Ролета день-ніч DN 207", price: '1620', color:DenNichRollColor.ODNOTONNA},
    { id: 371, image: rol71, name: "Ролета день-ніч DN 208", price: '1620', color:DenNichRollColor.ODNOTONNA},
    { id: 373, image: rol73, name: "Ролета день-ніч DN 209", price: '1620', color:DenNichRollColor.ODNOTONNA},
    { id: 374, image: rol74, name: "Ролета день-ніч DN 210", price: '1620', color:DenNichRollColor.ODNOTONNA},
    { id: 375, image: rol75, name: "Ролета день-ніч DN 211", price: '1620' , color:DenNichRollColor.ODNOTONNA},
    { id: 376, image: rol76, name: "Ролета день-ніч DN 212", price: '1620' , color:DenNichRollColor.ODNOTONNA},
    { id: 377, image: rol77, name: "Ролета день-ніч DN 213", price: '1620' , color:DenNichRollColor.ODNOTONNA},
    { id: 378, image: rol78, name: "Ролета день-ніч DN 506", price: '1916', color:DenNichRollColor.VIZERYNKOVA },
    { id: 379, image: rol79, name: "Ролета день-ніч DN 507", price: '1916' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 380, image: rol80, name: "Ролета день-ніч DN 508", price: '1916' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 381, image: rol81, name: "Ролета день-ніч DN 509", price: '1916' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 382, image: rol82, name: "Ролета день-ніч DN 510", price: '1916' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 383, image: rol83, name: "Ролета день-ніч DN 704", price: '2064', color:DenNichRollColor.ODNOTONNA },
    { id: 384, image: rol84, name: "Ролета день-ніч DN 705", price: '2064', color:DenNichRollColor.ODNOTONNA },
    { id: 385, image: rol85, name: "Ролета день-ніч DN 706", price: '2064', color:DenNichRollColor.ODNOTONNA },
    { id: 386, image: rol86, name: "Ролета день-ніч DN 707", price: '2064' , color:DenNichRollColor.ODNOTONNA},
    { id: 387, image: rol87, name: "Ролета день-ніч DN 708", price: '2064' , color:DenNichRollColor.ODNOTONNA},
    { id: 388, image: rol88, name: "Ролета день-ніч DN 709", price: '2064' , color:DenNichRollColor.ODNOTONNA},
    { id: 389, image: rol89, name: "Ролета день-ніч DN 710", price: '2064' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 390, image: rol90, name: "Ролета день-ніч DN 711", price: '2064' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 391, image: rol91, name: "Ролета день-ніч DN 712", price: '2064' ,color:DenNichRollColor.VIZERYNKOVA},
    { id: 392, image: rol92, name: "Ролета день-ніч DN 101", price: '1546', color:DenNichRollColor.ODNOTONNA },
    { id: 393, image: rol93, name: "Ролета день-ніч DN 102", price: '1546' , color:DenNichRollColor.ODNOTONNA},
    { id: 394, image: rol94, name: "Ролета день-ніч DN 103", price: '1546', color:DenNichRollColor.ODNOTONNA },
    { id: 395, image: rol95, name: "Ролета день-ніч DN 104", price: '1546', color:DenNichRollColor.ODNOTONNA },
    { id: 396, image: rol96, name: "Ролета день-ніч DN 105", price: '1546', color:DenNichRollColor.ODNOTONNA },
    { id: 397, image: rol97, name: "Ролета день-ніч DN 106", price: '1546', color:DenNichRollColor.ODNOTONNA},
    { id: 398, image: rol98, name: "Ролета день-ніч DN 108", price: '1546' , color:DenNichRollColor.ODNOTONNA},
    { id: 399, image: rol99, name: "Ролета день-ніч DN 109", price: '1546' , color:DenNichRollColor.ODNOTONNA},
    { id: 400, image: rol100, name: "Ролета день-ніч DN 110", price: '1546' , color:DenNichRollColor.ODNOTONNA},
    { id: 401, image: rol101, name: "Ролета день-ніч DN 408", price: '1768' ,color:DenNichRollColor.SOLOMKA},
    { id: 402, image: rol102, name: "Ролета день-ніч DN 409", price: '1768' ,color:DenNichRollColor.SOLOMKA},
    { id: 403, image: rol103, name: "Ролета день-ніч DN 410", price: '1768' ,color:DenNichRollColor.SOLOMKA},
    { id: 404, image: rol104, name: "Ролета день-ніч DN 411", price: '1768' ,color:DenNichRollColor.SOLOMKA},
    { id: 405, image: rol105, name: "Ролета день-ніч DN 701", price: '2064',color:DenNichRollColor.ZBLUSKOM },
    { id: 406, image: rol106, name: "Ролета день-ніч DN 702", price: '2064',color:DenNichRollColor.ZBLUSKOM },
    { id: 407, image: rol107, name: "Ролета день-ніч DN 703", price: '2064',color:DenNichRollColor.ZBLUSKOM },
    { id: 408, image: rol108, name: "Ролета день-ніч DN 801", price: '2138' ,color:DenNichRollColor.SOLOMKA},
    { id: 409, image: rol109, name: "Ролета день-ніч DN 802", price: '2138' ,color:DenNichRollColor.SOLOMKA},
    { id: 410, image: rol110, name: "Ролета день-ніч DN 803", price: '2138' ,color:DenNichRollColor.SOLOMKA},
    { id: 411, image: rol111, name: "Ролета день-ніч DN 804", price: '2138',color:DenNichRollColor.SOLOMKA },
]