import montaz1 from "../../images/catalog/montaz/denBravenDeco.png";
import montaz2 from "../../images/catalog/montaz/denBravenAcryl.png";
import montaz3 from "../../images/catalog/montaz/lacrysil.png";
import montaz4 from "../../images/catalog/montaz/lacrysil-В.png";
import montaz5 from "../../images/catalog/montaz/tekaSil.png";
import montaz6 from "../../images/catalog/montaz/denBravenHybrisealFacade.jpeg";
import montaz7 from "../../images/catalog/montaz/lacrysilWaterBlock.png";
import montaz8 from "../../images/catalog/montaz/cosmofen.png";
import montaz9 from "../../images/catalog/montaz/cкаженаЛипучка.png";
import montaz10 from "../../images/catalog/montaz/denBravenHybrisealFacadeБілий.png";
import montaz11 from "../../images/catalog/montaz/анкернийTEKAFIKSPE.png";
import montaz12 from "../../images/catalog/montaz/cosmofen.png";
import montaz13 from "../../images/catalog/montaz/пістолет-для-герметику.png";
import montaz14 from "../../images/catalog/montaz/теплий-підвіконний-профіль-blaugelb.png";
import montaz15 from "../../images/catalog/montaz/пінаSomafixMegaPl.png";
import montaz16 from "../../images/catalog/montaz/пінаTekapurMEGAXXL.png";
import montaz17 from "../../images/catalog/montaz/пінаDenBravenFlex.png";
import montaz18 from "../../images/catalog/montaz/клей-піна-Tekapur.png";
import montaz19 from "../../images/catalog/montaz/пістолет-для-піни-Bostik.png";
import montaz20 from "../../images/catalog/montaz/внутрішня-стрічкаALENORInterna.png";
import montaz21 from "../../images/catalog/montaz/внутрішня-стрічкаALENOR-Internal-U.png";
import montaz22 from "../../images/catalog/montaz/зовнішня-стрічка-ALENOR-External-N.png";
import montaz23 from "../../images/catalog/montaz/зовнішня-стрічка-ALENOR-External-U-25м.png";
import montaz24 from "../../images/catalog/montaz/псус-3*15-(10 м.)-Чорний.png";

export const montazPrices = { min: 200, max: 3500 };

export const mantazProducts = [
    {
        id: 70001, image: montaz1, name: "Герметик Den Braven Deco ", param1: "Колір: білий", price: '3359', param2: "Об'єм: 15 кг.(відро)",
        details1: "гарна адгезія без застосування праймерів до більшості будівельних матеріалів",
        details2: "придатний для нанесення на злегка вологі основи",
        details3: "практично без запаху",
        details4: "після остаточного висихання можна фарбувати водорозчинними та синтетичними фарбами",
        details5: "не містить розчинників: не викликає корозії металів, нейтральний по відношенню до пінополістиролу",
        details6: "зберігає еластичність впродовж усього терміну використання",
        text: "Застосовувати при температурі навколишнього середовища та основи від +5°С до +40°С. Поверхні повинні бути сухими, чистими, знежиреними. Допускається нанесення на злегка вологі основи. Дуже пористі основи, такі як гіпс, газобетон, вапняк, заґрунтовати, використовуючи суміш Den Braven Deco Acrylic з водою у відношенні 1:2. Перед застосуванням завжди рекомендується перевірити адгезію. Згладжування швів здійснювати за допомогою зволоженого інструменту. Після остаточного висихання герметик може бути забарвлений водорозчинними та синтетичними фарбами. Водорозчинні фарби з високим вмістом води можуть утворювати тріщини – рекомендується проводити тест на сумісність з фарбою. Шов не повинен піддаватися тривалому впливу води. Не застосовувати герметик на поверхнях з поліетилену, поліпропілену, полікарбонату, ПММА, ПТФЕ, неопрену та бітуму."
    },
    {
        id: 70002, image: montaz2, name: "Герметик акриловий Den Braven Acryl-W ", param1: "Колір: білий", price: '179', param2: "Об'єм: 300мл",
        details1: "гарна адгезія без застосування праймерів до поверхонь більшості будівельних матеріалів: бетону, цегли, гіпсу, металу, деревини",
        details2: "придатний для нанесення на злегка вологі основи",
        details3: "практично без запаху",
        details4: "після остаточного висихання можна фарбувати водорозчинними та синтетичними фарбами",
        details5: "не містить розчинників: не викликає корозії металів, нейтральний по відношенню до пінополістиролу",
        details6: "зберігає еластичність впродовж усього терміну використання",
        text: "Застосовувати при температурі навколишнього середовища та основи від +5°С до +40°С. Поверхні повинні бути сухими, чистими, знежиреними. Допускається нанесення на злегка вологі основи. Дуже пористі основи, такі як гіпс, газобетон, вапняк, заґрунтовати, використовуючи суміш Den Braven Acryl-W з водою у відношенні 1:2. Перед застосуванням завжди рекомендується перевірити адгезію. Нанесення виконувати за допомогою монтажного пістолету з дотримуванням пропорцій шва. Згладжування швів здійснювати за допомогою зволоженого інструменту. Після остаточного висихання герметик може бути забарвлений водорозчинними та синтетичними фарбами. Водорозчинні фарби з високим вмістом води можуть утворювати тріщини – рекомендується проводити тест на сумісність з фарбою. При зовнішньому застосуванні слід захищати від опадів протягом мінімум 5 годин після нанесення. Шов не повинен піддаватися тривалому впливу води. Не використовувати герметик на поверхнях з поліетилену, поліпропілену, полікарбонату, ПММА, ПТФЕ, неопрену та бітуму."
    },
    {
        id: 70003, image: montaz3, name: "Герметик акриловий для монтажних швів Lacrysil A", param1: "Тип призначення: зовнішній", price: '295', param2: "Об'єм: 1.2 кг.",
        details1: "висока паропроникність",
        details2: "екологічно чистий",
        details3: "100% водостійкий",
        details4: "ідеальний для вертикальних швів",
        details5: "термостійкий від -40 ° C до + 100 ° C",
        details6: "висока еластичність 400%;",
        details7: "захист монтажної піни від УФ-випромінювання",
        text: "Призначений для довгострокової і надійної герметизації, ущільнення різних будівельних матеріалів, конструкцій зовні приміщень; для виконання зовнішнього шару монтажного шва віконних, балконних, вітражних конструкцій. Має високу адгезію до дерева, цегли, каменю, бетону, полімербетону, металу (із захисним покриттям), алюмінію, ПВХ, штукатурці; після затвердіння легко фарбується акриловими фарбами."
    },
    {
        id: 70004, image: montaz4, name: "Герметик акриловий для монтажних швів Lacrysil B", param1: "Тип призначення: внутрішній", price: '295', param2: "Об'єм: 1.2 кг.",
        details1: "висока пароізоляція",
        details2: "екологічно чистий",
        details3: "100% водостійкий",
        details4: "ідеальний для вертикальних швів",
        details5: "термостійкий від -40 ° C до + 100 ° C",
        details6: "висока еластичність 400%;",
        details7: "захист монтажної піни від УФ-випромінювання",
        text: "Призначений для виконання внутрішнього шару монтажного шва віконних, балконних, дверних, вітражних конструкцій; для надійної герметизації примикань дверних і віконних коробок під час монтажу та ремонту; ущільнення різних тріщин і отворів усередині приміщень; для герметизації стиків елементів стін. Має високу адгезію до дерева, цегли, каменю, бетону, полімербетону, пінобетону, металу (із захисним покриттям), алюмінію, ПВХ, штукатурці; після затвердіння легко фарбується акриловими фарбами."
    },
    {
        id: 70005, image: montaz5, name: "Герметик силіконовий TekaSil універсальний", param1: "Колір: прозорий", price: '211', param2: "Об'єм: 280 мл.",
        details1: "для герметизації силікатних матеріалів і сполук при невисоких механічних навантаженнях, для монтажу скла алюмінієві рами, а також для встановлення профільованого скла. Властивості: після затвердіння герметик залишається еластичним.",
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        text: "Основа - ацетатний силікон. Володіє відмінною адгезією до більшості матеріалів: алюмінію, кераміки, скла, глазурованих поверхонь, профільованого скла, клінкеру, фарфору і т. д. Після затвердіння набуває відмінні механічних властивостей. Стійкий до хімічного впливу речовин широкого спектра, а також до атмосферних умов, ультрафіолетового випромінювання і старіння, не стікає по вертикальних швах та надійно герметизує. \n Підготовка та рекомендації: поверхня матеріалу повинна бути твердою і цілісною (без сколів), чистою і сухою перед нанесенням продукту. Ми рекомендуємо попередньо перевірити адгезію герметика до матеріалу поверхні. Температурна стабільність: від -40С до + 150С. Температурна навколишнього середовища при використанні від +5 до + 40С."
    },
    {
        id: 70006, image: montaz6, name: "Гібридний клей герметик Den Braven Hybriseal Facade", param1: "Колір: сірий", price: '489', param2: "Об'єм: 600 мл.",
        details1: "добра адгезія без застосування праймерів до більшості навіть злегка вологих основ",
        details2: "низький модуль пружності",
        details3: "зберігає еластичність впродовж всього терміну служби",
        details4: "нейтральне затвердіння, практично без запаху",
        details5: "не мітить ізоціанатів, розчинників і силікону",
        details6: "не дає усадки та не утворює бульбашок",
        details7: "стійкий до ультрафіолету, високої вологості, плісняви та погодних умов",
        details8: "висока механічна міцність",
        text: "Den Braven Hybriseal Facade є універсальним герметиком. Може застосовуватися для еластичного ущільнення швів у пішохідних зонах, а також використовуватися для герметизації дилатаційних (розширювальних) швів, фасадних швів, стиків між архітектурними та конструкційними елементами, наприклад навколо відливів, віконних та дверних рам, сходинок, покрівельних елементів, виробів з бетону та іншого. Може застосовуватися як всередині так і зовні приміщень на більшості будівельних матеріалів, таких як бетон, цегляна кладка, штукатурка, пофарбована деревина, емальовані поверхні, метали та різні види пластику. На відміну від поліуретанових герметиків може застосовуватися в умовах підвищеної вологості без ризику утворювання бульбашок у швах."
    },
    {
        id: 70007, image: montaz7, name: "Мастика акрилова гідроізоляційна Lacrysil Water Block", param1: null, price: '373', param2: "Об'єм: 1.2 кг",
        details1: "добра адгезія без застосування праймерів до більшості навіть злегка вологих основ",
        details2: "низький модуль пружності",
        details3: "зберігає еластичність впродовж всього терміну служби",
        details4: "нейтральне затвердіння, практично без запаху",
        details5: "не мітить ізоціанатів, розчинників і силікону",
        details6: "не дає усадки та не утворює бульбашок",
        details7: "стійкий до ультрафіолету, високої вологості, плісняви та погодних умов",
        details8: "висока механічна міцність",
        text: "Den Braven Hybriseal Facade є універсальним герметиком. Може застосовуватися для еластичного ущільнення швів у пішохідних зонах, а також використовуватися для герметизації дилатаційних (розширювальних) швів, фасадних швів, стиків між архітектурними та конструкційними елементами, наприклад навколо відливів, віконних та дверних рам, сходинок, покрівельних елементів, виробів з бетону та іншого. Може застосовуватися як всередині так і зовні приміщень на більшості будівельних матеріалів, таких як бетон, цегляна кладка, штукатурка, пофарбована деревина, емальовані поверхні, метали та різні види пластику. На відміну від поліуретанових герметиків може застосовуватися в умовах підвищеної вологості без ризику утворювання бульбашок у швах."
    },
    {
        id: 70008, image: montaz8, name: "Очисник COSMOFEN 10", param1: null, price: '577', param2: null,
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: null
    },
    {
        id: 70009, image: montaz9, name: "Клей акриловий Скажена Липучка", param1: null, price: '179', param2: "Об'єм: 0.4 кг",
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: 'Клей "СКАЖЕНА ЛИПУЧКА" - це високоякісний однокомпонентний акриловий клей для настінних і підлогових покриттів з нульовим сповзанням. Продукт характеризується високою адгезією до всіх поверхонь. Після виcихання утворює водостійкий міцний клейовий шов. Витримує великі навантаження і деформації, забезпечує довговічність склеювання. Продукт є екологічно чистим і не містить розчинників. Виготовлений за європейськими стандартами якості. Універсальний монтажний клей на акриловій основі "СКАЖЕНА ЛИПУЧКА" ТМ Lacrysil є унікальним продуктом і застосовується для робіт з усіма "складними" основами.'
    },
    {
        id: 70010, image: montaz10, name: "Гібридний клей герметик Den Braven Hybriseal Facade", param1: "Колір: білий", price: '289', param2: "Об'єм: 600 мл",
        details1: "добра адгезія без застосування праймерів до більшості навіть злегка вологих основ",
        details2: "низький модуль пружності",
        details3: "зберігає еластичність впродовж всього терміну служби",
        details4: "нейтральне затвердіння, практично без запаху",
        details5: "не мітить ізоціанатів, розчинників і силікону",
        details6: "не дає усадки та не утворює бульбашок",
        details7: "стійкий до ультрафіолету, високої вологості, плісняви та погодних умов",
        details8: "висока механічна міцність",
        text: "Den Braven Hybriseal Facade є універсальним герметиком. Може застосовуватися для еластичного ущільнення швів у пішохідних зонах, а також використовуватися для герметизації дилатаційних (розширювальних) швів, фасадних швів, стиків між архітектурними та конструкційними елементами, наприклад навколо відливів, віконних та дверних рам, сходинок, покрівельних елементів, виробів з бетону та іншого. Може застосовуватися як всередині так і зовні приміщень на більшості будівельних матеріалів, таких як бетон, цегляна кладка, штукатурка, пофарбована деревина, емальовані поверхні, метали та різні види пластику. На відміну від поліуретанових герметиків може застосовуватися в умовах підвищеної вологості без ризику утворювання бульбашок у швах."
    },
    {
        id: 70011, image: montaz11, name: "Монтажний клей анкерний TEKAFIKS PE-1", param1: null, price: '427', param2: "Об'єм: 300 мл",
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: "Для кріплення анкерів, що піддаються навантаження, в камінь, бетон, легкий бетон і цегляну кладку. \n Tekafix Anchor PE-1 - Двокомпонентний клей на поліефірної основі для анкерного кріплення переважно при статичних навантаженнях. \n Підходить також для виконання клейових з'єднань дерев'яних і металевих конструкцій, кріплення кронштейнів, огорож, патрубків і труб. Простий в застосуванні: легко видавлюється і вводиться в отвори. Швидко схоплюється."
    },
    {
        id: 70012, image: montaz12, name: "Клей секундний Cosmofen", param1: null, price: '185', param2: "Об'єм: 20 гр",
        details1: "прекрасно підходить для склеювання різноманітних полімерних матеріалів",
        details2: "швидка фіксація",
        details3: "дуже висока міцність",
        details4: "хороша стійкість до зовнішніх атмосферних впливів",
        details5: "висока стійкість до впливу низьких і високих температур",
        details6: "дуже хороша ультрафіолетова стійкість",
        details7: "склеювання еластомерів EPDM",
        details8: "без вмісту розчиннику",
        text: " - гумові ущільнювачі у віконному виробництві (наприклад, в місцях зрізу під кутом, в місцях стику між собою, з жорстким ПВХ і алюмінієвими профілями) \n - обробка пластмас / еластомерів / гуми \n -виобництво іграшок \n -оптична промисловість \n -виготовлення прикрас \n -герметичне склеювання матеріалів ущільнювачів \n -каналізаційна техніка \n -будівництво із застосуванням металоконструкцій \n -автомобілебудування / літакобудування / суднобудування \n -шкіряна / взуттєва промисловість / ортопедія \n -рекламна галузь \n -медичне / стоматологічне обладнання \n -електротехнічна промисловість \n -виробництво побутових приладів"
    },
    {
        id: 70013, image: montaz13, name: "Пістолет для герметику", param1: null, price: '539', param2: "Об'єм: 600 мл",
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: null,
    },
    {
        id: 70014, image: montaz14, name: "Теплий підвіконний профіль blaugelb 45*30*1175", param1: null, price: '279', param2: null,
        details1: "спінений полістирол високої щільності",
        details2: "ефективна і економічна тепло - і гідроізоляція",
        details3: "нескінченне подовження завдяки з'єднання єднанню «ластівчин хвіст»",
        details4: "висока стійкість до деформації",
        details5: "вологостійкість, перешкоджає утворенню цвілі",
        details6: "надійне утеплення (відсутність конденсату на рамі)",
        details7: "перешкоджає утворенню містків холоду в стандартних пластикових профілях",
        details8: "швидкий і простий монтаж, легка вага",
        text: "Спеціально розроблений для прихованої установки під вікном і порогом для забезпечення оптимальної тепло- і гідроізоляції. Надійно перешкоджає утворенню містків холоду. Відрізняється міцністю і стійкістю, а також високою швидкістю і простотою установки. Інноваційний з’єднання «ластівчин хвіст» забезпечує геометричне замикання і можливість формування відрізків будь-якої довжини."
    },
    {
        id: 70015, image: montaz15, name: "Піна Somafix Mega Plus", param1: null, price: '379', param2: "Об'єм: 850 мл",
        details1: "твердне в контакті з вологою",
        details2: "час первинного застигання: 8-10 хв",
        details3: "час обробки: 30-45 хв",
        details4: "граничне розширення: 19 %",
        details5: "межа міцності на розрив: 3 N/мм2",
        details6: "густина: 17-20 кг/м3",
        details7: "термостійкість: -40/+100 °С",
        details8: "вихід піни: 65 л",
        details9: "пористість: 70-80 %",
        details10: "теплопровідність: 25-30 M W/m.K",
        details11: "температура застосування: 0/+35 °С",
        details12: "температура зберігання: 0/+30 °С",
        details13: "термін придатності: 18 міс",
        text: "Однокомпонентна професійна поліуретанова монтажна піна преміум класу, завдяки спеціальній формулі забезпечує рівномірний високий вихід."
    },
    {
        id: 70016, image: montaz16, name: "Піна Tekapur MEGA XXL", param1: null, price: '379', param2: "Об'єм: 850 мл",
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: null,
    },
    {
        id: 70017, image: montaz17, name: "Піна Den Braven Flex", param1: null, price: '539', param2: "Об'єм: 850 мл",
        details1: "еластична протягом всього терміну служби",
        details2: "випробувана при тиску 1050 Па",
        details3: "забезпечує найкращу звукоізоляцію та повітронепроникність",
        details4: "відповідає вимогам енергоефективного будівництва (концепції «пасивного» та «енергонейтрального» домобудівництва)",
        details5: "добра адгезія як до горизонтальних, так і до вертикальних основ – не стікає",
        details6: "підходить для застосування в холодному кліматі",
        details7: "клас вогнестійкості B3",
        details8: null,
        text: "Піна Den Braven Flex-Foam GG призначена для заповнення швів, зазорів і стиків. Пройшла випробування згідно з директивами NEN-EN 1026: 2000 на проникність повітря при тиску до 1050 Па, продемонструвавши в 25 разів меншу проникність повітря, ніж приписано стандартом. Завдяки унікальній формулі Den Braven Flex-Foam GG може застосовуватися при низьких температурах повітря до -5°С. Використовується для герметизації зазорів навколо віконних і дверних рам, перегородок, стиків стін зі стелею та підлогою, зазорів у місцях перетину трубами огороджувальних конструкцій. Піна має відмінну адгезію до бетону, каменю, цегляного мурування, штукатурки, деревини, металу та багатьох пластиків, таких як спінений та екструдований пінополістирол, твердий поліуретан і ПВХ."
    },
    {
        id: 70018, image: montaz18, name: "Клей піна Tekapur", param1: null, price: '399', param2: "Об'єм: 800 мл",
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: null,
    },
    {
        id: 70019, image: montaz19, name: "Пістолет для піни Bostik T-620 тефлон", param1: null, price: '1799', param2: null,
        details1: null,
        details2: null,
        details3: null,
        details4: null,
        details5: null,
        details6: null,
        details7: null,
        details8: null,
        text: null,
    },
    {
        id: 70020, image: montaz20, name: "Внутрішня стрічка ALENOR Internal N 100мм. 25м. (ОВ ТМ)", param1: null, price: '836', param2: null,
        details1: "внутрішня пароізоляція монтажних швів у вузлах примикання СПК (віконні, дверні блоки, вітражі тощо) до поверхні світлового прорізу.",
        details2: "висока адгезія до будь-яких будівельних поверхонь, завдяки водно-дисперсній акриловій емульсії, яка використовується в якості адгезиву;",
        details3: "стійка до старіння",
        details4: "завдяки щільному спанбонду основи, відмінно штукатуриться",
        details5: "тривалий термін експлуатації - до 20 років;",
        details6: "має різні способи монтажу;",
        details7: "екологічна, не містить небезпечних для життя людей і тварин речовин.",
        details8: null,
        text: "СТРІЧКА ВІКОННА ВНУТРІШНЯ ALENOR® ОВ ТМ - 100 мм (Z-подібна) \n Внутрішня пароізоляційна віконна стрічка на основі захисного фольгованого покриття, синтетичного нетканого матеріалу РР-мембранного типу і двох клейових смуг. Акрилова клейова смужка призначена для кріплення стрічки до світлопрозорої конструкції, бутилкаучукова смужка – до внутрішнього укосу стіни з попередньо підготовленою поверхнею.Стрічка ALENOR® ОВ ТМ забезпечує надійний захист монтажної піни від зволоження з боку приміщення і запобігає потраплянню конденсату у монтажний шов.",
    },
    {
        id: 70021, image: montaz21, name: "Внутрішня стрічка ALENOR Internal N 150мм. 25м. (ОВ ТМ)", param1: null, price: '1036', param2: null,
        details1: "П-подібна стрічка: 2 клейові смуги (акрилова та бутилкаучукова), розміщені на одному боці стрічки.",
        details2: "внутрішня пароізоляція монтажних швів у вузлах примикання СПК (віконні, дверні блоки, вітражі тощо) до поверхні світлового прорізу",
        details3: "висока адгезія до будь-яких будівельних поверхонь, завдяки водно-дисперсній акриловій емульсії, яка використовується в якості адгезиву",
        details4: "стійка до старіння",
        details5: "завдяки щільному спанбонду основи, відмінно штукатуриться",
        details6: "тривалий термін експлуатації - до 20 років",
        details7: "має різні способи монтажу",
        details8: "екологічна, не містить небезпечних для життя людей і тварин речовин",
        text: "СТРІЧКА ВІКОННА ВНУТРІШНЯ ALENOR® ОВ ТМ - 150 мм (П-подібна) \n Внутрішня пароізоляційна віконна стрічка на основі захисного фольгованого покриття, синтетичного нетканого матеріалу РР-мембранного типу і двох клейових смуг. Акрилова клейова смужка призначена для кріплення стрічки до світлопрозорої конструкції, бутилкаучукова смужка – до внутрішнього укосу стіни з попередньо підготовленою поверхнею. \nСтрічка ALENOR® ОВ ТМ забезпечує надійний захист монтажної піни від зволоження з боку приміщення і запобігає потраплянню конденсату у монтажний шов.",
    },
    {
        id: 70022, image: montaz22, name: "Зовнішня стрічка ALENOR External N 75мм. 25м. (ОН Т)", param1: null, price: '690', param2: null,
        details1: "Z-подібна стрічка: 2 клейові смуги (акрилова та бутилкаучукова), розміщені на різних боках стрічки.",
        details2: "захист монтажних швів у вузлах примикання від атмосферних опадів",
        details3: "захист монтажної піни з зовнішньої сторони будівель",
        details4: "міцна й еластична одночасно",
        details5: "має високу адгезію до будь-яких будівельних поверхонь",
        details6: "максимально стійка до старіння",
        details7: "забезпечує одностороннє (зсередини назовні) виведення вологи з монтажного шва",
        details8: "має просту структуру, але якісні складові: первинний бутилкаучук, фірмова рецептура акрилового клею і міцна неткана основа роблять стрічку надійною і стійкою до кліматичних змін",
        text: "СТРІЧКА ВІКОННА ЗОВНІШНЯ ALENOR® ОН Т - 80 мм (Z-подібна) \n Зовнішня гідроізоляційна, паропроникна віконна стрічка на основі синтетичного нетканого матеріалу РР-РЕ-РР-мембранного типу і двох клейових смужок (акрилової і бутиловою). Акрилова клейова смужка призначена для кріплення стрічки до СПК, бутилкаучукова смужка – до укосу стіни з попередньо підготовленою поверхнею. \n Стрічка призначена для захисту будівельних монтажних швів від атмосферних опадів, а також монтажної піни від зволоження із зовнішнього боку приміщення. Забезпечує одночасне виведення вологи з шва. \n Краще рішення для ринку вторинної та комерційної нерухомості, новобудов економ-, стандарт-класу, об'єктів промислового призначення, стандартних офісних приміщень.",
    },
    {
        id: 70023, image: montaz23, name: "Зовнішня стрічка ALENOR External U 150мм. 25м. (ОН Т)", param1: null, price: '896', param2: null,
        details1: "Z-подібна стрічка: 2 клейові смуги (акрилова та бутилкаучукова), розміщені на різних боках стрічки.",
        details2: "захист монтажних швів у вузлах примикання від атмосферних опадів",
        details3: "захист монтажної піни з зовнішньої сторони будівель",
        details4: "виведення назовні пароподібної вологи, що утворюється всередині монтажного шва",
        details5: "має високу адгезію до будь-яких будівельних поверхонь",
        details6: "максимально стійка до старіння",
        details7: "забезпечує одностороннє (зсередини назовні) виведення вологи з монтажного шва",
        details8: "має просту структуру, але якісні складові: первинний бутилкаучук, фірмова рецептура акрилового клею і міцна неткана основа роблять стрічку надійною і стійкою до кліматичних змін",
        text: "СТРІЧКА ВІКОННА ЗОВНІШНЯ ALENOR® ОН Т - 150 мм (Z-подібна) \n Зовнішня гідроізоляційна, паропроникна віконна стрічка на основі синтетичного нетканого матеріалу РР-РЕ-РР-мембранного типу і двох клейових смужок (акрилової і бутиловою). Акрилова клейова смужка призначена для кріплення стрічки до вікна, бутилкаучукова смужка – до укосу стіни з попередньо підготовленою поверхнею. \n Стрічка призначена для захисту будівельних монтажних швів від атмосферних опадів, а також монтажної піни від зволоження із зовнішнього боку приміщення. Забезпечує одночасне виведення вологи з шва. \n Краще рішення для ринку вторинної та комерційної нерухомості, новобудов економ-, стандарт-класу, об'єктів промислового призначення, стандартних офісних приміщень.",
    },
    {
        id: 70024, image: montaz24, name: "Псус 3*15 (10 м.)", param1: "Колір: чорний", price: '352', param2: null,
        details1: "пінополіуретан",
        details2: "дисперсія акрилату",
        details3: "клейовий шар",
        details4: "антиадгезійний матеріал",
        details5: "для герметизації і звукоізоляції технологічних стиків і швів, захисту вузлів примикання від вологи і корозії",
        details6: "при монтажі вікон, у тому числі в отвори з чвертю",
        details7: "для створення монтажних швів, стійких до злив",
        details8: "для термоізоляційного ущільнення місць між віконними коробами і стіною, профільних елементів стиковок, забутки, профільних перекрить віконних швів",
        text: "Попередньо стиснута самоклеюча стрічка, що просякнута спеціальним розчином, який надає їй підвищену пружність, еластичність і здатність до саморозширення в процесі експлуатації.",
    },
]