import kr1 from "../../../images/catalog/rolety/kriplennya/еліт.png"
import kr2 from "../../../images/catalog/rolety/kriplennya/еліт32.png"
import kr3 from "../../../images/catalog/rolety/kriplennya/преміум.png"
import kr4 from "../../../images/catalog/rolety/kriplennya/стандарт.png"
import kr5 from "../../../images/catalog/rolety/kriplennya/закрита_система.png"
import kr6 from "../../../images/catalog/rolety/kriplennya/на_фрамугу.png"

export const kriplennyaData = [
    {
      id: 10000,
      name: "Еліт з планкою",
      image: kr1
    },
    {
      id: 20000,
      name: "Еліт 32",
      image: kr2
    },
    {
      id: 30000,
      name: "Преміум",
      image: kr3
    },
    {
      id: 40000,
      name: "Стандарт 24",
      image: kr4
    },
    {
      id: 50000,
      name: "Закрита система",
      image: kr5
    },
    {
      id: 60000,
      name: "На фрамугу",
      image: kr6
    }
  ];