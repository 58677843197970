import styled from "styled-components";
import call from "../../images/secondary_actions/call.png";
import calculator from "../../images/secondary_actions/calculator (1).png"
import ruler from "../../images/secondary_actions/ruler.png"
import { useEffect, useState } from "react";
import { SecondaryModal } from "../../modal/modal";
import { Link } from 'react-router-dom';

export const SecondaryActions = () => {
    const [showComponent0, setShowComponent0] = useState(false);
    const [showComponent2, setShowComponent2] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    return (
        <div>
            <CategoryBlock>
                <Action onClick={() => setShowComponent0(true)}>
                    <Item image={call} name={'Замовити дзвінок'}></Item>
                </Action>
                <Action>
                <Link to="/calculator" style={{ textDecoration: 'none'}}>
                    <Item image={calculator} name={'Калькулятор вікон'} ></Item>
                    </Link>
                </Action>
                <ActionRuler onClick={() => setShowComponent2(true)}>
                    <Item image={ruler} name={'Зробити замір'}></Item>
                </ActionRuler>
            </CategoryBlock>
            <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
            <SecondaryModal show={showComponent2} onHide={() => setShowComponent2(false)} />
        </div>

    );
};


const Item = ({ image, name }) => {
    return <Block>
        <Image src={image} className="action_image"></Image>
        <Name>{name}</Name>
    </Block>
}
const CategoryBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 768px) {
    flex-direction: row;
    }
`

const Action = styled.div`
    display:flex;
    justify-content: center;
    width: 33.33%;
    height: 195px;
    border: 1px solid #5A5B5D;
    align-items: center;
    background-color: #e6e6e6;
    transition: all .3s ease;
    cursor: pointer;
    &:last-child{
        border-left: none;
    }
    &:first-child{
        border-right: none;
    }
    &:hover{
        background: #ffffff;
        .action_image{
            opacity: 100%
        }
    }
    @media (max-width: 768px) {
    width:100%;
    height: 100px;
    &:last-child{
        border-left: 1px solid #5A5B5D;
        border-top:none;
    }
    }
`
const ActionRuler = styled(Action)`
    @media (max-width: 768px) {
    display: none;
    }
`
const Image = styled.img`
    height: 75px;
    width: 75px;
    background-size: cover;
    background-position: center;
    opacity: 80%;
    @media (max-width: 768px) {
    width:40px;
    height: 40px;
    }
`
const Name = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color:#5A5B5D;
    width: 100px;
    margin-left: 30px;
    @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
    margin-left:0px;
    }
`
const Block = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`


