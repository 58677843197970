import styled from "styled-components"
import { Header } from "../../header/header"
import { SecondaryActions } from "../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../landing_page/callback_form/callback_form"
import { Footer } from "../../footer/footer"
import { Background, Banner, ImageBig, Name, ProductItem, Row, TextItem } from "../information_page"
import { AlumWindow } from "../information_page_data"
import { useState } from "react"
import { ArrowLeft, ArrowRight } from "../../shared/slider/text_slider/slider_text"
import Slider from "react-slick";


export const InformationAlumWIndowPage = ({ }) => {
    return (
        <div>
            <Header></Header>
                <Banner data={AlumWindow}></Banner>
                <SliderParagrah items={AlumWindow.data} />
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

export const SliderParagrah = ({ items }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideCount = items?.length || 0;

    const nextSlide = () => {
        setCurrentSlide(currentSlide + 1);
    }

    const prevSlide = () => {
        setCurrentSlide(currentSlide - 1);
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        nextArrow: <ArrowRight currentSlide={currentSlide} slideCount={slideCount} onClick={nextSlide} />,
        prevArrow: <ArrowLeft currentSlide={currentSlide} slideCount={slideCount} onClick={prevSlide} />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div>
            {items?.map((item) => (
                <ProductItemScroll key={item.id}>
                    {item.name && <Name>{item.name}</Name>}
                    <RowScroll orientation={item.orientation}>
                        <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                            <ScrollTextBlock>
                                <Slider {...settings} currentSlide={currentSlide}>
                                    {item.text.map((p) => (<TextBlock text={p} key={item.id} />))}
                                </Slider>
                            </ScrollTextBlock>
                        </div>
                        {item.imagebig && <ImageBig src={item.imagebig}></ImageBig>}
                    </RowScroll>
                </ProductItemScroll>
            ))}
        </div>
    );
};


const TextBlock = ({ text }) => {
    return (
        <TextBlockWidth>
            <div key={text.id}>
                {text.bold && <ScrollText style={{ fontWeight: "500" }}>{text.bold}</ScrollText>}
                {text.part1 && <ScrollText>{text.part1}</ScrollText>}
                <ScrollText>{text.part2}</ScrollText>
            </div>
        </TextBlockWidth>
    );
};


const ScrollTextBlock = styled.div`
    height:550px;
    width: 750px;
    @media (max-width: 768px) {
    width:380px;
    height: 550px;
    }
`
const TextBlockWidth = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    @media (max-width: 768px) {
        margin-top: 0px;
}
`
const ScrollText = styled(TextItem)`
    max-width: 100%;
    @media (max-width: 768px) {
    margin: 0px 10px;
    font-size: 16px;
    max-width: none;
    }
`
const ProductItemScroll = styled(ProductItem)`
    @media (max-width: 768px) {
    margin: 20px 0px;
    }
`
const RowScroll = styled(Row)`
    @media (max-width: 768px) {
    flex-direction: column-reverse;
    }
`