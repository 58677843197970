import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, CardItem, Image, Text, ItemName, settings, Margin60, ImageBlock, SliderBlock, CardBlockMargin, Margin16 } from "../../card"
import { WoodWindowsillCard } from "../../../landing_page/products/cards"
import { Item, SmallItem, TextRed } from "./windowsill"
import { WoodWindowsillProducts, brandParameters } from "../../../catalog/product_data/wood_windowsill"
import { SecondaryModal } from "../../../modal/modal"
import { Dropdown } from "react-bootstrap"
import { MyButton } from "../../../shared/button/button"


export const WoodWindowsillProductsCard = () => {
    const { id } = useParams()
    const product = WoodWindowsillProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = WoodWindowsillProducts.filter((p) => p.id !== parseInt(id as string));
    const navigate = useNavigate();
    const [showComponent0, setShowComponent0] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);
    const [selectedWidth, setSelectedWidth] = useState('');
    const handleWidthChange = (newWidth) => {
        setSelectedWidth(newWidth);
    };

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product} onWidthChange={handleWidthChange}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
                <CategoryBlock>
                    <BlockName>Cхожі моделі</BlockName>
                    <SliderBlock>
                        <ScrollBlock>
                            <Slider {...settings}>
                                {similarProducts.map((p) => (<WoodWindowsillCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></WoodWindowsillCard>))}
                            </Slider>
                        </ScrollBlock>
                    </SliderBlock>
                </CategoryBlock>
            </Background>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const CardBlock = ({ product, onWidthChange }) => {
    const currentBrandParameters = brandParameters[product.collection];
    const [selectedWidth, setSelectedWidth] = useState('150');
    const handleWidthChange = (newWidth) => {
        setSelectedWidth(newWidth);
        onWidthChange(newWidth);
    };
    const values = currentBrandParameters.values;
    const getPriceByWidth = (width) => {
        const selectedValue = values.find((item) => item.width === width);
        return selectedValue ? selectedValue.price : '';
    };

    useEffect(() => {
        handleWidthChange(selectedWidth);
    }, [selectedWidth]);
    return (
        <div>
            <CardBlockMargin>
                <ItemName>{product.name}</ItemName>
                <Margin16>
                    <Item style={{ width: '350px' }}>{product.param1}</Item>
                    <Item>{product.param2}</Item>
                    <Item>{product.param3}</Item>
                    <Item>Декор: який на фото</Item>
                    <Item>Капінос: {product.param4}</Item>
                    {product.param5 && <Item>Німецьке покриття: {product.param5}</Item>}
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                        <Text><TextRed>Важливо!</TextRed>Колір товару може відрізнятися від представленого на екрані.</Text>
                    </div>
                </Margin16>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <CardItem>
                        <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text1}</Text>
                        <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text2}</Text>
                        {currentBrandParameters.text3 && <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text3}</Text>}
                        {currentBrandParameters.text4 && <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text4}</Text>}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Item>Прайс:</Item>
                            <SmallItem>Ширина х м.п</SmallItem>
                            <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', alignItems: 'center' }}>
                                <Dropdown onSelect={handleWidthChange}>
                                    <Dropdown.Toggle variant="secondary" className="artden-toggle">
                                        {selectedWidth}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="artden-menu">
                                        {values.map((item) => (
                                            <Dropdown.Item key={item.width} eventKey={item.width}>
                                                {item.width}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {selectedWidth && (
                                    <SmallItem style={{ marginLeft: '10px' }}>{getPriceByWidth(selectedWidth)} грн.</SmallItem>
                                )}
                            </div>
                            {currentBrandParameters.kraikyvannia && (
                                <SmallItem>Крайкування: {currentBrandParameters.kraikyvannia} грн.</SmallItem>
                            )}
                        </div>
                    </CardItem>
                </div>
            </CardBlockMargin>
        </div>
    );
}



