import React, { useState } from 'react';
import { Slider, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import "./range_input.scss";

const RangeSlider = styled(Slider)({
  color: '#F12B04',
  '&.Mui-hover': {
    color: '#F12B04',
  },
});


export const HorizontalInput = () => {
  const [HorizontalValue, setHorizontalValue] = useState(1300);
  const HorizontalValueChange = (event, newValue) => {
    setHorizontalValue(newValue);
  };

  const HorizontalInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setHorizontalValue(newValue);
  };
  const ResponsiveLabel = styled('label')({
    marginTop: '40px',
    '@media (max-width: 768px)': {
      marginTop: '-10px',
  },
  });

  return (
    <div className='slider_wrapper_horizontal'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',width:'100%' }}>
        <RangeSlider
          onChange={HorizontalValueChange}
          value={HorizontalValue}
          max={3000}
          style={{ width:'100%' }}
        />
        <ResponsiveLabel>Ширина (мм):</ResponsiveLabel>
        <TextField
          id="i1"
          value={HorizontalValue}
          onChange={HorizontalInputChange}
          type="number"
          inputProps={{ min: 0, max: 3000 }}
          style={{ width: '80px' }}
          size="small"
        />
      </div>
    </div>
  );
};

export const VerticalInput = () => {
  const [VerticalValue, setVerticalValue] = useState(1000);

  const VerticalValueChange = (event, newValue) => {
    setVerticalValue(newValue);
  };

  const VerticalInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    setVerticalValue(newValue);
  };

  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const ResponsiveLabelVertical = styled('label')({
    marginTop: '0px',
    '@media (max-width: 768px)': {
      marginTop: '-5px',
      textAlign: 'center'
  },
  });
  return (
    <div className='slider_wrapper_vertical'>
      <Box sx={{ height: '450px',
      '@media (max-width: 768px)': {
      height: '250px',
    }, }}>
        <RangeSlider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: 'slider-vertical',
            },
          }}
          orientation="vertical"
          value={VerticalValue}
          onChange={VerticalValueChange}
          onKeyDown={preventHorizontalKeyboardNavigation}
          max={3000}
        />
      </Box>
      <ResponsiveLabelVertical>Висота (мм):</ResponsiveLabelVertical>
      <TextField
        id="i2"
        value={VerticalValue}
        onChange={VerticalInputChange}
        type="number"
        inputProps={{ min: 0, max: 3000 }}
        style={{ width: '80px'}}
        size="small"
      />
    </div>
  );
};


