import styled from "styled-components"
import metal from '../../images/1.png'
import alum from '../../images/4.jpg'
import door from '../../images/2.jpg'
import windowsill from '../../images/3.jpg'
import CategoryButton, { CategoryButtonMobile } from "./categorybutton"
import { BlockName } from "../../shared/blockName/blockName";
import { Link } from "react-router-dom"

export const ProductCategories = () => {
    return (
     <div id="our_products">   
        <CategoriesBlock>
            <BlockName>Наша продукція</BlockName>
            <Categories>
                <CategoryRight>
                    <Metal>
                        <CategoryButton right>
                            <Link to="/catalog/plastic_windows" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                Металопластикові вікна
                            </Link>
                        </CategoryButton>
                    </Metal>
                </CategoryRight>
                <CategoryCenter>
                    <Door>
                        <CategoryButton short>
                            <Link to="/catalog/entrance_doors" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                Двері
                            </Link>
                        </CategoryButton>
                    </Door>
                    <Windowsill>
                        <CategoryButton short>
                            <Link to="/catalog/windowsill" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                Підвіконня
                            </Link>
                        </CategoryButton>
                    </Windowsill>
                </CategoryCenter>
                <CategoryLeft>
                    <Alum>
                        <CategoryButton left>
                        <Link to="/details/alyuminiyevi_vikna" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                            Алюмінієви вироби
                        </Link>
                        </CategoryButton>
                    </Alum>
                </CategoryLeft>
            </Categories>
        </CategoriesBlock>
        </div>
    )   
}


export const CategoriesBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    background: #F5F3F4; 
    justify-content: center;
    flex-direction: column;
`
const Categories = styled.div`
    display:flex;
    justify-content: center;
    @media (max-width: 768px) {
    flex-wrap:wrap;
    justify-content: flex-start;
      padding-left: 10px;
    }
`
const CategoryRight = styled.div`
    height: 672px;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position:relative;
    @media (max-width: 768px) {
    height:250px;
    width: 49%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    display: block;
    }
`
const CategoryCenter = styled.div`
    position: relative;
    display: flex;
    height:672px;
    width: 33.33%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
    height:250px;
    width: 49%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    display: block;
    }
`

const CategoryLeft = styled(CategoryRight)`
`

const Metal = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 98%;
    background-image: url(${metal});
    opacity: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    border: 1px solid #5A5B5D;
    
`
const Alum = styled(Metal)`
    background-image: url(${alum});
    justify-content: flex-start;
`
const Door = styled.div`
    display: flex;
    height:330px;
    width: 98%;
    margin: auto;
    background-size: cover;
    background-position: center;
    border: 1px solid #5A5B5D;
    background-image: url(${door});
    justify-content: center;
    @media (max-width: 768px) {
    height:100%;
    width:98%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    background-position: inherit;
    }
`
const Windowsill = styled(Door)`
    background-image: url(${windowsill}); 
    margin: 9px 10px 0px 10px; 
    @media (max-width: 768px) {
    margin:auto;
    } 
`