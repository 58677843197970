import styled from "styled-components"
import logo from "../images/logoWhite.png"
import { IoCall, IoLocation, IoMail } from "react-icons/io5";
import { RiInstagramFill, RiTelegramFill, RiFacebookFill } from "react-icons/ri";
import { Map } from "./map"
import { Link } from "react-router-dom";

interface TestProps {
    linkRef?: any;
}

export const Footer = ({ linkRef }: TestProps) => {
    return (
        <Back>
            <Content>
                <Logo><img src={logo} alt="Logo" /></Logo>
                <MenuColums>
                    <Column>
                        <Menu>
                            <MenuItems><a href='/#about_us' style={{ textDecoration: 'none' }}><MenuItems>Про нас</MenuItems></a></MenuItems>
                            <MenuItems><a href='/#our_products' style={{ textDecoration: 'none' }}><MenuItems>Продукція</MenuItems></a></MenuItems>
                            <MenuItems><a href='/#our_projects' style={{ textDecoration: 'none' }}><MenuItems>Проекти</MenuItems></a></MenuItems>
                            <MenuItems>Контакти</MenuItems>
                        </Menu>
                    </Column>
                    <Menu>
                        <MenuItems><Link to="/catalog/entrance_doors" style={{ textDecoration: 'none' }}><MenuItems>Двері</MenuItems></Link></MenuItems>
                        <MenuItems><Link to="/catalog/windowsill" style={{ textDecoration: 'none' }}><MenuItems>Підвіконня</MenuItems></Link></MenuItems>
                        <MenuItems><Link to="/details/alyuminiyevi_vikna" style={{ textDecoration: 'none' }}><MenuItems>Алюмінієви вироби</MenuItems></Link></MenuItems>
                        <MenuItems><Link to="/catalog/plastic_windows" style={{ textDecoration: 'none' }}><MenuItems>Металопластикові вироби</MenuItems></Link></MenuItems>
                    </Menu>
                    <LastColumn>
                        <MenuContacts id="contacts" ref={linkRef}>
                            <ContacItems><ContactItemIcon><IoCall size={'25px'}></IoCall></ContactItemIcon>+38 (099) 333 03 65 <br/> +38 (098) 433 03 65</ContacItems>
                            <ContacItems><ContactItemIcon><IoMail size={'25px'}></IoMail></ContactItemIcon>artden.office@gmail.com</ContacItems>
                            <ContacItems><ContactItemIcon><IoLocation size={'45px'}></IoLocation></ContactItemIcon>вул. Вовчинецька, 139, Ivano-Frankivsk, Ukraine</ContacItems>
                        </MenuContacts>
                        <div>
                            <ContacItems style={{marginTop: '20px'}}><ContactItemIcon></ContactItemIcon><ContacItems>Приєднатися до нас :</ContacItems></ContacItems>
                            <div style={{display: 'flex', justifyContent: 'start'}}>
                                <ContactItemIcon></ContactItemIcon>
                                <Icons>
                                    <Icon><Link to="https://www.facebook.com/ArtDen.Vikna/" style={{lineHeight: '25px', display:"flex"}}><RiFacebookFill size={'25px'} color="white"></RiFacebookFill></Link></Icon>
                                    <Icon><Link to="https://www.instagram.com/artden.vikna/" style={{lineHeight: '25px', display:"flex"}}><RiInstagramFill size={'25px'} color="white"></RiInstagramFill></Link></Icon>
                                    <Icon><Link to="https://t.me/ArtDen_office" style={{lineHeight: '25px', display:"flex"}}><RiTelegramFill size={'25px'} color="white"></RiTelegramFill></Link></Icon>
                                </Icons>
                            </div>
                        </div>
                    </LastColumn>
                </MenuColums>
                <Map />
            </Content>
        </Back>
    )
}

const Content = styled.div`
   display : flex;
   width: 1440px;
   height: 340px;
   background-color: black;
   padding: 55px;
   box-sizing: border-box;
   @media (max-width: 768px) {
    width: 100%;
    padding:20px;
    height:100%;
    flex-direction: column;
    }
`
const Back = styled.div`
   display : flex;
   width: 100%;
   height: 330px;
   background-color: black;
   box-sizing: border-box;
   justify-content: center;
   @media (max-width: 768px) {
    height:100%;
    }
`
const Logo = styled.div`
    display: flex;
    width: 168px;
    height: 226px;
    @media (max-width: 768px) {
    display:none;
    }
`
const Menu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 60px;
    @media (max-width: 768px) {
    margin-right:-5px;
    }
`
const MenuContacts = styled(Menu)`
    width: 190px;
    margin-right: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
`
const MenuItems = styled.div`
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #F5F3F4;
    cursor: pointer;
    margin-bottom: 20px;
    :last-child{
        margin-bottom: 0px;
    }
    &:hover {
      color: #B8B8B8;
    }
`

const ContacItems = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #F5F3F4;
  cursor: pointer;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  :last-child {
    margin-bottom: 0px;
  }
`

const ContactItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
`

const LastColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`
const MenuColums = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
`
const Icons = styled.div`
    display: flex;
    width: 150px;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    &:hover {
      color: #B8B8B8;
    }
`
const Icon = styled.div`
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  &:hover {
    color: #B8B8B8;
  }
`

const Column = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`