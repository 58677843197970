import { Header } from "../../header/header"
import { SecondaryActions } from "../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../landing_page/callback_form/callback_form"
import { Footer } from "../../footer/footer"
import { Background, Banner } from "../information_page"
import { AlumDoors } from "../information_page_data"
import styled from "styled-components"
import { Link } from "react-router-dom"
import CategoryButton from "../../landing_page/categories/categorybutton"
import left from "../../images/categories/warm.jpeg"
import right from "../../images/categories/cold.jpeg"
import top from "../../images/categories/door.jpeg"
import down from "../../images/categories/bottom.webp"
import { SliderParagrah } from "./alumWindow"
import { BlockName } from "../../shared/blockName/blockName"



export const InformationAlumDoorPage = ({ }) => {
    return (
        <div>
            <Header></Header>
            <Banner data={AlumDoors}></Banner>
            <ProductCategories/>
            <div style={{marginBottom:'10px'}}></div>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const ProductCategories = () => {
    return (
        <div>
            <CategoriesBlock>
                <Categories>
                    <CategoryRight>
                        <Left>
                            <CategoryButton right>
                                <Link to="/details/alyuminiyevi_dvery/tepliy_aluminiy" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                    Теплий алюміній
                                </Link>
                            </CategoryButton>
                        </Left>
                    </CategoryRight>
                    <CategoryCenter>
                        <Top>
                            <CategoryButton short>
                                <Link to="/details/alyuminiyevi_dvery/rozsuvni_dveri" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                    Розсувні двері
                                </Link>
                            </CategoryButton>
                        </Top>
                        <Down>
                            <CategoryButton short>
                                <Link to="/details/alyuminiyevi_dvery/rozpashni_dveri" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                    Розпашні двері
                                </Link>
                            </CategoryButton>
                        </Down>
                    </CategoryCenter>
                    <CategoryRight>
                        <Right>
                            <CategoryButton left>
                                <Link to="/details/alyuminiyevi_dvery/khololniy_alyuminiy" style={{ textDecoration: 'none', color: 'rgb(90, 91, 93)' }}>
                                    Холодний алюміній
                                </Link>
                            </CategoryButton>
                        </Right>
                    </CategoryRight>
                </Categories>
            </CategoriesBlock>
        </div>
    )
}

export const AlumDoorCategories = ({data})=>{
    return(
        <div>
            <Header></Header>
            <BlockName style={{marginBottom:"0px"}}>{data.name}</BlockName>
            <SliderParagrah items={data.data}/>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

export const CategoriesBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover; 
    justify-content: center;
    flex-direction: column;
`
const Categories = styled.div`
    display:flex;
    justify-content: center;
    margin-top: 60px;
    @media (max-width: 768px) {
    flex-wrap:wrap;
    margin-top: 20px;
    justify-content: flex-start;
      padding-left: 10px;
    }
`
const CategoryRight = styled.div`
    height: 672px;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position:relative;
    @media (max-width: 768px) {
    height:250px;
    width: 49%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    display: block;
    }
`
const CategoryCenter = styled.div`
    position: relative;
    display: flex;
    height:672px;
    width: 33.33%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
    height:250px;
    width: 49%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    display: block;
    }
`


const Left = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 98%;
    background-image: url(${left});
    opacity: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    border: 1px solid #5A5B5D;   
`
const Right = styled(Left)`
    background-image: url(${right});
    justify-content: flex-start;
`
const Top = styled.div`
    display: flex;
    height:330px;
    width: 98%;
    margin: auto;
    background-size: cover;
    background-position: center;
    border: 1px solid #5A5B5D;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url(${top});
    justify-content: center;
    @media (max-width: 768px) {
    height:100%;
    width:98%;
    margin-bottom: 5px;
    -webkit-box-align: center;
    background-position: inherit;
    }
`
const Down = styled(Top)`
    background-image: url(${down}); 
    margin: 9px 10px 0px 10px; 
    @media (max-width: 768px) {
    margin:auto;
    } 
`