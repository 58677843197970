import styled from "styled-components"

export const Benefit = ({ text, image }) => {
    return <BenefitItem>
        <Img src={image}></Img>
        <TextItem>{text}</TextItem>
    </BenefitItem>
}

const BenefitItem = styled.div`
    display: flex;
    position: relative;
    width: 220px;
    height: 100%;
    background-size: cover;
    background-position: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-right: 50px;
    &:last-child {
      margin-right: 0px;
    }
    @media (max-width: 768px) {
    width:30%;
    margin-right:10px;
    height: 120px;
    }
`
const Img = styled.img`
    @media (max-width: 768px) {
        width:50%
    }
`
export const TextItem = styled.div`
    display: flex;
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #5A5B5D;
    justify-content: flex-end;
    align-items: flex-end;
    @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    margin-top:5px;
    }
`