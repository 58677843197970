import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

export const Filter = ({ filterChange, options }) => {
    const [selected, setSelected] = useState(options.reduce((obj, option) => {
        obj[option.value] = false;
        return obj;
    }, {}))

    const toggleCheckbox = (key, value) => {
        const newState = { ...selected, [key]: value }
        setSelected(newState)
        const filtered = Object.keys(newState).filter(key => newState[key] === true)
        if (filtered.length) {
            filterChange(filtered)
        } else {
            filterChange(options.map(o => o.value))
        }
    }
    return (
        <div>
            {options.map(o => (<TextFilterParameters key={o.value}>
                <FormControlLabel
                    control={<FilterCheckbox onChange={(e) => toggleCheckbox(o.value, e.target.checked)} checked={selected[o.value]} />}
                    label={`${o.label}`} />
            </TextFilterParameters>))}
        </div>)
}


const TextFilterParameters = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: rgb(90, 91, 93);
    margin-left: 10px;
    margin-top: -5px;
    margin-bottom: 0px;
`

const FilterCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#F12B04',
    '&.Mui-checked': {
        color: '#F12B04',
    },
    '& .MuiSvgIcon-root': {
        color: '#F12B04',
    },
}));