import {Link} from 'react-router-dom';
import './menu.scss';
import Accordion from 'react-bootstrap/Accordion';


export const DropProductsDesktop = ({categories}) => {
    return (
        <div className="dropdown__list" style={{right: '-790px'}}>
            <div style={{display: 'flex'}}>
                {categories.map((category, index) => (
                    <div key={index} style={{width: '200px', flexShrink: 0}}>
                        <div style={{height: '50px'}}>
                            <div className="dropdown__category">{category.category}</div>
                        </div>
                        {category.items.map((item, index) => (
                            <div className="dropdown__item" style={{whiteSpace: 'normal'}} key={index}>
                                <Link to={item.link} style={{textDecoration: 'none'}}>
                                    {item.label}
                                </Link>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const DropProductsMobile = ({categories}) => {
    return (
        <Accordion className='drop_accordion'>
            {categories.map((category, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{category.category}</Accordion.Header>
                    <Accordion.Body>
                        {category.items.map((item) => (
                            <div
                                key={item.label}
                                className="dropdown__item"
                                style={{whiteSpace: 'normal', fontSize: '20px'}}
                            ><Link to={item.link} style={{textDecoration: 'none', color: '#5A5B5D'}}>
                                {item.label}
                            </Link>
                            </div>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};