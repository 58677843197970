import Modal from 'react-bootstrap/Modal';
import './modal.scss';
import smile from "../images/smile.png";

export const ModalSuccess = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={false}
            style={{ width: '450px', height: '350px' ,top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
            className="modal-success"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                <div className='modal_text_success'>Ваш запит прийнято. Наш менеджер зв'яжеться з вами найблищим часом.</div>
            </Modal.Header>
            <img src={smile}></img>
        </Modal>
    );
}

//450px x 350px