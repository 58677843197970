import styled from "styled-components"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const IconLeftStyled = styled(FiChevronLeft)`
    height: 20px;
    width: 20px;
    @media (max-width: 768px) {
    height: 16px;
    width: 16px;
    }
  `;
const IconRightStyled = styled(FiChevronRight)`
    height: 20px;
    width: 20px;
  @media (max-width: 768px) {
    height: 16px;
    width: 16px;
  }
`;
export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <IconLeftStyled />,
    </button>
);

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <IconRightStyled />,
    </button>
);

export const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    slidesToShow: 4,
    nextArrow: <SlickArrowRight currentSlide={''} slideCount={''} />,
    prevArrow: <SlickArrowLeft currentSlide={''} slideCount={''} />,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                dots: false,
                slidesToShow: 1.65,
            },
        },
    ],
}

export const Background = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom:60px;
    @media (max-width: 768px){
        margin-bottom: 0px;
    }
`

export const CardItem = styled.div`
    display: flex;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    align-items: flex-start;
`
export const Image = styled.img`
    object-fit: contain;
    width: 35%;
    max-height:460px;
    object-position: center;
    @media (max-width:768px){
        width: 100%;
        max-height:300px;
    }
`
export const Text = styled.div`
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color:#5A5B5D;
    margin-bottom: 10px;
    white-space: pre-wrap;
    @media (max-width: 768px){
        font-size:14px;
        margin-bottom: 0px;
    }
`
export const TextItem = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color:#5A5B5D;
    padding-bottom: 5px;
    @media (max-width: 768px){
        padding-bottom: 0px;
        font-weight: 500;
    }
`
export const Item = styled.div`
    display:'flex';
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    color:#5A5B5D;
    @media (max-width: 768px){
        font-size:24px;
    }
`
export const ItemName = styled(Item)`
    font-size: 32px;
    @media (max-width: 768px){
        font-size:24px;
        flex-direction:column;
        align-items:center
    }
`
export const Color = styled.img`
    display: flex;
    width: 60px;
    height: 60px;
    border: 1px solid #B8B8B8;
    border-radius: 0px;
    @media (max-width: 768px){
        width: 50px;
        height: 50px;
    }
`
export const ColorItem = styled.div<{ active: boolean }>`
   display: flex;
   flex-direction:column;
   width:80px;
   align-items: center;
   margin-right: 10px;
   padding: 5px;
   border: ${({ active }) => active ? '1px solid #B8B8B8' : 'none'};
   box-shadow: ${({ active }) => active ? '0px 0px 5px rgba(0, 0, 0, 0.5)' : 'none'};
   @media (max-width: 768px){
        width: 70px;
        margin-right: 0px;
    }
  `
export const Margin60 = styled.div`
display: flex;
margin: 60px;
flex-direction: row;
align-items: flex-start;
@media (max-width:768px){
    flex-direction:column;
    margin: 10px;
}
`
export const CardBlockMargin = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start; 
margin-left: 60px; 
max-width: 1000px;
@media (max-width: 768px){
margin-left: 10px; 
max-width: 350px;
margin-top:10px;
}
`
export const ImageBlock = styled.div`
display: flex; 
justify-content: flex-start;
align-items: flex-start;
@media (max-width: 768px){
    flex-direction:column;
}
`
export const Margin16 = styled.div`
margin: 16px 0px;
@media (max-width:768px){
    margin: 5px 0px;
}
`
export const Margin10 = styled.div`
margin: 20px 0px;
@media (max-width:768px){
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items:center;
}
`
export const TypeKripleniy = styled.div`
    @media (max-width:768px){
display:flex;
align-items: center;
flex-direction:column;
}
`
export const SliderBlock = styled.div`
    display: flex; 
    justify-content: center;
    overflow: hidden ;
    margin-bottom: 60px;
    @media (max-width:768px){
        margin-bottom: 10px;
    }   
`