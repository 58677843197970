import styled from "styled-components";
import item1 from "../../images/benefits/item5.jpg"
import item2 from "../../images/benefits/item2.jpg"
import item3 from "../../images/benefits/item3.jpg"
import item4 from "../../images/benefits/item4.png"
import item5 from "../../images/benefits/item6.png"
import { Benefit } from "./benefit";
import { BlockName } from "../../shared/blockName/blockName";

export const OurBenefits=()=>{
    return(
        <Block>
            <BlockName>Чому ми?</BlockName>
            <Benefits> 
                <Benefit image={item1} text="З 2002 року на українському ринку"></Benefit>
                <Benefit image={item2} text="10 років гарантія  якості"></Benefit>
                <Benefit image={item3} text="Пряма взаємодія з виробником"></Benefit>
                <Benefit image={item4} text="Повний цикл послуг"></Benefit>
                <Benefit image={item5} text="Великий асортиментний ряд"></Benefit>
            </Benefits>
        </Block>

    )
}

const Block=styled.div`
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    flex-direction: column;
    @media (max-width: 768px) {
    margin-bottom:60px;
    }
`

const Benefits=styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  @media (max-width: 768px) {
    height: 160px;
    flex-wrap:wrap;
    }
`