import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { windowProducts, windowColors } from "../../../catalog/product_data/plastic_window_data"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { FiCheck } from "react-icons/fi";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { WindowCard } from "../../../landing_page/products/cards"
import { Background, CardBlockMargin, CardItem, Color, ColorItem, Image, ImageBlock, Item, Margin16, Margin60, Text, TextItem, settings } from "../../card"
import { SecondaryModal } from "../../../modal/modal"
import { MyButton } from "../../../shared/button/button"
import styled from "styled-components"

export const ProductPageWindow = () => {
    const { id } = useParams()
    const product = windowProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = windowProducts.filter(p => p.id !== parseInt(id as string));
    const [showComponent0, setShowComponent0] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);
    return (
        <div>
            <Header></Header>
            <Background>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
                <CategoryBlock>
                    <BlockName>Cхожі моделі</BlockName>
                    <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                        <ScrollBlock>
                            <Slider {...settings}>
                                {similarProducts.map((p) => (<WindowCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></WindowCard>))}
                            </Slider>
                        </ScrollBlock>
                    </div>
                </CategoryBlock>
            </Background>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CheckIcon = styled(FiCheck)`
  position: absolute;
  top: 25%;
  left: 28%;
  color: #FFFFFF;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
  }
`;
const CardBlock = ({ product }) => {
    const [selectedColor, setSelectedColor] = useState(windowColors[0].id);
    const ColorClick = (colorId) => {
        setSelectedColor(colorId);
    };

    return (
        <div>
            <CardBlockMargin>
                <Item>{product.name}</Item>
                <Item>Вартість: від {product.price} грн.</Item>
                <Item>Колір:</Item>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {windowColors.map(color => (
                        <ColorItem
                            key={color.id}
                            onClick={() => ColorClick(color.id)}
                            active={selectedColor === color.id}
                        >
                            <div style={{ position: 'relative' }}>
                                <Color src={color.image} alt={color.label} />
                                {selectedColor === color.id && <CheckIcon size = {"30px"}/>}
                            </div>
                            <div style={{ display: 'flex', padding: '-10px' }}>
                                <Text style={{ lineHeight: '16px', textAlign: 'center' }}>{color.label}</Text>
                            </div>
                        </ColorItem>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
                    <CardItem key={product.id}>
                        <Item>Опис:</Item>
                        <Margin16>
                            <TextItem> ● {product.param1}</TextItem>
                            <TextItem> ● {product.param2}</TextItem>
                            <TextItem>● {product.param3}</TextItem>
                        </Margin16>
                    </CardItem>
                    <Text>{product.text1}</Text>
                    <Text>{product.text2}</Text>
                    <Text>{product.about}</Text>
                </div>
            </CardBlockMargin>
        </div>
    );
}


