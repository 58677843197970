import GoogleMapReact from 'google-map-react';
import { MdLocationOn } from 'react-icons/md';
import styled from 'styled-components';


const LocationComponent = ({ lat, lng }) => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <MdLocationOn size={'25px'} color='red'></MdLocationOn>
        </div>
    )
};

export const Map = () => {
    const fixedCenter = { lat: 48.931873, lng: 24.727468 };
    const defaultZoom = 12;

    return (
        <MapPossition>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCEGbYtj2tL9oYCHLOofu6BjD_SxN-K5uc', language: 'uk', }}
                defaultCenter={fixedCenter}
                defaultZoom={defaultZoom}
            >
                <LocationComponent lat={48.931873} lng={24.727468} />
            </GoogleMapReact>
        </MapPossition>
    );
};


const MapPossition = styled.div`
    width: 450px;
    height: 226px;
    @media (max-width: 768px) {
    margin :20px 0px;
    width:100%;
    height: 200px ;
    }
`



