import styled from "styled-components"
import banner from '../../images/banner.jpg'
import  { Contactform } from "../../shared/contactform/contactform"

export const MainBanner = () => {
    return (
        <Banner>
            <BannerFilter></BannerFilter>
            <BannerTextWrapper>
                <span className="banner-text red"><BannerTextRed>АртДен-</BannerTextRed></span>
                <span className="banner-text"><BannerText>усе про вікна та двері</BannerText></span>
            </BannerTextWrapper>
            <Contactform></Contactform>
        </Banner>
    )
}


const Banner = styled.div`
    height: calc(100vh - 80px);
    background-image: url(${banner});
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BannerFilter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.2;
`
const BannerTextWrapper = styled.div`
    position: relative;
    @media (max-width: 768px) {
        margin-top: -120px;
        padding: 0 16px;
    }
`

const BannerText = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 68px;
    text-align: center;
    color: #2D2D2D;
    display:block;
    @media (max-width: 768px) {
        font-size: 48px;
        line-height: 48px;
    }
`

const BannerTextRed = styled(BannerText)`
    color: #F12B04;
`

const BannerTextMobile = styled(BannerText)`
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
`
