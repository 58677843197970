import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import styled from "styled-components";

const IconLeftStyled = styled(FiChevronLeft)`
    height: 20px;
    width: 20px;
    @media (max-width: 768px) {
    height: 16px;
    width: 16px;
    }
  `;
const IconRightStyled = styled(FiChevronRight)`
    height: 20px;
    width: 20px;
  @media (max-width: 768px) {
    height: 16px;
    width: 16px;
  }
`;
export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-prev slick-arrow" +
            (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <IconLeftStyled />,
    </button>
);

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <IconRightStyled />,
    </button>
);