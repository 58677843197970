import { Header } from "../../header/header"
import { SecondaryActions } from "../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../landing_page/callback_form/callback_form"
import { Footer } from "../../footer/footer"
import { Banner, ImageBig, } from "../information_page"
import { PanoramWindow, panoramWindowCharacteristic, panoramWindowTypes } from "../information_page_data"
import styled from "styled-components"
import { BlockName } from "../../shared/blockName/blockName"
import { SliderParagrah } from "./alumWindow"


export const InformationPanpramWindowPage = ({ }) => {
    return (
        <div>
            <Header></Header>
            <Banner data={PanoramWindow}></Banner>
            <SliderParagrah items={panoramWindowCharacteristic.data}></SliderParagrah>
            <Characteristics></Characteristics>
            <Blocks></Blocks>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const Characteristics = () => {
    return (
        <Characteristic>
            <BlockName>Характеристики та можливості панорамних вікон:</BlockName>
            <ItemsBlock>
                <RedRectangle>
                    <Items>
                        {PanoramWindow.data.map((item) => (
                            <ItemCategories key={item.id} name={item.name} param={item.param} />
                        ))}
                    </Items>
                </RedRectangle>
            </ItemsBlock>
        </Characteristic>
    )
}

const Blocks = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop:'60px', }}>
            {panoramWindowTypes.map(item => (
                <BlockItem key={item.id} item={item} />
            ))}
        </div>
    )
}

const BlockItem = ({ item }) => {
    const { image, characteristics } = item;
    return (
        <BlockItemWrapper>
            <div style={{display:"flex", flexDirection:'column', justifyContent:'center', padding:'30px', backgroundColor:"#F4F4F4"}}>
            {characteristics.map(p => (
                <div style={{margin:'10px 0px'}}>
                    <Name>{p.name}</Name>
                    <ItemText style={{margin:'0px'}}>{p.param}</ItemText>
                </div>
            ))}
            </div>
            <ImageBig src={image} />
        </BlockItemWrapper>
    )
}


const ItemCategories = ({ name, param }) => {
    return (
        <Back>
            <Name>{name}</Name>
            <ItemText>{param}</ItemText>
        </Back>
    )
}

const BlockItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 30px 0px;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin:0px;
        padding-bottom:20px;
        background-color: #f4f4f4;
    }
`
const Characteristic = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`
const Back = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    padding:30px;
    background-color: #F5F3F4;
`
const Items = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const ItemText = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color:#5A5B5D;
    font-style: normal;
    text-align: justify;
    margin-top: 10px;
    white-space: pre-line;
    @media (max-width: 768px) {
    margin: 10px 20px;
    font-size: 12px;
    }
`

const Name = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: rgb(90, 91, 93);
    @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
    }
`

const RedRectangle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 100%;
    height: 100px;
    background-color: #F12B04;
    z-index: 1;
`
const ItemsBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-around;
    @media (max-width: 768px) {
    height:200px;
    justify-content:normal;
    }
`