import styled from "styled-components"
import { Header } from "../header/header"
import { SecondaryActions } from "../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../landing_page/callback_form/callback_form"
import { Footer } from "../footer/footer"
import { BlockName } from "../shared/blockName/blockName"
import { MyButton } from "../shared/button/button"
import { SecondaryModal } from "../modal/modal"
import { useState } from "react"
import { Orientation } from "./information_page_data"

export const InformationPage = ({ data }) => {
    return (
        <div>
            <Header></Header>
            <Banner data={data}></Banner>
            <Paragraph items={data.data} />
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const Paragraph = ({ items }) => {
    return (
        <div>
            {items?.map((item) => {
                return <ProjectItem key={item.id}>
                    {item.name && <Name>{item.name}</Name>}
                    <ParagrahItems>
                        <div style={{ display: "flex", width: '100%' }}>
                            <Rectangle>
                                <Row orientation={item.orientation}>
                                    <ImageContainer>
                                        {item.image && <Image src={item.image} style={{}}></Image>}
                                    </ImageContainer>
                                    <TextItem style={{ alignItems: "center" }}>{item.text}</TextItem>
                                </Row>
                            </Rectangle>
                        </div>
                    </ParagrahItems>
                </ProjectItem>
            })}
        </div>
    );
};


export const Banner = ({ data }) => {
    const [showComponent0, setShowComponent0] = useState(false);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {data.title && <BlockName>{data.title}</BlockName>}
            <BannerWidth>
                <TextSection>
                    {data.description && <BannerText>{data.description}</BannerText>}
                    <MyButton name={' '} onClick={() => setShowComponent0(true)}></MyButton>
                </TextSection>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                {data.image && <BannerImage src={data.image}></BannerImage>}
            </BannerWidth>
        </div>
    )
}

export const BannerWidth = styled.div`
    display: flex;
    flex-direction: row;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: center;
            margin-right:0px;
        }  
`;
export const Row = styled.div<any>`
    display: flex;
    width: 100%;
    flex-direction: ${({ orientation }) => orientation === Orientation.left ? 'row' : 'row-reverse'};
    @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            margin: 0px;
        }  
`
const Rectangle = styled.div`
    background-color: #F4F4F4; 
    display: flex; 
    flex-direction: row;
    width: 100%;
    justify-content: space-between; 
    align-items: center;
    min-height: 100%;
    margin: 30px 0px;
    padding:10px;
    @media (max-width: 768px) {
        margin: 0px; 
     }  
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 30%;
    min-width: 400px;
    @media (max-width: 768px) {
        width: 50% ;
        position: inherit ; 
     }  
`

const TextSection = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;
    text-align: center;
`
export const Background = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-bottom:60px;
`
export const ProjectItem = styled.div`
    display: flex;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    align-items: center;
    margin: 120px 0px;
    @media (max-width: 768px) {
    margin:30px 0px 0px 0px;
    }
`
const ParagrahItems = styled(ProjectItem)`
        flex-direction: row;
        margin: 40px 0px;
        width:100%;
        @media (max-width: 768px) {
        flex-direction: column;
        margin: 0px;
    }
`

export const ProductItem = styled(ProjectItem)`
    margin: 60px 20px 0px 0px;
    box-sizing: border-box;
    @media (max-width: 768px) {
    margin:30px;
    }
`
export const Image = styled.img`
    width: 100%;
    object-position: center;
    object-fit: contain;
    position:absolute;
    max-height:400px;
    @media (max-width: 768px) {
    width: 100%;
    margin:0px;
    position: inherit;
    }
`
export const ImageBig = styled(Image)`
    width: 45%;
    position:inherit;
    @media (max-width: 768px) {
    width: 100%;
    margin:0px 0px 10px 0px;
    }
`
const BannerImage = styled(Image)`
    position:relative;
    width: 30%;
    margin-right: 60px;
    @media (max-width: 768px) {
    width: 90%;
    margin:0px;
    }
`

export const Name = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color:#5A5B5D;
    @media (max-width: 768px) {
    font-size:22px;
    text-align:center;
    }
`
export const TextItem = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color:#5A5B5D;
    font-style: normal;
    text-align: justify;
    margin: 0px 80px 0px;
    white-space: pre-line;
    max-width: 70%;
    @media (max-width: 768px) {
    margin: 10px 20px;
    font-size: 18px;
    max-width: none;
    }
`
export const Description = styled(TextItem)`
    margin:0px 120px;
    @media (max-width: 768px) {
    margin: 10px 20px;
    }
`
export const BannerText = styled(TextItem)`
    margin:0px 70px 30px;
    @media (max-width: 768px) {
    margin: 10px 20px;
    }
`
