import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { defaultWindowsillBrands, WindowsillProducts, defaultWindowsillType, defaultWindowsillCover, defaultWindowsillColor } from "../../product_data/windowsill";
import { WindowsillCard } from "../../../landing_page/products/cards";
import { useState } from "react";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { ArtDenDrawer } from "../../drower";


export const Windowsill = () => {
    const [brands, setBrands] = useState(defaultWindowsillBrands.map(b => b.value))
    const [type, setType] = useState(defaultWindowsillType.map(t => t.value))
    const [cover, setCover] = useState(defaultWindowsillCover.map(c => c.value))
    const [color, setColor] = useState(defaultWindowsillColor.map(d => d.value))

    const filteredProducts = WindowsillProducts.filter((product) => {
        return WindowsillProducts
    }).filter(p => brands.includes(p.brand)).filter(p => type.includes(p.type)).filter(p => color.includes(p.color)).filter(p => cover.includes(p.cover));

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Підвіконня</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                <TextFilter style={{}}>Виробник</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(brands) => setBrands(brands)} options={defaultWindowsillBrands}></Filter>
                                </FilterBlock>
                                <TextFilter style={{}}>Покриття</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(cover) => setCover(cover)} options={defaultWindowsillCover}></Filter>
                                </FilterBlock>
                                <TextFilter style={{}}>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultWindowsillColor}></Filter>
                                </FilterBlock>

                                <TextFilter style={{}}>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultWindowsillType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{}}>Виробник</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(brands) => setBrands(brands)} options={defaultWindowsillBrands}></Filter>
                                </FilterBlock>
                                <TextFilter style={{}}>Покриття</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(cover) => setCover(cover)} options={defaultWindowsillCover}></Filter>
                                </FilterBlock>
                                <TextFilter style={{}}>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultWindowsillColor}></Filter>
                                </FilterBlock>

                                <TextFilter style={{}}>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultWindowsillType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}

                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <WindowsillCard
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                param2={p.param2}
                                                param3={p.param3}
                                                price={p.price}
                                            ></WindowsillCard>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};