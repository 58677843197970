import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 68px;
    background-color: ${(props) => props.color};
    justify-content: space-between;
`
