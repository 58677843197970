import rol1 from "../../images/catalog/rolety/tkanevy_rolety/с8.webp"
import rol2 from "../../images/catalog/rolety/tkanevy_rolety/с9.webp"
import rol3 from "../../images/catalog/rolety/tkanevy_rolety/с10.webp"
import rol4 from "../../images/catalog/rolety/tkanevy_rolety/с11.webp"
import rol5 from "../../images/catalog/rolety/tkanevy_rolety/с12.webp"
import rol6 from "../../images/catalog/rolety/tkanevy_rolety/с13.webp"
import rol7 from "../../images/catalog/rolety/tkanevy_rolety/d1.webp"
import rol8 from "../../images/catalog/rolety/tkanevy_rolety/d2.webp"
import rol9 from "../../images/catalog/rolety/tkanevy_rolety/d3.webp"
import rol10 from "../../images/catalog/rolety/tkanevy_rolety/d4.webp"
import rol11 from "../../images/catalog/rolety/tkanevy_rolety/d5.webp"
import rol12 from "../../images/catalog/rolety/tkanevy_rolety/e1.webp"
import rol13 from "../../images/catalog/rolety/tkanevy_rolety/e2.webp"
import rol14 from "../../images/catalog/rolety/tkanevy_rolety/e3.webp"
import rol15 from "../../images/catalog/rolety/tkanevy_rolety/e4.webp"
import rol16 from "../../images/catalog/rolety/tkanevy_rolety/e5.webp"
import rol17 from "../../images/catalog/rolety/tkanevy_rolety/f1.webp"
import rol18 from "../../images/catalog/rolety/tkanevy_rolety/f2.webp"
import rol19 from "../../images/catalog/rolety/tkanevy_rolety/g1.webp"
import rol20 from "../../images/catalog/rolety/tkanevy_rolety/g2.webp"
import rol21 from "../../images/catalog/rolety/tkanevy_rolety/h1.webp"
import rol22 from "../../images/catalog/rolety/tkanevy_rolety/h2.webp"
import rol23 from "../../images/catalog/rolety/tkanevy_rolety/h3.webp"
import rol24 from "../../images/catalog/rolety/tkanevy_rolety/i1.webp"
import rol25 from "../../images/catalog/rolety/tkanevy_rolety/i2.webp"
import rol26 from "../../images/catalog/rolety/tkanevy_rolety/i3.webp"
import rol27 from "../../images/catalog/rolety/tkanevy_rolety/i4.webp"
import rol28 from "../../images/catalog/rolety/tkanevy_rolety/i5.webp"
import rol29 from "../../images/catalog/rolety/tkanevy_rolety/а6.jpeg"
import rol30 from "../../images/catalog/rolety/tkanevy_rolety/а10.jpeg"
import rol31 from "../../images/catalog/rolety/tkanevy_rolety/a12.webp"
import rol32 from "../../images/catalog/rolety/tkanevy_rolety/а13.webp"
import rol33 from "../../images/catalog/rolety/tkanevy_rolety/d5.webp"
import rol34 from "../../images/catalog/rolety/tkanevy_rolety/b9.webp"
import rol35 from "../../images/catalog/rolety/tkanevy_rolety/d6.jpeg"
import rol36 from "../../images/catalog/rolety/tkanevy_rolety/d7.jpeg"
import rol37 from "../../images/catalog/rolety/tkanevy_rolety/Bl1.jpeg"
import rol38 from "../../images/catalog/rolety/tkanevy_rolety/bl2.jpeg"
import rol39 from "../../images/catalog/rolety/tkanevy_rolety/bl3.jpeg"
import rol40 from "../../images/catalog/rolety/tkanevy_rolety/a1.webp"
import rol41 from "../../images/catalog/rolety/tkanevy_rolety/a2.webp"
import rol42 from "../../images/catalog/rolety/tkanevy_rolety/a3.webp"
import rol43 from "../../images/catalog/rolety/tkanevy_rolety/f3.webp"
import rol44 from "../../images/catalog/rolety/tkanevy_rolety/a4.webp"
import rol45 from "../../images/catalog/rolety/tkanevy_rolety/a5.webp"
import rol46 from "../../images/catalog/rolety/tkanevy_rolety/a7.webp"
import rol47 from "../../images/catalog/rolety/tkanevy_rolety/a8.webp"
import rol48 from "../../images/catalog/rolety/tkanevy_rolety/a9.webp"
import rol49 from "../../images/catalog/rolety/tkanevy_rolety/a11.webp"
import rol50 from "../../images/catalog/rolety/tkanevy_rolety/a14.webp"
import rol51 from "../../images/catalog/rolety/tkanevy_rolety/b1.webp"
import rol53 from "../../images/catalog/rolety/tkanevy_rolety/b3.webp"
import rol54 from "../../images/catalog/rolety/tkanevy_rolety/b5.webp"
import rol56 from "../../images/catalog/rolety/tkanevy_rolety/b7.webp"
import rol57 from "../../images/catalog/rolety/tkanevy_rolety/b8.webp"
import rol58 from "../../images/catalog/rolety/tkanevy_rolety/b10.webp"
import rol59 from "../../images/catalog/rolety/tkanevy_rolety/b11.webp"
import rol60 from "../../images/catalog/rolety/tkanevy_rolety/b12.webp"
import rol61 from "../../images/catalog/rolety/tkanevy_rolety/b13.webp"
import rol63 from "../../images/catalog/rolety/tkanevy_rolety/b14.webp"
import rol64 from "../../images/catalog/rolety/tkanevy_rolety/b15.webp"
import rol65 from "../../images/catalog/rolety/tkanevy_rolety/b16.webp"
import rol66 from "../../images/catalog/rolety/tkanevy_rolety/b17.webp"
import rol67 from "../../images/catalog/rolety/tkanevy_rolety/b18.webp"
import rol68 from "../../images/catalog/rolety/tkanevy_rolety/b19.webp"
import rol69 from "../../images/catalog/rolety/tkanevy_rolety/b20.webp"
import rol70 from "../../images/catalog/rolety/tkanevy_rolety/b21.webp"
import rol71 from "../../images/catalog/rolety/tkanevy_rolety/c1.webp"
import rol73 from "../../images/catalog/rolety/tkanevy_rolety/c3.webp"
import rol74 from "../../images/catalog/rolety/tkanevy_rolety/c4.webp"
import rol75 from "../../images/catalog/rolety/tkanevy_rolety/c6.webp"
import rol76 from "../../images/catalog/rolety/tkanevy_rolety/c7.webp"


// Колір
export enum TkanevRollColor {
    ODNOTONNA = 'odnotonna',
    VIZERYNKOVA = 'vizerunkova',
    MISHKOVYNA = 'mishkovina',
    SOLOMKA = 'solomka',
    SVITLONEPRONYKNA = 'svitlonepronykna',
    PERLAMUTR = 'perlamutr',
}
export const defaultTkanevRollColor = [
    { value: TkanevRollColor.ODNOTONNA, label: 'Однотонна' },
    { value: TkanevRollColor.VIZERYNKOVA, label: 'Візерункова' },
    { value: TkanevRollColor.MISHKOVYNA, label: 'Мішковина' },
    { value: TkanevRollColor.SOLOMKA, label: 'Соломка' },
    { value: TkanevRollColor.SVITLONEPRONYKNA, label: 'Світлонепроникна' },
    { value: TkanevRollColor.PERLAMUTR, label: 'Перламутр' },
]
export const tkanevRollPrices = { min: 500, max: 2000 };

export const tkanevRollProducts = [
    
    { id: 200, image: rol1, name: "Ролета тканева C8", price: '1028', color: TkanevRollColor.VIZERYNKOVA},
    { id: 201, image: rol2, name: "Ролета тканева C9", price: '1028', color: TkanevRollColor.VIZERYNKOVA},
    { id: 202, image: rol3, name: "Ролета тканева C10", price: '1028' , color: TkanevRollColor.MISHKOVYNA},
    { id: 203, image: rol4, name: "Ролета тканева C11", price: '1028' , color: TkanevRollColor.MISHKOVYNA},
    { id: 204, image: rol5, name: "Ролета тканева C12", price: '1028' , color: TkanevRollColor.MISHKOVYNA},
    { id: 205, image: rol6, name: "Ролета тканева C13", price: '1028' , color: TkanevRollColor.MISHKOVYNA},
    { id: 206, image: rol7, name: "Ролета тканева D1", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 207, image: rol8, name: "Ролета тканева D2", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 208, image: rol9, name: "Ролета тканева D3", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 209, image: rol10, name: "Ролета тканева D4", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 210, image: rol11, name: "Ролета тканева D5", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 211, image: rol12, name: "Ролета тканева E1", price: '1324' , color: TkanevRollColor.PERLAMUTR},
    { id: 212, image: rol13, name: "Ролета тканева E2", price: '1324', color: TkanevRollColor.PERLAMUTR },
    { id: 213, image: rol14, name: "Ролета тканева E3", price: '1324' , color: TkanevRollColor.PERLAMUTR},
    { id: 214, image: rol15, name: "Ролета тканева E4", price: '1324', color: TkanevRollColor.VIZERYNKOVA },
    { id: 215, image: rol16, name: "Ролета тканева E5", price: '1324', color: TkanevRollColor.VIZERYNKOVA },
    { id: 216, image: rol17, name: "Ролета тканева F1", price: '1398', color: TkanevRollColor.ODNOTONNA },
    { id: 217, image: rol18, name: "Ролета тканева F2", price: '1398', color: TkanevRollColor.ODNOTONNA },
    { id: 218, image: rol19, name: "Ролета тканева G1", price: '1472', color: TkanevRollColor.VIZERYNKOVA },
    { id: 219, image: rol20, name: "Ролета тканева G2", price: '1472' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 220, image: rol21, name: "Ролета тканева H1", price: '1546', color: TkanevRollColor.VIZERYNKOVA },
    { id: 221, image: rol22, name: "Ролета тканева H2", price: '1546', color: TkanevRollColor.VIZERYNKOVA },
    { id: 222, image: rol23, name: "Ролета тканева H3", price: '1546' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 223, image: rol24, name: "Ролета тканева L1", price: '1620', color: TkanevRollColor.PERLAMUTR },
    { id: 224, image: rol25, name: "Ролета тканева L2", price: '1620' , color: TkanevRollColor.PERLAMUTR},
    { id: 225, image: rol26, name: "Ролета тканева L3", price: '1620', color: TkanevRollColor.VIZERYNKOVA },
    { id: 226, image: rol27, name: "Ролета тканева L4", price: '1620', color: TkanevRollColor.VIZERYNKOVA },
    { id: 227, image: rol28, name: "Ролета тканева L5", price: '1620', color: TkanevRollColor.VIZERYNKOVA },
    { id: 228, image: rol29, name: "Ролета тканева A6", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 229, image: rol30, name: "Ролета тканева A10", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 230, image: rol31, name: "Ролета тканева A12", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 231, image: rol32, name: "Ролета тканева A13", price: '880' , color: TkanevRollColor.ODNOTONNA },
    { id: 232, image: rol33, name: "Ролета тканева D5", price: '1176', color: TkanevRollColor.PERLAMUTR },
    { id: 233, image: rol34, name: "Ролета тканева B9", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 234, image: rol35, name: "Ролета тканева D6", price: '1176', color: TkanevRollColor.PERLAMUTR },
    { id: 235, image: rol36, name: "Ролета тканева D7", price: '1176' , color: TkanevRollColor.PERLAMUTR},
    { id: 236, image: rol37, name: "Ролета тканева BL1", price: '1250', color: TkanevRollColor.SVITLONEPRONYKNA },
    { id: 237, image: rol38, name: "Ролета тканева BL2", price: '1250' , color: TkanevRollColor.SVITLONEPRONYKNA},
    { id: 238, image: rol39, name: "Ролета тканева BL3", price: '1250' , color: TkanevRollColor.SVITLONEPRONYKNA},
    { id: 239, image: rol40, name: "Ролета тканева A1", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 240, image: rol41, name: "Ролета тканева A2", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 241, image: rol42, name: "Ролета тканева A3", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 242, image: rol43, name: "Ролета тканева F3", price: '1398', color: TkanevRollColor.ODNOTONNA },
    { id: 243, image: rol44, name: "Ролета тканева A4", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 244, image: rol45, name: "Ролета тканева A5", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 245, image: rol46, name: "Ролета тканева A7", price: '880', color: TkanevRollColor.ODNOTONNA },
    { id: 246, image: rol47, name: "Ролета тканева A8", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 247, image: rol48, name: "Ролета тканева A9", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 248, image: rol49, name: "Ролета тканева A11", price: '880' , color: TkanevRollColor.ODNOTONNA},
    { id: 249, image: rol50, name: "Ролета тканева A14", price: '880', color: TkanevRollColor.ODNOTONNA },
    { id: 250, image: rol51, name: "Ролета тканева B1", price: '954' , color: TkanevRollColor.MISHKOVYNA},
    { id: 252, image: rol53, name: "Ролета тканева B3", price: '954' , color: TkanevRollColor.MISHKOVYNA},
    { id: 253, image: rol54, name: "Ролета тканева B5", price: '954' , color: TkanevRollColor.MISHKOVYNA},
    { id: 255, image: rol56, name: "Ролета тканева B7", price: '954', color: TkanevRollColor.MISHKOVYNA },
    { id: 256, image: rol57, name: "Ролета тканева B8", price: '954' , color: TkanevRollColor.MISHKOVYNA},
    { id: 257, image: rol58, name: "Ролета тканева B10", price: '954', color: TkanevRollColor.VIZERYNKOVA },
    { id: 258, image: rol59, name: "Ролета тканева B11", price: '954', color: TkanevRollColor.VIZERYNKOVA },
    { id: 259, image: rol60, name: "Ролета тканева B12", price: '954', color: TkanevRollColor.VIZERYNKOVA },
    { id: 261, image: rol61, name: "Ролета тканева B13", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 263, image: rol63, name: "Ролета тканева B14", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 264, image: rol64, name: "Ролета тканева B15", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 265, image: rol65, name: "Ролета тканева B16", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 266, image: rol66, name: "Ролета тканева B17", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 267, image: rol67, name: "Ролета тканева B18", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 268, image: rol68, name: "Ролета тканева B19", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 269, image: rol69, name: "Ролета тканева B20", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 270, image: rol70, name: "Ролета тканева B21", price: '954' , color: TkanevRollColor.VIZERYNKOVA},
    { id: 271, image: rol71, name: "Ролета тканева C1", price: '1028', color: TkanevRollColor.SOLOMKA },
    { id: 273, image: rol73, name: "Ролета тканева C3", price: '1028', color: TkanevRollColor.SOLOMKA },
    { id: 274, image: rol74, name: "Ролета тканева C4", price: '1028', color: TkanevRollColor.SOLOMKA },
    { id: 275, image: rol75, name: "Ролета тканева C6", price: '1028', color: TkanevRollColor.VIZERYNKOVA },
    { id: 276, image: rol76, name: "Ролета тканева C7", price: '1028', color: TkanevRollColor.VIZERYNKOVA },
]