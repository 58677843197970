import styled from "styled-components";
import sadsmile from "../images/unhappysmile.png";


export const EmptyBlock = () => {
    return (
        <EmptyBlockSection>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', border: '1px solid #F12B04', padding: '10px' }}>
                <TextFilter>За вашими фільтрами немає доступних товарів. Спробуйте змінити параметри фільтрації.</TextFilter>
                <img src={sadsmile} style={{ width: '150px' }}></img>
            </div>
        </EmptyBlockSection>
    )
}

export const TextFilter = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: rgb(90, 91, 93);
    margin-top:30px;
    text-align: center;
    &:first-child{
        margin-top:0px;
    }
    @media (max-width: 768px){
        font-size:20px;
    }
`

export const Background = styled.div`
    display: flex;
    justify-content: center;
`;

export const CardsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: 20px;
`;

export const CardWrapper = styled.div`
    width: calc(33.33% - 20px);
    margin: 0px 5px 10px 5px;
    @media (max-width: 768px) {
    margin: 0px 0px 5px 5px;
    width: calc(50% - 10px);
    }
`;

export const FilterBlock = styled.div`
    border: 1px solid #CACACA;
    width: 240px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 5px;
    @media(max-width: 768px) {
        width: 220px;
    }

`
export const SameProductBlock = styled.div`
    flex: '1';
    margin-left: 60px;
    @media (max-width: 768px) {
    margin-left: 0px ;
    max-width: 390px;
    }
`
export const ProductWrapper = styled.div`
    display: flex;
    margin: 0px 70px;
    @media (max-width: 768px) {
    margin: 0px;
    flex-direction: column;
    align-items: center;
    }
`
export const CatalogPage = styled.div`
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
    width: 1440px;
    @media (max-width: 768px) {
    width: 100%;
    }
`
export const FilterSection = styled.div`
    @media (max-width: 768px){  
        display:none ;
}
`

export const MobileFilterSection = styled.div`
  @media (max-width: 768px) {
    width: 200px;
    padding: 10px;
  }
`;

export const ButtonText = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgb(90, 91, 93);
    
`
const EmptyBlockSection = styled.div`
    display: flex;
    width: 50% ;
    justify-content: center ;
    margin-left: 100px ;
    margin-bottom: 60px;
    @media (max-width: 768px){
        width: 95%;
        margin: 10px;
    }

`