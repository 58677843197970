import './menu.scss';
import { FiChevronDown } from "react-icons/fi";
import { DropProductsDesktop, DropProductsMobile } from "./drop_product";
import { menuItems } from './menu.data';
import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export const MenuDesktop = () => {
    return (
        <div>
            <ul className="menu">
                {menuItems.map((item) => (
                    <MenuItem key={item.label} item={item} />
                ))}
            </ul>
        </div>

    );
};

const MenuItem = ({ item }) => {
    const hasChildren = item.children && item.children.length > 0;
    return (
        <li className={`menu__item${hasChildren ? ' dropdown' : ''}`}>
            {hasChildren ? (
                <>
                    <span className="menu__item__link">{item.label}<FiChevronDown className="arrow" /></span>
                    <DropProductsDesktop categories={item.children} />
                </>
            ) : (
                <Link to={item.link}>{item.label}</Link>
            )}
        </li>
    );
};


const MenuItemMobile = ({ item }) => {
    const hasChildren = item.children && item.children.length > 0;
    return (
        <div>
            <Accordion.Header >
                <span className={`menu__item__link `}>
                    {item.link ? <Link to={item.link} style={{textDecoration:'none', color:'#5A5B5D'}}>
                        {item.label}
                    </Link> : <span style={{textDecoration:'none', color:'#5A5B5D'}}>
                        {item.label}
                    </span>}
                </span>
            </Accordion.Header>
            {hasChildren && (
                <Accordion.Body style={{padding: 0}}>
                    <DropProductsMobile categories={item.children} />
                </Accordion.Body>
            )}
        </div>
    );
};


export const MenuMobile = () => {
    return (
        <MobileMenuContainer>
            <Accordion className='menu_accordion'>
                {menuItems.map((item, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                        <MenuItemMobile item={item} />
                    </Accordion.Item>
                ))}
            </Accordion>
        </MobileMenuContainer>
    );
};


export const MobileMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 310px; 
  height: 100vh;
  background-color:  #F5F5F5;
  padding: 60px 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 9; 
`;