import { useState } from 'react';
import { Slider as MuiSlider } from '@mui/material';
import { styled } from '@mui/system';


const PriceSlider = styled(MuiSlider)({
    color: '#F12B04',
    '&.Mui-hover': {
        color: '#F12B04',
    },
});
interface PriceRangeSliderProps {
    min: number;
    max: number;
    onPriceChange: ([minPrice, maxPrice]: [number, number]) => void;
  }

    export const PriceRangeSlider = ({ min, max, onPriceChange }: PriceRangeSliderProps) => {
    const [PriceRangeValue, setPriceRangeValue] = useState([min, max]);

    const PriceRangeValueChange = (event, newValue) => {
        setPriceRangeValue(newValue);
        onPriceChange([newValue[0], newValue[1]]);
    };
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <PriceSlider
                    valueLabelDisplay="auto"
                    onChange={PriceRangeValueChange}
                    value={PriceRangeValue}
                    min={min}
                    max={max}
                    style={{ width: '100%' }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                    <label htmlFor="i1" style={{ marginTop: '-5px' }}>{min} грн.</label>
                    <label htmlFor="i2" style={{ marginTop: '-5px' }}>{max} грн.</label>
                </div>
            </div>
        </div>
    );
};


