import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal.scss';
import { Modal_Button } from '../shared/button/button';
import { ModalSuccess } from './modal_success';
import { sendEmail } from '../API/send_email';


export const SecondaryModal = (props) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('');

  const { show, onHide } = props;
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFocus = () => {
    if (!phone) {
      setPhone('+380');
    }
  };

  const phoneChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^\d]/g, '');
    newValue = '+' + newValue;
    setPhone(newValue);
  };

  const nameChange = (e) => {
    const newName = e.target.value
    setName(newName)
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    onHide();
  };

  const handleShowSuccessModal = async () => {
      await sendEmail(name, phone);
      setShowSuccessModal(true);
      onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        className="secondary-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <div className='modal_text'>Залишайте свої контактні дані та ми допоможемо розрахувати вартість</div>
        </Modal.Header>
        <input type='text' value={name} placeholder="Ім’я" onChange={nameChange} className='modalinput' />
        <input type='text' value={phone} placeholder="Номер телефону" onFocus={handleFocus} onChange={phoneChange} className='modalinput' />
        <Modal.Footer>
          <Modal_Button name='Розрахувати вартість' disabled={!phone || !name} onClick={handleShowSuccessModal} />
        </Modal.Footer>
      </Modal>
      <ModalSuccess
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        className="modal-success modal-dialog-centered"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      />
    </>

  );
};



