import { Footer } from "../footer/footer"
import { Header } from "../header/header"
import { AboutUs } from "./about_us/about_us"
import { MainBanner } from "./banner/banner"
import { CallBackForm } from "./callback_form/callback_form"
import { ProductCategories } from "./categories/categories"
import { OurProjects } from "./our_project/our_projects"
import { OurBenefits } from "./ourbenefits/ourbenefits"
import { Partners } from "./partners/partners"
import { Products } from "./products/window_products_onlanding"
import { SecondaryActions } from "./secondary_actions/secondary_actions"
import '../App.scss';
import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

export const LandingPage = () => {
    const aboutUsRef = useRef();
    const contactsRef = useRef();
    const ourProjectsRef = useRef();
    const location = useLocation();


    useEffect(() => {
        if (location.hash) {
            switch (location.hash) {
                case '#about_us':
                    //@ts-ignore
                    aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case '#our_projects':
                    //@ts-ignore
                    ourProjectsRef.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case '#contacts':
                    //@ts-ignore
                    contactsRef.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
            }
        }
    }, [location.hash]);

    return (
        <div className="App">
            <Header />
            <MainBanner />
            <ProductCategories />
            <Products />
            <OurBenefits></OurBenefits>
            <AboutUs linkRef={aboutUsRef}></AboutUs>
            <OurProjects linkRef={ourProjectsRef}></OurProjects>
            <Partners></Partners>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer linkRef={contactsRef}></Footer>
        </div>
    )
}



