import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { defaultTkanevRollColor, tkanevRollPrices, tkanevRollProducts } from "../../product_data/rolety_tkaniny_data";
import { Tkaninny_Rolet_Card } from "../../../landing_page/products/cards";
import { useState } from "react";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { PriceRangeSlider } from "../../filterBlock/price_slider";
import { ArtDenDrawer } from "../../drower";


export const TkanevRollety = () => {
    const [color, setColor] = useState(defaultTkanevRollColor.map(d => d.value))
    const [price, setPrice] = useState([tkanevRollPrices.min, tkanevRollPrices.max]);
    const [minPrice, maxPrice] = price
    const filteredProducts = tkanevRollProducts.filter((product) => {
        const price = parseInt(product.price, 10);
        return price >= minPrice && price <= maxPrice;
    }).filter(p => color.includes(p.color));
    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Тканинні ролети</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                    <PriceRangeSlider min={tkanevRollPrices.min} max={tkanevRollPrices.max} onPriceChange={setPrice} />
                                    <TextFilter>Декор/колір</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(color) => setColor(color)} options={defaultTkanevRollColor}></Filter>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                <PriceRangeSlider min={tkanevRollPrices.min} max={tkanevRollPrices.max} onPriceChange={setPrice} />
                                <TextFilter>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultTkanevRollColor}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}
                        <SameProductBlock>
                            <CardsBlock>
                                {filteredProducts.length === 0 ? (
                                    <EmptyBlock />) : (
                                    <CardsBlock>
                                        {filteredProducts.map((p) => (
                                            <CardWrapper key={p.id}>
                                                <Tkaninny_Rolet_Card
                                                    id={p.id}
                                                    image={p.image}
                                                    name={p.name}
                                                    price={p.price}
                                                />
                                            </CardWrapper>
                                        ))}
                                    </CardsBlock>
                                )}
                            </CardsBlock>
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};