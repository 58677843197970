import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { defaultEntranceDoorBrands, defaultEntranceDoorColor, defaultEntranceDoorType, entranceDoorProducts } from "../../product_data/entrance_door_data";
import { EntranceDoorCard } from "../../../landing_page/products/cards";
import { useState } from "react";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { ArtDenDrawer } from "../../drower";

export const EntranceDoorsCatalog = () => {
    const [brands, setBrands] = useState(defaultEntranceDoorBrands.map(b => b.value))
    const [color, setColor] = useState(defaultEntranceDoorColor.map(d => d.value))
    const [type, setType] = useState(defaultEntranceDoorType.map(t => t.value))
    const filteredProducts = entranceDoorProducts.filter((product) => {
        return entranceDoorProducts;
    }).filter(p => brands.includes(p.brand)).filter(p => type.includes(p.type)).filter(p => color.includes(p.color));

    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Вхідні двері</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter>Виробник</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(brands) => setBrands(brands)} options={defaultEntranceDoorBrands}></Filter>
                                    </FilterBlock>
                                    <TextFilter>Декор/колір</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(color) => setColor(color)} options={defaultEntranceDoorColor}></Filter>
                                    </FilterBlock>
                                    <TextFilter>Тип</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(type) => setType(type)} options={defaultEntranceDoorType}></Filter>
                                    </FilterBlock>
                                    <div style={{ marginBottom: '60px' }}></div>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter>Виробник</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(brands) => setBrands(brands)} options={defaultEntranceDoorBrands}></Filter>
                                </FilterBlock>
                                <TextFilter>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultEntranceDoorColor}></Filter>
                                </FilterBlock>
                                <TextFilter>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultEntranceDoorType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}
                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <EntranceDoorCard
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                param2={p.param2}
                                                param3={p.param3}
                                                price={p.price}
                                            ></EntranceDoorCard>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};