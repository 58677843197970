import a1 from "../../images/calculator/a1.png"
import a2 from "../../images/calculator/a2-min.png"
import a3 from "../../images/calculator/a3-min.png"
import a4 from "../../images/calculator/a4-min.png"
import a5 from "../../images/calculator/a5-min.png"
import a6 from "../../images/calculator/a6-min.png"
import b1 from "../../images/calculator/b1.png"
import b2 from "../../images/calculator/b2-min.png"
import b3 from "../../images/calculator/b3-min.png"
import b4 from "../../images/calculator/b4-min.png"
import b5 from "../../images/calculator/b5-min.png"
import b6 from "../../images/calculator/b6-min.png"
import b7 from "../../images/calculator/b7-min.png"
import b8 from "../../images/calculator/b8-min.png"
import d1 from "../../images/calculator/d1.png"
import d2 from "../../images/calculator/d2-min.png"
import d3 from "../../images/calculator/d3-min.png"
import d4 from "../../images/calculator/d4-min.png"
import d5 from "../../images/calculator/d5-min.png"
import d6 from "../../images/calculator/d6-min.png"
import e1 from "../../images/calculator/e1.png"
import { Im1, Im2, Im3, Im4, Im5, Im6, Im10,Im11,Im12,Im13,Im14, Im7, Im8, Im9, Im15, Im16, Im17, Im18, Im19, Im20, Im21} from "../icon_svg";

export interface Option {
    id: string;
    label: string;
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    image?: string;
  }
export const column1 : Option[]= [
    { id: "im1", label: "Глухе", icon: <Im1 color={"#474747"}/>, image:a1 },
    { id: "im2", label: "Поворотньо відкидне", icon: <Im2 color={"#474747"}/>, image:a2},
    { id: "im3", label: "Поворотне ліве", icon: <Im3 color={"#474747"}/>, image:a3 },
    { id: "im4", label: "Поворотньо-відкидне праве", icon: <Im4 color={"#474747"}/>,image:a4 },
    { id: "im5", label: "Поворотньо-відкидне ліве", icon: <Im5 color={"#474747"}/> ,image:a5},
    { id: "im6", label: "Відкидне", icon: <Im6 color={"#474747"}/>,image:a6 },
  ] 
  
  
  export const column2 : Option[] = [
      { id: "im7", label: "Дві глухі створки", icon: <Im7 color={"#474747"}/>,image:b1 },  
      { id: "im8", label: "Поворотньо-відкидне з глухою створкою справа", icon: <Im8 color={"#474747"}/>,image:b2 },
      { id: "im9", label: "Поворотньо-відкидне з глухою створкою зліва", icon: <Im9 color={"#474747"}/>, image:b3 },
      { id: "im10", label: "Поворотньо-відкидне с поворотною створкою справа", icon: <Im10 color={"#474747"}/>,image:b4 },
      { id: "im11", label: "Поворотньо-відкидне с поворотною створкою", icon: <Im11 color={"#474747"}/>,image:b5 },
      { id: "im12", label: "Поворотне з глухою створкою справа", icon: <Im12 color={"#474747"}/>,image:b6 },
      { id: "im13", label: "Поворотне з глухою створкою зліва", icon: <Im13 color={"#474747"}/> ,image:b7},
      { id: "im14", label: "Дві поворотньо-відкидні створки", icon: <Im14 color={"#474747"}/>,image:b8 },
    ] 
  
  
  export const column3 : Option[] = [
      { id: "im15", label: "Три глухі створки", icon: <Im15 color={"#474747"}/>,image:d1 },  
      { id: "im16", label: "Поворотньо-відкидна створка з двома крайніми глухими", icon: <Im16 color={"#474747"}/> ,image:d2},
      { id: "im17", label: "Поворотна створка з двома крайніми глухими", icon: <Im17 color={"#474747"}/>,image:d3 },
      { id: "im18", label: "Три поворотньо-відкидні створки", icon: <Im18 color={"#474747"}/>,image:d4 },
      { id: "im19", label: "Поворотня зліва, глуха в центрі, поворотньо-відкидна справа", icon: <Im19 color={"#474747"}/> ,image:d5},
      { id: "im20", label: "Поворотньо-відкидна зліва, глуха в центрі, поворотна справа", icon: <Im20 color={"#474747"}/> ,image:d6},
    ] 
  
    export const column4 : Option[] = [
      { id: "im21", label: " ", icon: <Im21 color={"#474747"}/> ,image:e1},  
    ]