import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { entranceDoorProducts } from "../../../catalog/product_data/entrance_door_data"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import {MyButton} from "../../../shared/button/button"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { EntranceDoorCard } from "../../../landing_page/products/cards"
import { Background, CardItem, Image, Text, ItemName, settings, Margin60, ImageBlock, CardBlockMargin, Margin16 } from "../../card"
import styled from "styled-components"
import { SecondaryModal } from "../../../modal/modal"

export const EntranceDoorProductPage = () => {
    const { id } = useParams()
    const product = entranceDoorProducts.find(w => w.id === parseInt(id as string))
    const currentProduct = entranceDoorProducts.find((p) => p.id === parseInt(id as string));
    const similarProducts = entranceDoorProducts.filter((p) => p.id !== parseInt(id as string) && p.brand === currentProduct?.brand);
    const [showComponent0, setShowComponent0] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);
    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {similarProducts.map((p) => (<EntranceDoorCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></EntranceDoorCard>))}
                        </Slider>
                    </ScrollBlock>
                </div>
            </CategoryBlock>
            </Background>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const CardBlock = ({ product }) => {
    return (
        <div>
            <CardBlockMargin>
                <ItemName>{product.name}</ItemName>
                <Margin16>
                    <Item style={{ width: '350px' }}>Виробник:{product.virobnyk}</Item>
                    <Item>Покриття: квартирне/вуличне</Item>
                    <Item>Броненакладка: так</Item>
                    <Item>Верхній замок: на вибір</Item>
                </Margin16>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <CardItem key={product.id}>
                        <Text style={{ marginBottom: '10px' }}>{product.text1}</Text>
                        <Text style={{ marginBottom: '10px' }}>{product.text2}</Text>
                        <Text style={{ marginBottom: '10px' }}>{product.text3}</Text>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Item>Характеристика:</Item>
                            <Parameter>Вид дв.коробки: гнутий профіль / труба профільна</Parameter>
                            <Parameter>Висота: 2050мм.</Parameter>
                            <Parameter>Вічко: на вибір</Parameter>
                            <Parameter>Гарантія: 1р.</Parameter>
                            <Parameter>Країна-виробник: Україна</Parameter>
                            <Parameter>Кількість антизрізів: 2/3шт</Parameter>
                            <Parameter>Кількість контурів ущільенння: 2/3шт</Parameter>
                            <Parameter>Кількість петель: 3шт</Parameter>
                            <Parameter>Наповнення коробки: мінеральна вата.</Parameter>
                            <Parameter>Наповнення полотна: мінеральна вата, фольгований утеплювач</Parameter>
                            <Parameter>Наявність нержавіючого порогу: ні</Parameter>
                            <Parameter>Нижній замок: на вибір</Parameter>
                            <Parameter>Товщина внутрішньох МДФ панелі: 10 мм./16 мм.</Parameter>
                            <Parameter>Товщина зовнішньої МДФ панелі: 16 мм.</Parameter>
                            <Parameter>Товщина металу коробки: 1,5 мм./2 мм.</Parameter>
                            <Parameter>Товщина металу полотна: 1 мм./1,4 мм./2 мм.</Parameter>
                            <Parameter>Товщина полотна: 95мм./105 мм.</Parameter>
                            <Parameter>Ширина: 850 мм./950 мм./1200 мм.(уточнюйте)</Parameter>
                        </div>
                    </CardItem>
                </div>
            </CardBlockMargin>
        </div>
    );
}


const Parameter = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color:#5A5B5D;
`
const Item = styled(Parameter)`
    font-size: 25px;
`