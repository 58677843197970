import { Footer } from "../../../footer/footer";
import { Header } from "../../../header/header";
import { CallBackForm } from "../../../landing_page/callback_form/callback_form";
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions";
import { BlockName } from "../../../shared/blockName/blockName";
import { defaultInteriorDoorBrands, defaultInteriorDoorColor, defaultInteriorDoorCover, defaultInteriorDoorType, flatDoorPrices, interiorDoorProducts } from "../../product_data/interior_door_data";
import { InteriorDoorCard } from "../../../landing_page/products/cards";
import { useState } from "react";
import { PriceRangeSlider } from "../../filterBlock/price_slider";
import { Filter } from "../../filterBlock/filter";
import { Background, CardWrapper, CardsBlock, EmptyBlock, FilterBlock, FilterSection, SameProductBlock, ProductWrapper, MobileFilterSection, TextFilter, CatalogPage } from "../../catalog_page_components";
import { ArtDenDrawer } from "../../drower";

export const InteriorDoorsCatalog = () => {
    const [price, setPrice] = useState([flatDoorPrices.min, flatDoorPrices.max]);
    const [brands, setBrands] = useState(defaultInteriorDoorBrands.map(b => b.value))
    const [cover, setCover] = useState(defaultInteriorDoorCover.map(c => c.value))
    const [color, setColor] = useState(defaultInteriorDoorColor.map(d => d.value))
    const [type, setType] = useState(defaultInteriorDoorType.map(t => t.value))
    const [minPrice, maxPrice] = price
    const filteredProducts = interiorDoorProducts.filter((product) => {
        const price = parseInt(product.price, 10);
        return price >= minPrice && price <= maxPrice;
    }).filter(p => brands.includes(p.brand)).filter(p => cover.includes(p.cover)).filter(p => p.type.some(t => type.includes(t))).filter(p => color.includes(p.color));
    const isMobile = window.innerWidth <= 768;
    const showFiltersInDrawer = isMobile;
    return (
        <div>
            <Header />
            <BlockName>Міжкімнатні двері</BlockName>
            <Background>
                <CatalogPage>
                    <ProductWrapper>
                        {showFiltersInDrawer ? (
                            <ArtDenDrawer>
                                <MobileFilterSection>
                                    <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                    <PriceRangeSlider min={flatDoorPrices.min} max={flatDoorPrices.max} onPriceChange={setPrice} />
                                    <TextFilter>Виробник</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(brands) => setBrands(brands)} options={defaultInteriorDoorBrands}></Filter>
                                    </FilterBlock>
                                    <TextFilter>Покриття</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(cover) => setCover(cover)} options={defaultInteriorDoorCover}></Filter>
                                    </FilterBlock>
                                    <TextFilter>Декор/колір</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(color) => setColor(color)} options={defaultInteriorDoorColor}></Filter>
                                    </FilterBlock>
                                    <TextFilter>Тип</TextFilter>
                                    <FilterBlock>
                                        <Filter filterChange={(type) => setType(type)} options={defaultInteriorDoorType}></Filter>
                                    </FilterBlock>
                                </MobileFilterSection>
                            </ArtDenDrawer>
                        ) : (
                            <FilterSection>
                                <TextFilter style={{ marginBottom: '-5px' }}>Вартість:</TextFilter>
                                <PriceRangeSlider min={flatDoorPrices.min} max={flatDoorPrices.max} onPriceChange={setPrice} />
                                <TextFilter>Виробник</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(brands) => setBrands(brands)} options={defaultInteriorDoorBrands}></Filter>
                                </FilterBlock>
                                <TextFilter>Покриття</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(cover) => setCover(cover)} options={defaultInteriorDoorCover}></Filter>
                                </FilterBlock>
                                <TextFilter>Декор/колір</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(color) => setColor(color)} options={defaultInteriorDoorColor}></Filter>
                                </FilterBlock>
                                <TextFilter>Тип</TextFilter>
                                <FilterBlock>
                                    <Filter filterChange={(type) => setType(type)} options={defaultInteriorDoorType}></Filter>
                                </FilterBlock>
                                <div style={{ marginBottom: '60px' }}></div>
                            </FilterSection>
                        )}

                        <SameProductBlock>
                            {filteredProducts.length === 0 ? (
                                <EmptyBlock />) : (
                                <CardsBlock>
                                    {filteredProducts.map((p) => (
                                        <CardWrapper key={p.id}>
                                            <InteriorDoorCard
                                                id={p.id}
                                                image={p.image}
                                                name={p.name}
                                                param1={p.param1}
                                                param2={p.param2}
                                                param3={p.param3}
                                                price={p.price}
                                            ></InteriorDoorCard>
                                        </CardWrapper>
                                    ))}
                                </CardsBlock>
                            )}
                        </SameProductBlock>
                    </ProductWrapper>
                </CatalogPage>
            </Background >
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div >
    );
};