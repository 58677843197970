import rol1 from "../../images/catalog/vidlyv/білий1.png"
import rol2 from "../../images/catalog/vidlyv/коричневий.png"
import rol3 from "../../images/catalog/vidlyv/білий2.png"
import rol4 from "../../images/catalog/vidlyv/вишневий.png"
import rol5 from "../../images/catalog/vidlyv/зелений.png"
import rol6 from "../../images/catalog/vidlyv/графіт.png"
import rol7 from "../../images/catalog/vidlyv/антрацит.png"
import rol8 from "../../images/catalog/vidlyv/чорний.png"
import rol9 from "../../images/catalog/vidlyv/темний_дуб.png"
import rol10 from "../../images/catalog/vidlyv/золотий_дуб.png"
import rol11 from "../../images/catalog/vidlyv/цинк.png"
import rol12 from "../../images/catalog/vidlyv/1.png"
import type1 from "../../images/catalog/vidlyv/price_type1.png"
import type2 from "../../images/catalog/vidlyv/price_type2.png"
import type3 from "../../images/catalog/vidlyv/price_type3.png"

// Тип
export enum VidlyvType {
    SMALL = 'small',
    MEDIUM = 'medium',
    BIG='big'
}
export const defaultVidlyvType = [
    { value: VidlyvType.SMALL, label: '0,39 мм' },
    { value: VidlyvType.MEDIUM, label: '0,43 мм' },
    { value: VidlyvType.BIG, label: '0,8 мм' }
]

export const brandType = {
[VidlyvType.SMALL]: {
    image: type1,
    values: [
        { width: '50-100 мм', price: '102 грн' },
        { width: '110 мм', price: '108 грн' },
        { width: '120 мм', price: '116 грн' },
        { width: '130 мм', price: '122 грн' },
        { width: '140 мм', price: '130 грн' },
        { width: '150 мм', price: '136 грн' },
        { width: '160 мм', price: '142 грн' },
        { width: '170 мм', price: '150 грн' },
        { width: '180 мм', price: '156 грн' },
        { width: '190 мм', price: '164 грн' },
        { width: '200 мм', price: '170 грн' },
        { width: '210 мм', price: '176 грн' },
        { width: '220 мм', price: '184 грн' },
        { width: '230 мм', price: '190 грн' },
        { width: '240 мм', price: '198 грн' },
        { width: '250 мм', price: '204 грн' },
        { width: '260 мм', price: '210 грн' },
        { width: '270 мм', price: '218 грн' },
        { width: '280 мм', price: '224 грн' },
        { width: '290 мм', price: '232 грн' },
        { width: '300 мм', price: '238 грн' },
        { width: '310 мм', price: '244 грн' },
        { width: '320 мм', price: '252 грн' },
        { width: '330 мм', price: '258 грн' },
        { width: '340 мм', price: '266 грн' },
        { width: '350 мм', price: '272 грн' },
        { width: '360 мм', price: '275 грн' },
      ],
 },

 [VidlyvType.MEDIUM]:{
    image: type2,
    values: [
        { width: '50-100 мм', price: '126 грн' },
        { width: '120 мм', price: '142 грн' },
        { width: '140 мм', price: '160 грн' },
        { width: '160 мм', price: '176 грн' },
        { width: '180 мм', price: '194 грн' },
        { width: '200 мм', price: '210 грн' },
        { width: '220 мм', price: '226 грн' },
        { width: '240 мм', price: '224 грн' },
        { width: '260 мм', price: '260 грн' },
        { width: '280 мм', price: '278 грн' },
        { width: '300 мм', price: '294 грн' },
        { width: '320 мм', price: '310 грн' },
        { width: '340 мм', price: '328 грн' },
        { width: '360 мм', price: '344 грн' },
      ],
 },
 [VidlyvType.BIG]:{
    image: type3,
    values: [
        { width: '60 мм', price: '272 грн' },
        { width: '70 мм', price: '296 грн' },
        { width: '80 мм', price: '318 грн' },
        { width: '90 мм', price: '340 грн' },
        { width: '100 мм', price: '364 грн' },
        { width: '110 мм', price: '386 грн' },
        { width: '120 мм', price: '408 грн' },
        { width: '130 мм', price: '432 грн' },
        { width: '140 мм', price: '454 грн' },
        { width: '150 мм', price: '478 грн' },
        { width: '160 мм', price: '500 грн' },
        { width: '170 мм', price: '522 грн' },
        { width: '180 мм', price: '546 грн' },
        { width: '190 мм', price: '568 грн' },
        { width: '200 мм', price: '590 грн' },
        { width: '210 мм', price: '614 грн' },
        { width: '220 мм', price: '636 грн' },
        { width: '230 мм', price: '682 грн' },
        { width: '240 мм', price: '682 грн' },
        { width: '250 мм', price: '704 грн' },
        { width: '260 мм', price: '726 грн' },
        { width: '270 мм', price: '750 грн' },
        { width: '280 мм', price: '772 грн' },
        { width: '290 мм', price: '786 грн' },
        { width: '300 мм', price: '818 грн' },
        { width: '310 мм', price: '840 грн' },
        { width: '320 мм', price: '864 грн' },
        { width: '330 мм', price: '886 грн' },
        { width: '340 мм', price: '908 грн' },
      ],
 },
}
export const vidlivyProducts = [
    
    { id: 4000, image: rol1, name: "Відлив білий", param1:"Товщина: 0,39 мм",price: null, type:VidlyvType.SMALL},
    { id: 4001, image: rol2, name: "Відлив коричневий", param1:"Товщина: 0,39 мм",price: null, type:VidlyvType.SMALL},
    { id: 4002, image: rol3, name: "Відлив білий", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4003, image: rol4, name: "Відлив вишневий", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4004, image: rol5, name: "Відлив зелений", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4005, image: rol6, name: "Відлив графіт", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4006, image: rol7, name: "Відлив антрацит", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4007, image: rol8, name: "Відлив чорний", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4008, image: rol9, name: "Відлив темний дуб", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4009, image: rol10, name: "Відлив золотий дуб", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4010, image: rol11, name: "Відлив цинк", param1:"Товщина: 0,43 мм",price: null, type:VidlyvType.MEDIUM},
    { id: 4011, image: rol12, name: "Відлив білий", param1:"Товщина: 0,8 мм",price: null, type:VidlyvType.BIG}
]