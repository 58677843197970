import styled from "styled-components"
import { Header } from "../../../header/header";
import { SecondaryActions } from "../../secondary_actions/secondary_actions";
import { CallBackForm } from "../../callback_form/callback_form";
import { Footer } from "../../../footer/footer";
import { BlockName } from "../../../shared/blockName/blockName";


export const PhotoProjectPage = ({ data }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header></Header>
      <Background>
        <Page data={data}></Page>
      </Background>
      <SecondaryActions></SecondaryActions>
      <CallBackForm></CallBackForm>
      <Footer></Footer>
    </div>
  )
}

const Page = ({ data }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {data.title && <BlockName>{data.title}</BlockName>}
      {data.year && <Year>{data.year}</Year>}
      <Margin>
        <WidthContainer>
          {data.data?.map((item) => (
            <Flex key={item.id}>
              <ImageWrapper>
                <Image src={item.image} />
              </ImageWrapper>
            </Flex>
          ))}
        </WidthContainer>
      </Margin>
    </div>
  );
};
const Background = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`
const Image = styled.img`
    width: 100%;
    max-height: 270px;
    object-fit:cover;
    @media (max-width: 768px) {
      max-height: 120px;
    }
`

const Year = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color:#5A5B5D;
    margin-top: -30px;
`
export const ImageWrapper = styled.div`
    margin: 0 15px;
    @media (max-width: 768px) {
    margin: 0px 5px;
    }
`;

const Margin = styled.div`
    display: 'flex';
    flex-direction: 'row';
    margin : 60px;
    @media (max-width: 768px) {
    margin: 5px;
    }
`
const WidthContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1440px;
    @media (max-width: 768px) {
    width: 100%;
    }
`
const Flex = styled.div`
    flex: 0 0 30%;
    margin-bottom:20px;
@media (max-width: 768px) {
    margin-bottom:5px;
    flex: 0 0 50%;
    }
`