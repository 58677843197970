import styled from "styled-components"
import { BlockName } from "../../shared/blockName/blockName";

export const AboutUs = ({linkRef}) => {
    return (
        <div id="about_us" ref={linkRef}>
            <CategoryBlock>
                <BlockName> Про нас </BlockName>
                <TextBlock>
                    Компанія “АртДен” - це висококласні фахівці та 20 років успішного
                    досвіду на прикарпатському ринку металопластикових та алюмінієвих вікон та дверей.
                    Нестандартні та виготовлені за індивідуальними проектами конструкції для вашого дому.
                    Сьогодні ми готові реалізувати будь-який задум архітекторів, дизайнерів або забудовників.
                </TextBlock>
            </CategoryBlock>
        </div>
    );
};

const CategoryBlock = styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    background: #ffffff;
    justify-content: center;
    flex-direction: column;
`

const TextBlock = styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #5A5B5D;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0px 178px;
    @media (max-width: 768px) {
    margin:0px 15px;
    text-align:justify;
    font-size: 14px;
    line-height: 18px;
    }
`

