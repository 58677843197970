import wind1 from "../../images/catalog/windowsill/ganz/біледеревоганс.jpeg"
import wind2 from "../../images/catalog/windowsill/ganz/podokonnik-ganz-14.jpg"
import wind3 from "../../images/catalog/windowsill/ganz/глянецьganz.jpeg"
import wind4 from "../../images/catalog/windowsill/ganz/брістолганс.jpeg"
import wind5 from "../../images/catalog/windowsill/ganz/горіхганс.jpeg"
import wind6 from "../../images/catalog/windowsill/ganz/золотийдуб.jpeg"
import wind7 from "../../images/catalog/windowsill/ganz/лофтганс.jpeg"
import wind8 from "../../images/catalog/windowsill/ganz/стромбліганс.jpeg"
import wind9 from "../../images/catalog/windowsill/estera/estera-21.jpg"
import wind10 from "../../images/catalog/windowsill/estera/sanlaytестера.jpg"
import wind11 from "../../images/catalog/windowsill/estera/sofiyaестера.jpg"
import wind12 from "../../images/catalog/windowsill/estera/bristolестера.jpg"
import wind13 from "../../images/catalog/windowsill/estera/mramorестара.jpg"
import wind14 from "../../images/catalog/windowsill/estera/palermoестера.jpg"
import wind15 from "../../images/catalog/windowsill/crystalit/palermo.webp"
import wind16 from "../../images/catalog/windowsill/crystalit/biliy.webp"
import wind17 from "../../images/catalog/windowsill/crystalit/белыйдуб.webp"
import wind18 from "../../images/catalog/windowsill/crystalit/bristol.webp"

//--------Підвіконня
// Виробник
export enum WindowsillBrands {
    GANZ = 'Ganz',
    CRYSTALIT = 'Crystalit',
    ESTERA = 'Estera',
}
export const defaultWindowsillBrands = [
    { value: WindowsillBrands.GANZ, label: 'Ganz' },
    { value: WindowsillBrands.CRYSTALIT, label: 'Crystalit' },
    { value: WindowsillBrands.ESTERA, label: 'Estera' },
]

//  Покриття
export enum WindowsillCover {
    PLASTIC = 'plastic',
    DEREVO = 'derevo'
}
export const defaultWindowsillCover = [
    { value: WindowsillCover.PLASTIC, label: 'Пластик' },
    { value: WindowsillCover.DEREVO, label: 'Дерево' }
]
// Колір
export enum WindowsillColor {
    WHITE = 'white',
    KAMIN = 'kamin',
    HEITEK = 'heitek',
    DEREVO = 'derevo',
}
export const defaultWindowsillColor = [
    { value: WindowsillColor.WHITE, label: 'Білий' },
    { value: WindowsillColor.KAMIN, label: 'Під камінь' },
    { value: WindowsillColor.HEITEK, label: 'Хай-тек' },
    { value: WindowsillColor.DEREVO, label: 'Під дерево' },
]
//Тип
export enum WindowsillType {
    PRYAMIY = 'pryamiy',
    ZAOKRYGLENIY = 'zaokrugleniy',
    NEOTYPOVIY = 'neotypoviy',
}
export const defaultWindowsillType = [
    { value: WindowsillType.PRYAMIY, label: 'Прямий капінос' },
    { value: WindowsillType.ZAOKRYGLENIY, label: 'Заокруглений капінос' },
    { value: WindowsillType.NEOTYPOVIY, label: 'Нетиповий капінос' },
]

export const brandParameters = {
    [WindowsillBrands.GANZ]: {
      text1: 'Ganz пропонує найкращий вибір бюджетних підвіконь з акриловим покриттям. Ми гарантуємо високу якість та надійність продукції, яка буде служити Вам на довгі роки. Є широка палітра кольорів, включаючи білий, лофт, Стромбела, біле дерево, Брістолі, золотий дуб та горіх. Підвіконня мають глибину від 150 мм до 600 мм, а максимальна цільна довжина становить 6 метрів.',
      text2: 'Ganz має посилені ребра жорсткості та потовщені стінки, що забезпечує максимальну стійкість та надійність. Індивідуальний капінос та глянцеву поверхню з німецьким покриттям IMAWELL, що не вигорає на сонці та стійка до високих температур. Це дозволяє нашим підвіконням служити довгий час та не втрачати свій естетичний вигляд.',
      values: [
        { width: '150 мм', price: '549,99 грн' },
        { width: '200 мм', price: '733,2 грн' },
        { width: '250 мм', price: '916,5 грн' },
        { width: '300 мм', price: '1099,8 грн' },
        { width: '350 мм', price: '1283,1 грн' },
        { width: '400 мм', price: '1466,4 грн' },
        { width: '450 мм', price: '1649,7 грн' },
        { width: '500 мм', price: '1833 грн' },
        { width: '600 мм', price: '2199,5 грн' },
      ],
      zaglyshkaPrice:'40',
    },
    [WindowsillBrands.CRYSTALIT]: {
      text1: 'Вироби марки Crystalit - новинка на вітчизняному ринку. Товари цього виробництва мають високі якісні характеристики і складають гідну конкуренцію найвідомішим брендам.',
      text2: "Підвіконня Crystalit за відгуками українських покупців, вже знайомих з продукцією, є одними з кращих в цьому товарному сегменті. Серед переваг відзначають: привабливий дизайн; еликий вибір кольорів (можна придбати підвіконня Кристаліт для будь-якого інтер'єру); високу міцність (має надійні ребра жорсткості); Підвіконня мають глибину від 150 мм до 600 мм, а максимальна цільна довжина становить 6 метрів.",
      values: [
        { width: '150 мм', price: '699,9 грн' },
        { width: '200 мм', price: '933,2 грн' },
        { width: '250 мм', price: '1116,5 грн' },
        { width: '300 мм', price: '1199,8 грн' },
        { width: '350 мм', price: '1633,1 грн' },
        { width: '400 мм', price: '1866,4 грн' },
        { width: '450 мм', price: '2099,7 грн' },
        { width: '500 мм', price: '2333 грн' },
        { width: '600 мм', price: '2799,5 грн' },
      ],
      zaglyshkaPrice:'70',
    },
    [WindowsillBrands.ESTERA]: {
        text1: 'Підвіконня Естера виглядають естетично — капінос (передній вільний край підвіконня) закруглений, що надає йому вигляд вироби з натурального каменю.',
        text2: "Поверхня підвіконня матова, і прекрасно поєднується з будь-яким інтер'єром — будь це житлове або комерційне будівля. Перерахуємо головні особливості підвіконь Естера: Стійкі до вологи. Навіть при високій вологості в приміщенні і попаданні води на поверхню, підвіконня не стане набухати і розшаровуватися. Високоміцні. Каркас підвіконня витримує високе навантаження: на нього можна сміливо сідати. Захист від подряпин. Покриття Elesgo здатне витримати квіткові горщики, домашнє начиння, предмети інтер'єру, домашніх тварин. На Сонці не вигоряють. Акриловий шар активно захищає від ультрафіолетових променів, тим самим, зберігаючи первинний вигляд підвіконня на довгі роки.Стійкі до побутової хімії. Підвіконня можна обробляти засобами побутової хімії, не турбуючись його пошкодити.",
        values: [
          { width: '150 мм', price: '699,9 грн' },
          { width: '200 мм', price: '933,2 грн' },
          { width: '250 мм', price: '1116,5 грн' },
          { width: '300 мм', price: '1199,8 грн' },
          { width: '350 мм', price: '1683,1 грн' },
          { width: '400 мм', price: '1866,4 грн' },
          { width: '450 мм', price: '2099,7 грн' },
          { width: '500 мм', price: '2333 грн' },
          { width: '600 мм', price: '2799,6 грн' },
        ],
        zaglyshkaPrice:'70',
      },
};



export const WindowsillProducts = [
    //  Ganz
    { id: 114, image: wind1, name: "Біле дерево Ganz",color:WindowsillColor.DEREVO, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY, param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 115, image: wind2, name: "Білий Ganz", color:WindowsillColor.WHITE,param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY , param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 116, image: wind3, name: "Білий глянс Ganz",color:WindowsillColor.WHITE, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY , param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 117, image: wind4, name: "Брістол Ganz",color:WindowsillColor.KAMIN ,param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY , param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 118, image: wind5, name: "Горіх Ganz",color:WindowsillColor.DEREVO, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY , param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 119, image: wind6, name: "Золотий дуб Ganz",color:WindowsillColor.DEREVO, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY, param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 120, image: wind7, name: "Лофт Ganz",color:WindowsillColor.HEITEK, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY, param4:'Нетиповий капінос', param5:"IMAWELL" },
    { id: 121, image: wind8, name: "Стромболі Ganz",color:WindowsillColor.KAMIN, param1: 'Виробник: Ganz', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.GANZ, cover: WindowsillCover.PLASTIC, type: WindowsillType.NEOTYPOVIY, param4:'Нетиповий капінос', param5:"IMAWELL" },
    //ESTERA
    { id: 122, image: wind9, name: "Bottichino Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY , param4:'Заокруглений капінос', param5:"ELESGO"},
    { id: 123, image: wind10, name: "Sanlayt Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY, param4:'Заокруглений капінос', param5:"ELESGO" },
    { id: 124, image: wind11, name: "Sofia Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY , param4:'Заокруглений капінос', param5:"ELESGO"},
    { id: 125, image: wind12, name: "Bristol Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY , param4:'Заокруглений капінос', param5:"ELESGO"},
    { id: 126, image: wind13, name: "Mramor Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY , param4:'Заокруглений капінос', param5:"ELESGO"},
    { id: 127, image: wind14, name: "Palermo Estera",color:WindowsillColor.KAMIN, param1: 'Виробник: Estera', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Напівматовий', price: null, brand: WindowsillBrands.ESTERA, cover: WindowsillCover.PLASTIC, type: WindowsillType.ZAOKRYGLENIY , param4:'Заокруглений капінос', param5:"ELESGO"},
    //Crystalit
    { id: 128, image: wind15, name: "Palermo Crystalit",color:WindowsillColor.KAMIN, param1: 'Виробник: Crystalit', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.CRYSTALIT, cover: WindowsillCover.PLASTIC, type: WindowsillType.PRYAMIY, param4:'Прямий', param5:"Elesgo" },
    { id: 129, image: wind16, name: "Білий Crystalit", color:WindowsillColor.WHITE,param1: 'Виробник: Crystalit', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.CRYSTALIT, cover: WindowsillCover.PLASTIC, type: WindowsillType.PRYAMIY, param4:'Прямий', param5:"Elesgo" },
    { id: 130, image: wind17, name: "Білий дуб Crystalit",color:WindowsillColor.DEREVO, param1: 'Виробник: Crystalit', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.CRYSTALIT, cover: WindowsillCover.PLASTIC, type: WindowsillType.PRYAMIY, param4:'Прямий', param5:"Elesgo" },
    { id: 131, image: wind18, name: "Bristol Crystalit",color:WindowsillColor.KAMIN, param1: 'Виробник: Crystalit', param2: 'Матеріал: Пластик', param3: 'Вид поверхні: Глянець', price: null, brand: WindowsillBrands.CRYSTALIT, cover: WindowsillCover.PLASTIC, type: WindowsillType.PRYAMIY, param4:'Прямий', param5:"Elesgo" },
    ]
