import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, CardItem, Image, Text, ItemName, settings, Margin60, ImageBlock, CardBlockMargin, Margin10 } from "../../card"
import { vidlivyProducts, brandType } from "../../../catalog/product_data/vidlyv_data"
import { VodovidlivyCard } from "../../../landing_page/products/cards"
import { Item, SmallItem } from "../windowsill/windowsill"
import { SecondaryModal } from "../../../modal/modal"
import { Dropdown } from "react-bootstrap"
import "../windowsill/dropdown.scss"
import { MyButton } from "../../../shared/button/button"

export const VidlyvyCard = () => {
    const { id } = useParams()
    const product = vidlivyProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = vidlivyProducts.filter((p) => p.id !== parseInt(id as string));
    const [showComponent0, setShowComponent0] = useState(false);
    const [selectedWidth, setSelectedWidth] = useState('');
    const handleWidthChange = (newWidth) => {
        setSelectedWidth(newWidth);
    };

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product} onWidthChange={handleWidthChange}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            </Background>
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden', marginBottom: '60px' }}>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {similarProducts.map((p) => (<VodovidlivyCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} price={p.price}></VodovidlivyCard>))}
                        </Slider>
                    </ScrollBlock>
                </div>
            </CategoryBlock>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CardBlock = ({ product, onWidthChange }) => {
    const [selectedWidth, setSelectedWidth] = useState('150');
    const { values } = brandType[product.type];

    const handleWidthChange = (newWidth) => {
      setSelectedWidth(newWidth);
      onWidthChange(newWidth);
    };
  
    const getPriceByWidth = (width) => {
      const selectedValue = values.find((item) => item.width === width);
      return selectedValue ? selectedValue.price : '';
    };
  
    useEffect(() => {
      handleWidthChange(selectedWidth);
    }, [selectedWidth]);
    return (
        <CardBlockMargin>
            <ItemName>{product.name}</ItemName>
            <Item>{product.param1}</Item>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <CardItem>
                    <Text style={{ marginBottom: '10px' }}>Відлив - це зовнішній компонент віконного профілю, який розташовується внизу пройому. Одна з головних функцій відливу полягає в захисті віконного відкосу та віконних рам від потрапляння вологи, що може призвести до пошкодження матеріалів та розвитку грибкових інфекцій.</Text>
                    <Text style={{ marginBottom: '10px' }}>Відливи виготовляються з різних матеріалів, зокрема з оцинкованої сталі, алюмінію та ПВХ. Вибір матеріалу залежить від умов експлуатації та вимог до зовнішнього вигляду. Наприклад, відливи з оцинкованої сталі відрізняються довговічністю та міцністю, а відливи з алюмінію - легкістю та стійкістю до корозії. Відливи з ПВХ мають високу стійкість до ультрафіолетового випромінювання та не вимагають додаткового догляду.</Text>
                    <Text style={{ marginBottom: '10px' }}>Важливо зазначити, що монтаж відливу повинен виконуватись одночасно з установкою вікна, оскільки це гарантує надійний захист від вологи та забезпечує правильну роботу віконної конструкції. Необхідно звернути увагу на довговічність та стійкість до корозії при виборі відливу.</Text>
                </CardItem>
            </div>
            <SmallItem>Ширина х м.п</SmallItem>
            <Margin10>
              <Dropdown onSelect={handleWidthChange}>
                <Dropdown.Toggle variant="secondary" className="artden-toggle">
                  {selectedWidth}
                </Dropdown.Toggle>
                <Dropdown.Menu className="artden-menu">
                  {values.map((item) => (
                    <Dropdown.Item key={item.width} eventKey={item.width}>
                      {item.width}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {selectedWidth && (
                <SmallItem style={{ marginLeft: '10px' }}>{getPriceByWidth(selectedWidth)} грн.</SmallItem>
              )}
            </Margin10>
        </CardBlockMargin>

    );
}

