import styled from "styled-components";

export const BlockName=styled.div`
    margin: 60px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #5A5B5D;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
    margin: 30px 0px;
    }
`

