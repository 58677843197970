import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { MyButton } from "../../../shared/button/button"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, Image, Text, ItemName, settings, Margin60, ImageBlock, SliderBlock, CardBlockMargin, Margin16, } from "../../card"
import { Den_Rolet_Card } from "../../../landing_page/products/cards"
import { denNichRollProducts } from "../../../catalog/product_data/den_nich_rolety"
import { SecondaryModal } from "../../../modal/modal"
import { Kriplennya } from "./kriplennya"

export const Den_nich_Rolety_Product_Card = () => {
    const { id } = useParams()
    const product = denNichRollProducts.find(w => w.id === parseInt(id as string))
    const similarProducts = denNichRollProducts.filter((p) => p.id !== parseInt(id as string));
    const [showComponent0, setShowComponent0] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <Background style={{ marginBottom: "0px" }}>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
            </Background>
            <CategoryBlock>
                <BlockName>Cхожі моделі</BlockName>
                <SliderBlock>
                    <ScrollBlock>
                        <Slider {...settings}>
                            {similarProducts.map((p) => (<Den_Rolet_Card key={p.id} id={p.id} image={p.image} name={p.name} price={p.price}></Den_Rolet_Card>))}
                        </Slider>
                    </ScrollBlock>
                </SliderBlock>
            </CategoryBlock>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}
const CardBlock = ({ product }) => {
    return (
        <CardBlockMargin>
            <ItemName>{product.name}</ItemName>
            <ItemName>Вартість: {product.price} грн/м²</ItemName>
            <Margin16>
                <Text>Ролети день-ніч – популярний варіант декорування вікон у домі чи офісі. Ефектний вигляд надає цьому виду жалюзі здатності гармонічно доповнити будь-який стиль приміщення, зробити його оригінальним та сучасним. Придбати чи замовити жалюзі день-ніч високої якості та за доступну ціну пропонуємо в офісі «АртДен».</Text>
                <Text>Легкість керування. Простим рухом Ви можете регулювати освітленння, відкрити ролети повністю або ж навпаки – перекрити доступ сонячним променям, затемнивши кімнату;</Text>
                <Text>Простота догляду. Ролети мають спеціальну обробку, яка відштовхує пил і бруд – для догляду за дерев'яними і тканинними ламелями достатньо сухої чистки раз на рік, а пластикові та металеві вироби можна мити водою;</Text>
                <Text>Стильний дизайн, завдяки якому ролети день-ніч пасують до будь-якого інтер’єру.</Text>
            </Margin16>
            <ItemName>
                Типи кріплення
                <Kriplennya></Kriplennya>
            </ItemName>

        </CardBlockMargin>

    );
}