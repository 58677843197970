import styled from "styled-components"
import { MyButton } from "../../shared/button/button"
import { useState } from "react"
import './callback.scss'
import { ModalSuccess } from "../../modal/modal_success"
import { sendEmail } from "../../API/send_email"


export const CallBackForm = () => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleFocus = () => {
        if (!phone) {
            setPhone('+380');
        }
    };

    const phoneChange = (e) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/[^\d]/g, '');
        newValue = '+' + newValue;
        setPhone(newValue);
    };

    const nameChange = (e) => {
        const newName = e.target.value
        setName(newName)
    }
    const handleShowSuccessModal = async () => {
        await sendEmail(name, phone);
        setShowSuccessModal(true);
    };
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    };
    return (
        <Block>
            <Contactform>
                <Text>Залишайте свої контактні дані та ми допоможемо розрахувати вартість</Text>
                <Contactform_wrapper>
                    <input type='text' value={name} placeholder="Ім’я" onChange={nameChange} className='whiteinput' />
                    <input type='text' value={phone} placeholder="Номер телефону" onFocus={handleFocus} onChange={phoneChange} className='whiteinput' />
                    <MyButton name='Розрахувати вартість' disabled={!phone || !name} onClick={handleShowSuccessModal} />
                    <ModalSuccess
                        show={showSuccessModal}
                        onHide={handleCloseSuccessModal}
                        className="modal-success modal-dialog-centered"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    />
                </Contactform_wrapper>
            </Contactform>
        </Block>
    )
}

const Block = styled.div`
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #474747;
    border: 1px solid #000000;
    @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        height:100%;
        justify-content:normal;
        align-content:center;
        flex-wrap: wrap;
        align-content:center;
    }
`

const Contactform = styled.div`
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    }
`
const Text = styled.div`
margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
    margin-top:30px

    }
`
const Contactform_wrapper = styled.div`
    display: flex;
    flex-direction: row;
   @media (max-width: 768px) {
    margin-bottom:30px;
    flex-direction: column;
    } 
`