import './telephone.scss';

function Telephone() {
    return (
    
        <ul className="telephone">
            <li className="item">+38 (099) 333 03 65</li>
            <li className="item">+38 (098) 433 03 65</li>
            </ul>
    );    
}   

export default Telephone;
