import rol1 from "../../images/catalog/furnitura/windows/ручка_віконна_Roto_Swing_secustik_біла.png"
import rol2 from "../../images/catalog/furnitura/windows/round_біла.png"
import rol3 from "../../images/catalog/furnitura/windows/аfina_біла.png"
import rol4 from "../../images/catalog/furnitura/windows/eLIT_протизламна_біла.png"
import rol5 from "../../images/catalog/furnitura/windows/premium_(скруглена).png"
import rol6 from "../../images/catalog/furnitura/windows/premium_(пряма).png"
import rol7 from "../../images/catalog/furnitura/windows/premium_КЛЮЧ.png"
import rol8 from "../../images/catalog/furnitura/windows/premium_RAL_7016_КЛЮЧ.png"
import rol9 from "../../images/catalog/furnitura/windows/асиметрична_Premium(скруглена).png"
import rol10 from "../../images/catalog/furnitura/windows/двостороння_симетрична_Premium_(скруглена).png"
import rol11 from "../../images/catalog/furnitura/windows/hoppe_New_York:Secustik_срібна.png"
import rol12 from "../../images/catalog/furnitura/windows/hoppe_New_York:Secustik_бронза.png"
import rol13 from "../../images/catalog/furnitura/windows/hoppe_New_York:Secustik_антрацит.png"
import rol14 from "../../images/catalog/furnitura/windows/пряма_(500мм)_(Пара).png"
import rol15 from "../../images/catalog/furnitura/windows/пряма_(1000мм)_(Пара).png"
import rol16 from "../../images/catalog/furnitura/windows/пряма_(500мм)_антрацит_7024.png"
import rol17 from "../../images/catalog/furnitura/windows/пряма_(1000мм)_антрацит 7024.png"
import rol18 from "../../images/catalog/furnitura/windows/пряма_(500мм)_нержавійка_(кронштейн 9005)_(Пара).png"
import rol19 from "../../images/catalog/furnitura/windows/пряма_(1000мм)_нержавійка_(кронштейн 9005)_(Пара).png"
import rol20 from "../../images/catalog/furnitura/windows/офісна_П-подібна_(300 мм)_біла.png"
import rol21 from "../../images/catalog/furnitura/windows/офісна_П-подібна_(300 мм)_срібна.png"
import rol22 from "../../images/catalog/furnitura/windows/курця(ракушка)_C-подібна.png"
import rol23 from "../../images/catalog/furnitura/windows/гарнітур_Vorne_90.png"
import rol24 from "../../images/catalog/furnitura/windows/гарнітур_Vorne_82.png"
import rol25 from "../../images/catalog/furnitura/windows/гарнітур_Vorne_85:Антрацит.png"
import rol26 from "../../images/catalog/furnitura/windows/циліндр_RIKO_40*40_ключ.png"
import rol27 from "../../images/catalog/furnitura/windows/циліндр_USK_45*45_ключ.png"
import rol28 from "../../images/catalog/furnitura/windows/циліндр_ROTO_S6_45*50_ключ.png"
import rol29 from "../../images/catalog/furnitura/windows/накладка_циліндра.png"
import rol30 from "../../images/catalog/furnitura/windows/петля_Jocker.png"
import rol31 from "../../images/catalog/furnitura/windows/петля_Vorne .png"
import rol32 from "../../images/catalog/furnitura/windows/петля_Aksis.png"
import rol33 from "../../images/catalog/furnitura/windows/петля_віконна_Vorne_на_раму_верхня.png"
import rol34 from "../../images/catalog/furnitura/windows/петля_віконна_Vorne_на_раму_нижня.png"
import rol35 from "../../images/catalog/furnitura/windows/петля_віконна_Vorne_на_стулку_нижня.png"
import rol36 from "../../images/catalog/furnitura/windows/vorne_1600_25.png"
import rol37 from "../../images/catalog/furnitura/windows/vorne_1600_35.png"
import rol38 from "../../images/catalog/furnitura/windows/vorne_1800_25:92.png"
import rol39 from "../../images/catalog/furnitura/windows/привід_п:відкидний_Vorne.png"
import rol40 from "../../images/catalog/furnitura/windows/зустрічна_планка_Vorne .png"
import rol41 from "../../images/catalog/furnitura/windows/балконна_защіпка_(магнітна).png"
import rol42 from "../../images/catalog/furnitura/windows/обмежувач_відкриття_SIEGENIA.png"
import rol43 from "../../images/catalog/furnitura/windows/ніжка_фіксатор_дверей.png"
import rol44 from "../../images/catalog/furnitura/windows/шпінгалет_дверний_підсилений.png"
import rol45 from "../../images/catalog/furnitura/windows/дотягувач_RYOBI_9903_STD.png"
import rol46 from "../../images/catalog/furnitura/windows/дотягувач_RYOBI_D-2005V.png"
import rol47 from "../../images/catalog/furnitura/windows/дотягувач_GU_OTS_210 .png"
import rol48 from "../../images/catalog/furnitura/windows/тяга_до_дотягувача_RYOBI D-1504.png"
import rol49 from "../../images/catalog/furnitura/windows/шина_GEZE_TS_3000.png"
import rol50 from "../../images/catalog/furnitura/windows/ущільнювач_для_металопластикових_вікон.png"
import rol51 from "../../images/catalog/furnitura/windows/ущільнювач_для_порогу.png"
import rol53 from "../../images/catalog/furnitura/doors/1.ручка.png"
import rol54 from "../../images/catalog/furnitura/doors/2.ручка.png"
import rol56 from "../../images/catalog/furnitura/doors/3.ручка.png"
import rol57 from "../../images/catalog/furnitura/doors/4.ручка.png"
import rol58 from "../../images/catalog/furnitura/doors/5.ручка.png"
import rol59 from "../../images/catalog/furnitura/doors/6.ручка.png"
import rol60 from "../../images/catalog/furnitura/doors/7.ручка.png"
import rol61 from "../../images/catalog/furnitura/doors/8.ручка.png"
import rol63 from "../../images/catalog/furnitura/doors/9.ручка.png"
import rol64 from "../../images/catalog/furnitura/doors/1.замок.png"
import rol65 from "../../images/catalog/furnitura/doors/2.замок.png"
import rol66 from "../../images/catalog/furnitura/doors/13.замок.png"
import rol67 from "../../images/catalog/furnitura/doors/14.замок.png"
import rol68 from "../../images/catalog/furnitura/doors/15.петлі.png"
import rol69 from "../../images/catalog/furnitura/doors/16.петлі.png"
import rol70 from "../../images/catalog/furnitura/doors/17.петлі.png"
import rol71 from "../../images/catalog/furnitura/doors/18.накладка.png"
import rol73 from "../../images/catalog/furnitura/doors/19.накладка.png"
import rol74 from "../../images/catalog/furnitura/doors/20.накладка.png"
import rol75 from "../../images/catalog/furnitura/doors/21.накладка.png"
import rol7622 from "../../images/catalog/furnitura/doors/22.поворотнік_KEDR.png"
import rol7623 from "../../images/catalog/furnitura/doors/23.накладка.png"
import rol7624 from "../../images/catalog/furnitura/doors/24.поворотнік_KEDR.png"
import rol7625 from "../../images/catalog/furnitura/doors/25.накладка.png"
import rol7626 from "../../images/catalog/furnitura/doors/26.ручка.png"
import rol7627 from "../../images/catalog/furnitura/doors/27.ручка.png"
import rol7628 from "../../images/catalog/furnitura/doors/28.ручка.png"
import rol7629 from "../../images/catalog/furnitura/doors/29.ручка.png"
import rol7630 from "../../images/catalog/furnitura/doors/30.ручка.png"
import rol7631 from "../../images/catalog/furnitura/doors/31.ручка.png"
import rol7632 from "../../images/catalog/furnitura/doors/32.ручка.png"
import rol7633 from "../../images/catalog/furnitura/doors/33.ручка.png"
import rol7634 from "../../images/catalog/furnitura/doors/34.ручка.png"
import rol7635 from "../../images/catalog/furnitura/doors/35.ручка.png"
import rol7636 from "../../images/catalog/furnitura/doors/36.ручка.png"
import rol7637 from "../../images/catalog/furnitura/doors/37.ручка.png"
import rol7638 from "../../images/catalog/furnitura/doors/38.ручка.png"
import rol7639 from "../../images/catalog/furnitura/doors/39.ручка.png"
import rol7640 from "../../images/catalog/furnitura/doors/40.ручка.png"
import rol7641 from "../../images/catalog/furnitura/doors/41.ручка.png"
import rol7642 from "../../images/catalog/furnitura/doors/42.ручка.png"
import rol7643 from "../../images/catalog/furnitura/doors/43.ручка.png"
import rol7644 from "../../images/catalog/furnitura/doors/44.ручка.png"
import rol7645 from "../../images/catalog/furnitura/doors/45.ручка.png"
import rol7646 from "../../images/catalog/furnitura/doors/46.ручка.png"
import rol7647 from "../../images/catalog/furnitura/doors/47.ручка.png"
import rol7648 from "../../images/catalog/furnitura/doors/48.ручка.png"
import rol7649 from "../../images/catalog/furnitura/doors/49.завіси.png"
import rol7650 from "../../images/catalog/furnitura/doors/50.завіси.png"
import rol7651 from "../../images/catalog/furnitura/doors/51.механізм.png"
import rol7652 from "../../images/catalog/furnitura/doors/52.механізм.png"
import rol7653 from "../../images/catalog/furnitura/doors/53.механізм.png"
import rol7654 from "../../images/catalog/furnitura/doors/54.шпінгалет.png"
import rol7655 from "../../images/catalog/furnitura/doors/55.упор_дверний.png"
import rol7656 from "../../images/catalog/furnitura/doors/56.упор_дверний.png"
import rol7657 from "../../images/catalog/furnitura/doors/57.упор_дверний.png"
import rol7658 from "../../images/catalog/furnitura/doors/58.упор_дверний.png"

// Колір
export enum FurnituraType {
    WINDOW = 'window',
    DOOR = 'door',
    
}
export const defaultFurnituraType = [
    { value: FurnituraType.WINDOW, label: 'Для металопластикових вікон та дверей' },
    { value: FurnituraType.DOOR, label: 'Для дверей' },
]

export const furnituraProducts = [
    
    { id: 700, image: rol1, name: "Ручка віконна Roto Swing secustik ", param1:"Колір: білий", price: '359', type: FurnituraType.WINDOW},
    { id: 701, image: rol2, name: "Ручка віконна Round ", param1:"Колір: білий/коричневий", price: '190',type: FurnituraType.WINDOW},
    { id: 702, image: rol3, name: "Ручка віконна Afina ", param1:"Колір: білий/коричневий", price: '79',type: FurnituraType.WINDOW},
    { id: 703, image: rol4, name: "Ручка віконна ELIT протизламна ", param1:"Колір: білий/коричневий", price: '171',type: FurnituraType.WINDOW},
    { id: 704, image: rol5, name: "Ручка віконна Premium(скруглена)", param1:"Колір: білий/коричневий", price: '79',type: FurnituraType.WINDOW},
    { id: 705, image: rol6, name: "Ручка віконна Premium(пряма)", param1:"Колір: білий/коричневий", price: '79',type: FurnituraType.WINDOW},
    { id: 706, image: rol7, name: "Ручка віконна Premium(ключ)", param1:"Колір: білий/коричневий", price: '259',type: FurnituraType.WINDOW},
    { id: 707, image: rol8, name: "Ручка віконна Premium антрацит RAL 7016 КЛЮЧ", param1: null, price: '319',type: FurnituraType.WINDOW},
    { id: 708, image: rol9, name: "Ручка двостороння асиметрична Premium(скруглена)", param1:"Колір: білий/коричневий", price: '271',type: FurnituraType.WINDOW},
    { id: 709, image: rol10, name: "Ручка двостороння симетрична Premium(скруглена)", param1:"Колір: білий/коричневий", price: '271',type: FurnituraType.WINDOW},
    { id: 710, image: rol11, name: "Ручка віконна Hoppe New York / Secustik", param1:"Колір: срібна", price: '285',type: FurnituraType.WINDOW},
    { id: 711, image: rol12, name: "Ручка віконна Hoppe New York / Secustik", param1:"Колір: бронза", price: '287',type: FurnituraType.WINDOW},
    { id: 712, image: rol13, name: "Ручка віконна Hoppe New York / Secustik", param1:"Колір: антрацит", price: '317',type: FurnituraType.WINDOW },
    { id: 713, image: rol14, name: "Ручка офісна пряма (500мм) пара", param1:"Колір: білий/коричневий", price: '678',type: FurnituraType.WINDOW},
    { id: 714, image: rol15, name: "Ручка офісна пряма (1000мм) пара", param1:"Колір: білий/коричневий", price: '1299',type: FurnituraType.WINDOW },
    { id: 715, image: rol16, name: "Ручка офісна пряма (500мм) пара", param1:"Колір: антрацит/срібний/чорний", price: '799',type: FurnituraType.WINDOW },
    { id: 716, image: rol17, name: "Ручка офісна пряма (1000мм) пара", param1:"Колір: антрацит/срібний/чорний", price: '1499',type: FurnituraType.WINDOW},
    { id: 717, image: rol18, name: "Ручка офісна пряма (500мм) нержавійка (кронштейн 9005)", param1:null, price: '1239',type: FurnituraType.WINDOW },
    { id: 718, image: rol19, name: "Ручка офісна пряма (1000мм) нержавійка (кронштейн 9005)", param1:null, price: '2579',type: FurnituraType.WINDOW},
    { id: 719, image: rol20, name: "Ручка офісна П-подібна (300 мм) пара", param1:"Колір: білий/коричневий", price: '710',type: FurnituraType.WINDOW},
    { id: 720, image: rol21, name: "Ручка офісна П-подібна (300 мм) пара", param1:"Колір: срібна", price: '740',type: FurnituraType.WINDOW },
    { id: 721, image: rol22, name: "Ручка курця (ракушка) C - подібна", param1:"Колір: білий/коричневий", price: '40',type: FurnituraType.WINDOW},
    { id: 722, image: rol23, name: "Дверний гарнітур Vorne 90 пружина", param1:"Колір: білий/коричневий", price: '439',type: FurnituraType.WINDOW},
    { id: 723, image: rol24, name: "Дверний гарнітур Vorne 85/92/200", param1:"Колір: білий", price: '365',type: FurnituraType.WINDOW },
    { id: 724, image: rol25, name: "Дверний гарнітур Vorne 85/92/200", param1:"Колір: антрацит", price: '410',type: FurnituraType.WINDOW},
    { id: 725, image: rol26, name: "Циліндр RIKO 40*40 ключ/ключ (ключ метал) лазер", price: '238', param1:null,type: FurnituraType.WINDOW},
    { id: 726, image: rol27, name: "Циліндр USK 45*45 ключ/ключ (ключ метал)", price: '335', param1:null,type: FurnituraType.WINDOW},
    { id: 727, image: rol28, name: "Циліндр ROTO S6 45*50 ключ/ключ (ключ метал)", price: '665', param1:null ,type: FurnituraType.WINDOW},
    { id: 728, image: rol29, name: "Накладка циліндра", price: '20', param1: "Колір: білий/коричневий",type: FurnituraType.WINDOW},
    { id: 729, image: rol30, name: "Петля Jocker 120кг", price: '689', param1:"Колір: білий",type: FurnituraType.WINDOW},
    { id: 730, image: rol31, name: "Петля Vorne 120кг(фторопласт втулка)", price: '739', param1:"Колір: білий/коричневий",type: FurnituraType.WINDOW},
    { id: 731, image: rol32, name: "Петля Aksis 120кг(фторопласт втулка)", price: '3755', param1:"Колір: білий" ,type: FurnituraType.WINDOW},
    { id: 732, image: rol33, name: "Петля віконна Vorne на раму верхня", price: '89', param1:null,type: FurnituraType.WINDOW },
    { id: 733, image: rol34, name: "Петля віконна Vorne на раму нижня", price: '80' , param1:null,type: FurnituraType.WINDOW},
    { id: 734, image: rol35, name: "Петля віконна Vorne на стулку нижня", price: '85' , param1:null ,type: FurnituraType.WINDOW},
    { id: 735, image: rol36, name: "Замок рейка Vorne 1600 25 85/92 защіпка + ригель", price: '615' , param1:null,type: FurnituraType.WINDOW},
    { id: 736, image: rol37, name: "Замок рейка Vorne 1600 35 85/92 защіпка + ригель", price: '670' , param1:null ,type: FurnituraType.WINDOW},
    { id: 737, image: rol38, name: "Замок рейка Vorne 1800 25 85/92 защіпка + ригель", price: '635' , param1:null,type: FurnituraType.WINDOW},
    { id: 738, image: rol39, name: "Привід п/відкидний Vorne (15,5)  700-1200мм.", price: '220' , param1:null,type: FurnituraType.WINDOW},
    { id: 739, image: rol40, name: "Зустрічна планка Vorne універсальна", price: '99' , param1:null,type: FurnituraType.WINDOW},
    { id: 740, image: rol41, name: "Балконна защіпка (магнітна)", price: '59' , param1:null,type: FurnituraType.WINDOW},
    { id: 741, image: rol42, name: "Обмежувач відкриття SIEGENIA FB-201 (стулка 501мм.-1750мм.)", price: '399' , param1:null,type: FurnituraType.WINDOW},
    { id: 742, image: rol43, name: "Ніжка фіксатор дверей 270 мм", price: '149', param1:null,type: FurnituraType.WINDOW },
    { id: 743, image: rol44, name: "Шпінгалет дверний підсилений з пластиковою ручкою", price: '209' , param1:null,type: FurnituraType.WINDOW},
    { id: 744, image: rol45, name: "Дотягувач RYOBI 9903 STD 2/3 до 65кг - 965 мм (тяга в комплекті)", price: '1359' , param1:null,type: FurnituraType.WINDOW},
    { id: 745, image: rol46, name: "Дотягувач RYOBI D-2005V 3/4/5 до 100 кг - 1250 мм (тяга в комплекті)", price:"2849", param1:null,type: FurnituraType.WINDOW},
    { id: 746, image: rol47, name: "Дотягувач GU OTS 210 2/3 до 80 кг - 1100 мм (тяга в комплекті)", price:"2399", param1:null ,type: FurnituraType.WINDOW},
    { id: 747, image: rol48, name: "Тяга до дотягувача RYOBI D-1504/1554 з фіксацією ", price:"1399", param1:null ,type: FurnituraType.WINDOW},
    { id: 748, image: rol49, name: "Шина GEZE TS 3000 ", price:"2010", param1:null ,type: FurnituraType.WINDOW},
    { id: 749, image: rol50, name: "Ущільнювач для металопластикових вікон 141-02 (від 15 м/пог)", price:"39", param1:null ,type: FurnituraType.WINDOW},
    { id: 750, image: rol51, name: "Ущільнювач для порогу (1,5м) ", price: '115' , param1:null,type: FurnituraType.WINDOW},

    ///Дверна фурнітура
    { id: 752, image: rol53, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1089' , param1:null,type: FurnituraType.DOOR},
    { id: 753, image: rol54, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1100' , param1:null,type: FurnituraType.DOOR},
    { id: 755, image: rol56, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1300' , param1:null ,type: FurnituraType.DOOR},
    { id: 756, image: rol57, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1400' , param1:null,type: FurnituraType.DOOR},
    { id: 757, image: rol58, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1150' , param1:null,type: FurnituraType.DOOR},
    { id: 758, image: rol59, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1020' , param1:null,type: FurnituraType.DOOR},
    { id: 759, image: rol60, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '1009' , param1:null,type: FurnituraType.DOOR},
    { id: 761, image: rol61, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '579' , param1:null,type: FurnituraType.DOOR},
    { id: 763, image: rol63, name: "Ручка для міжкімнатних дверей GAVROCHE", price: '450' , param1:null,type: FurnituraType.DOOR},
    { id: 764, image: rol64, name: "Замок для міжкімнатних дверей GAVROCHE", price: '239' , param1:null,type: FurnituraType.DOOR},
    { id: 765, image: rol65, name: "Замок для міжкімнатних дверей GAVROCHE", price: '241' , param1:null,type: FurnituraType.DOOR},
    { id: 766, image: rol66, name: "Замок для міжкімнатних дверей GAVROCHE", price: '79' , param1:null,type: FurnituraType.DOOR},
    { id: 767, image: rol67, name: "Замок для міжкімнатних дверей GAVROCHE", price: '215' , param1:null,type: FurnituraType.DOOR},
    { id: 768, image: rol68, name: "Замок для міжкімнатних дверей GAVROCHE", price: '307' , param1:null,type: FurnituraType.DOOR},
    { id: 769, image: rol69, name: "Петлі для міжкімнатних дверей GAVROCHE", price: '62' , param1:null,type: FurnituraType.DOOR},
    { id: 770, image: rol70, name: "Петлі для міжкімнатних дверей GAVROCHE", price: '66' , param1:null,type: FurnituraType.DOOR},
    { id: 771, image: rol71, name: "Петлі для міжкімнатних дверей GAVROCHE", price: '76' , param1:null,type: FurnituraType.DOOR},
    { id: 773, image: rol73, name: "Накладки для міжкімнатних дверей GAVROCHE", price: '261' , param1:null,type: FurnituraType.DOOR},
    { id: 774, image: rol74, name: "Накладки для міжкімнатних дверей GAVROCHE", price: '364' , param1:null,type: FurnituraType.DOOR},
    { id: 775, image: rol75, name: "Накладки для міжкімнатних дверей GAVROCHE", price: '272' , param1:null,type: FurnituraType.DOOR},
    { id: 776, image: rol7622, name: "Накладки для міжкімнатних дверей GAVROCHE", price: '413' , param1:null,type: FurnituraType.DOOR},
    { id: 777, image: rol7622, name: "Поворотнік KEDR", price: '365' , param1:null,type: FurnituraType.DOOR},
    { id: 778, image: rol7623, name: "Накладка під циліндр KEDR", price: '288' , param1:null,type: FurnituraType.DOOR},
    { id: 779, image: rol7624, name: "Поворотнік KEDR", price: '388' , param1:null,type: FurnituraType.DOOR},
    { id: 780, image: rol7625, name: "Накладка під циліндр KEDR", price: '307' , param1:null,type: FurnituraType.DOOR},
    { id: 781, image: rol7626, name: "Ручка на розете KEDR", price: '628' , param1:null,type: FurnituraType.DOOR},
    { id: 782, image: rol7627, name: "Ручка на розете KEDR", price: '657' , param1:null,type: FurnituraType.DOOR},
    { id: 783, image: rol7628, name: "Ручка на розете KEDR", price: '619' , param1:null,type: FurnituraType.DOOR},
    { id: 784, image: rol7629, name: "Ручка на розете KEDR", price: '652' , param1:null,type: FurnituraType.DOOR},
    { id: 785, image: rol7630, name: "Ручка на розете KEDR", price: '662' , param1:null,type: FurnituraType.DOOR},
    { id: 786, image: rol7631, name: "Ручка на розете KEDR", price: '739' , param1:null,type: FurnituraType.DOOR},
    { id: 787, image: rol7632, name: "Ручка на розете KEDR", price: '585' , param1:null,type: FurnituraType.DOOR},
    { id: 788, image: rol7633, name: "Ручка на розете KEDR", price: '657' , param1:null,type: FurnituraType.DOOR},
    { id: 789, image: rol7634, name: "Ручка на розете KEDR", price: '758' , param1:null,type: FurnituraType.DOOR},
    { id: 790, image: rol7635, name: "Ручка на розете KEDR", price: '571' , param1:null,type: FurnituraType.DOOR},
    { id: 791, image: rol7636, name: "Ручка на розете KEDR", price: '566' , param1:null,type: FurnituraType.DOOR},
    { id: 792, image: rol7637, name: "Ручка на розете KEDR", price: '628' , param1:null,type: FurnituraType.DOOR},
    { id: 793, image: rol7638, name: "Ручка на розете KEDR", price: '628' , param1:null,type: FurnituraType.DOOR},
    { id: 794, image: rol7639, name: "Ручка на розете KEDR", price: '633' , param1:null,type: FurnituraType.DOOR},
    { id: 795, image: rol7640, name: "Ручка на розете KEDR", price: '550' , param1:null,type: FurnituraType.DOOR},
    { id: 796, image: rol7641, name: "Ручка на розете KEDR", price: '580' , param1:null,type: FurnituraType.DOOR},
    { id: 797, image: rol7642, name: "Ручка на розете KEDR", price: '655' , param1:null,type: FurnituraType.DOOR},
    { id: 798, image: rol7643, name: "Ручка на розете KEDR", price: '635' , param1:null,type: FurnituraType.DOOR},
    { id: 799, image: rol7644, name: "Ручка на розете KEDR", price: '644' , param1:null,type: FurnituraType.DOOR},
    { id: 800, image: rol7645, name: "Ручка на розете KEDR", price: '644' , param1:null,type: FurnituraType.DOOR},
    { id: 801, image: rol7646, name: "Ручка на розете KEDR", price: '644' , param1:null,type: FurnituraType.DOOR},
    { id: 802, image: rol7647, name: "Ручка на розете KEDR", price: '684' , param1:null,type: FurnituraType.DOOR},
    { id: 803, image: rol7648, name: "Ручка на розете KEDR", price: '750' , param1:null,type: FurnituraType.DOOR},
    { id: 804, image: rol7649, name: "Завіси KEDR", price: '131' , param1:null,type: FurnituraType.DOOR},
    { id: 805, image: rol7650, name: "Завіси KEDR", price: '91' , param1:null,type: FurnituraType.DOOR},
    { id: 806, image: rol7651, name: "Механізм KEDR", price: '105' , param1:null,type: FurnituraType.DOOR},
    { id: 807, image: rol7652, name: "Механізм KEDR", price: '317' , param1:null,type: FurnituraType.DOOR},
    { id: 808, image: rol7653, name: "Механізм KEDR", price: '423' , param1:null,type: FurnituraType.DOOR},
    { id: 809, image: rol7654, name: "Шпінгалет KEDR", price: '91' , param1:null,type: FurnituraType.DOOR},
    { id: 810, image: rol7655, name: "Упор дверний", price: '124' , param1:null,type: FurnituraType.DOOR},
    { id: 811, image: rol7656, name: "Упор дверний", price: '240' , param1:null,type: FurnituraType.DOOR},
    { id: 812, image: rol7657, name: "Упор дверний", price: '187' , param1:null,type: FurnituraType.DOOR},
    { id: 813, image: rol7658, name: "Упор дверний", price: '230' , param1:null,type: FurnituraType.DOOR},
]