import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { interiorDoorProducts } from "../../../catalog/product_data/interior_door_data"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { MyButton } from "../../../shared/button/button"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { InteriorDoorCard } from "../../../landing_page/products/cards"
import { Background, CardItem, Image, Item, Text, TextItem, ItemName, settings, Margin60, ImageBlock, CardBlockMargin, Margin16 } from "../../card"
import { SecondaryModal } from "../../../modal/modal"

export const InteriorDoorProductPage = () => {
    const { id } = useParams()
    const product = interiorDoorProducts.find(w => w.id === parseInt(id as string))
    const currentProduct = interiorDoorProducts.find((p) => p.id === parseInt(id as string));
    const similarProducts = interiorDoorProducts.filter((p) => p.id !== parseInt(id as string) && p.brand === currentProduct?.brand);
    const navigate = useNavigate();
    const [showComponent0, setShowComponent0] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);
    return (
        <div>
            <Header></Header>
            <Background>
                <Margin60>
                    <ImageBlock>
                        {product?.image && <Image src={product.image}></Image>}
                        <CardBlock product={product}></CardBlock>
                    </ImageBlock>
                </Margin60>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
                <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
                <CategoryBlock>
                    <BlockName>Cхожі моделі</BlockName>
                    <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                        <ScrollBlock>
                            <Slider {...settings}>
                                {similarProducts.map((p) => (<InteriorDoorCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></InteriorDoorCard>))}
                            </Slider>
                        </ScrollBlock>
                    </div>
                </CategoryBlock>
            </Background>
            <SecondaryActions></SecondaryActions>
            <CallBackForm></CallBackForm>
            <Footer></Footer>
        </div>
    )
}

const CardBlock = ({ product }) => {

    return (
        <div>
            <CardBlockMargin>
                <ItemName>{product.name}</ItemName>
                <Margin16><Item>{product.param1}</Item>
                    <Item>Вартість: від {product.price} грн.</Item>
                </Margin16>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <CardItem key={product.id}>
                        <Item>Опис:</Item>
                        <TextItem>-{product.param2}.</TextItem>
                        <TextItem>-{product.description1}</TextItem>
                        <TextItem>-{product.description2}</TextItem>
                        {product.description3 && <TextItem>-{product.description3}</TextItem>}
                    </CardItem>
                    <div style={{ marginTop: '10px' }}><Text>{product.text}</Text></div>
                </div>
            </CardBlockMargin>
        </div>
    );
}

