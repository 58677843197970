import './categorybutton.scss';

const CategoryButton = ({children, short = false, right=false, left=false}) =>{

    return (
      
        <button className={`categorybutton ${short && 'short'} ${right && 'right'} ${left && 'left'}`}>
             {children}
        </button>

    );
  };
  
  export default CategoryButton;

  export const CategoryButtonMobile = ({children, short = false }) =>{

    return (
        <button className={`categorybutton_mobile ${short && 'short'}`}>
             {children}
        </button>

    );
  };