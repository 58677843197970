import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/system';
import {MyButton} from '../../shared/button/button';
import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { column1, column2, column3, column4 } from './selectorData';
import { Option } from './selectorData';
import { SecondaryModal } from '../../modal/modal';

const StyledRadio = styled(Radio)({
    color: '#F12B04',
    opacity: 0.5,
    '&.Mui-checked': {
        color: '#F12B04',
        opacity: 1
    },
    '& + .MuiTypography-root': {
        opacity: 0.7
    },
    '&.Mui-checked + .MuiTypography-root': {
        opacity: 1
    }
});

const StyledFormControlLabel = styled(FormControlLabel)({
    color: '#474747',
    marginRight: '60px',
});

const StyledRadioGroup = styled(RadioGroup)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const StyledBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }));
    

const columns: Option[][] = [column1, column2, column3, column4];


export const Selector = ({ onOptionClick }) => {
    const [showComponent0, setShowComponent0] = useState(false);
    return (
        <FormControl>
            <div>
                <div>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#474747' }}>Тип вікна</FormLabel>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <SelectColumns onOptionClick={onOptionClick}></SelectColumns>
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#474747' }}>Тип приміщення</FormLabel>
                    <StyledRadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="Балкон"
                    >
                        <StyledFormControlLabel value="Балкон" control={<StyledRadio />} label="Балкон" />
                        <StyledFormControlLabel value="Квартира" control={<StyledRadio />} label="Квартира" />
                        <StyledFormControlLabel value="Будинок" control={<StyledRadio />} label="Будинок" />
                        <StyledFormControlLabel value="Комерція" control={<StyledRadio />} label="Комерція" />
                    </StyledRadioGroup>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#474747' }}>Підвіконня</FormLabel>
                    <StyledRadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="Так"
                    >
                        <StyledFormControlLabel value="Так" control={<StyledRadio />} label="Так" />
                        <StyledFormControlLabel value="Ні" control={<StyledRadio />} label="Ні" />
                    </StyledRadioGroup>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: '#474747' }}>Монтажні роботи</FormLabel>
                    <StyledRadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="Так"
                    >
                        <StyledFormControlLabel value="Так" control={<StyledRadio />} label="Так" />
                        <StyledFormControlLabel value="Ні" control={<StyledRadio />} label="Ні" />
                    </StyledRadioGroup>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
                <MyButton name={' '} onClick={() => setShowComponent0(true)}></MyButton>
                <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
            </div>
        </FormControl>
    );
}

const SelectColumns = ({ onOptionClick }) => {
    const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
    const [selected, setSelectedOption] = useState<Option | null>(null);
    const openMenusRef = useRef<(HTMLElement | null)[]>([null, null, null, null]);
    const handleClick = (event: React.MouseEvent<HTMLElement>, columnIndex: number) => {
        setOpenMenuIndex(columnIndex === openMenuIndex ? null : columnIndex);
        openMenusRef.current[columnIndex] = event.currentTarget;
    };

    const handleClose = () => {
        setOpenMenuIndex(null);
    };

    const handleOpenSelector = (option: Option, columnIndex: number) => {
        setSelectedOption(option);
        onOptionClick(option)
        handleClose();
    };

    useEffect(() => {
        if (columns[1] && columns[1].length > 0) {
            setSelectedOption(columns[1][0]);
            onOptionClick(columns[1][0]);
        }
    }, []);

    return (
        <Box>
            {columns.map((column, columnIndex) => {
                const selectedOption = column.find(option => option.id === selected?.id);
                return (
                    <StyledBox key={`column-${columnIndex}`} >
                        <Button onClick={(event) => handleClick(event, columnIndex)}>
                            {selectedOption && (
                                <span
                                    style={{
                                        boxShadow: "0px 2px 7px #F12B04",
                                        opacity: 1,
                                    }}
                                >
                                    {selectedOption.icon && React.cloneElement(selectedOption.icon, { color: "#F12B04" })}
                                </span>
                            )}
                            {!selectedOption && column[0]?.icon}
                        </Button>
                        <Popover
                            id={`select-column${columnIndex + 1}-popover`}
                            open={openMenuIndex === columnIndex}
                            anchorEl={openMenusRef.current[columnIndex]}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            PaperProps={{
                                style: {
                                    width: columnIndex === columns.length - 1 ? "200px" : `${(columnIndex + 1) * 200}px`,
                                    maxHeight: 400,
                                    overflowY: "auto",
                                },
                            }}
                        >
                            <List>
                                {column.map((option) => (
                                    <ListItem
                                        key={option.id}
                                        onClick={() => handleOpenSelector(option, columnIndex)}
                                    >
                                        {option.icon && (
                                            <span
                                                style={{
                                                    opacity: selectedOption === option ? 1 : 0.3,
                                                }}
                                            >
                                                {option.icon && React.cloneElement(option.icon, { color: "#F12B04" })}
                                            </span>
                                        )}
                                        <ListItemText
                                            primary={<span style={{ color: "#474747" }}>{option.label}</span>}
                                            primaryTypographyProps={{
                                                style: {
                                                    display: "flex",
                                                    alignItems: "center",
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Popover>
                    </StyledBox>
                );
            })}
        </Box>
    );
};
