import rol1 from "../../images/catalog/rolety/screen/601.jpg"
import rol2 from "../../images/catalog/rolety/screen/602.jpg"
import rol3 from "../../images/catalog/rolety/screen/701.jpg"
import rol4 from "../../images/catalog/rolety/screen/702.jpg"
import rol5 from "../../images/catalog/rolety/screen/801.jpg"
import rol6 from "../../images/catalog/rolety/screen/802.jpg"
import rol7 from "../../images/catalog/rolety/screen/803.jpg"
import rol8 from "../../images/catalog/rolety/screen/804.jpg"
import rol9 from "../../images/catalog/rolety/screen/901.jpg"
import rol10 from "../../images/catalog/rolety/screen/902.jpg"
import rol11 from "../../images/catalog/rolety/screen/903.jpg"
import rol12 from "../../images/catalog/rolety/screen/904.jpg"


// Колір
export enum ScreenRollColor {
    ODNOTONNA = 'odnotonna',
    VIZERYNKOVA = 'vizerunkova',
    map = "map"
}
export const defaultScreenRollColor = [
    { value: ScreenRollColor.ODNOTONNA, label: 'Однотонна' },
    { value: ScreenRollColor.VIZERYNKOVA, label: 'Візерункова' },
]
export const screenRollPrices = { min: 2000, max: 2500 };

export const screenRollProducts = [
    
    { id: 3000, image: rol1, name: "Ролета SCR 601", price: '2064', color:ScreenRollColor.VIZERYNKOVA},
    { id: 3001, image: rol2, name: "Ролета SCR 602", price: '2064', color:ScreenRollColor.VIZERYNKOVA},
    { id: 3002, image: rol3, name: "Ролета SCR 701", price: '2138', color:ScreenRollColor.ODNOTONNA},
    { id: 3003, image: rol4, name: "Ролета SCR 702", price: '2138', color:ScreenRollColor.ODNOTONNA},
    { id: 3004, image: rol5, name: "Ролета SCR 801", price: '2212', color:ScreenRollColor.ODNOTONNA},
    { id: 3005, image: rol6, name: "Ролета SCR 802", price: '2212', color:ScreenRollColor.ODNOTONNA},
    { id: 3006, image: rol7, name: "Ролета SCR 803", price: '2212', color:ScreenRollColor.ODNOTONNA},
    { id: 3007, image: rol8, name: "Ролета SCR 804", price: '2212', color:ScreenRollColor.ODNOTONNA},
    { id: 3008, image: rol9, name: "Ролета SCR 901", price: '2286', color:ScreenRollColor.ODNOTONNA},
    { id: 3009, image: rol10, name: "Ролета SCR 902", price: '2286', color:ScreenRollColor.ODNOTONNA},
    { id: 3010, image: rol11, name: "Ролета SCR 903", price: '2286', color:ScreenRollColor.ODNOTONNA},
    { id: 3011, image: rol12, name: "Ролета SCR 904", price: '2286', color:ScreenRollColor.ODNOTONNA}
]