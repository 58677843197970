import { Header } from "../../../header/header"
import { SecondaryActions } from "../../../landing_page/secondary_actions/secondary_actions"
import { CallBackForm } from "../../../landing_page/callback_form/callback_form"
import { Footer } from "../../../footer/footer"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { BlockName } from "../../../shared/blockName/blockName"
import Slider from "react-slick";
import { CategoryBlock, ScrollBlock } from "../../../landing_page/products/window_products_onlanding"
import { Background, Image, Text, ItemName, settings, CardItem, Margin60, ImageBlock, CardBlockMargin, Margin16, SliderBlock } from "../../card"
import styled from "styled-components"
import { WindowsillProducts, brandParameters } from "../../../catalog/product_data/windowsill"
import { WindowsillCard } from "../../../landing_page/products/cards"
import { SecondaryModal } from "../../../modal/modal"
import { Dropdown } from "react-bootstrap"
import "./dropdown.scss"
import { MyButton } from "../../../shared/button/button"

export const WindowsillProductsCard = () => {
  const { id } = useParams()
  const product = WindowsillProducts.find(w => w.id === parseInt(id as string))
  const similarProducts = WindowsillProducts.filter((p) => p.id !== parseInt(id as string));
  const navigate = useNavigate();
  const [showComponent0, setShowComponent0] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div>
      <Header></Header>
      <Background style={{ marginBottom: "0px" }}>
        <Margin60>
          <ImageBlock>
            {product?.image && <Image src={product.image}></Image>}
            <CardBlock product={product}></CardBlock>
          </ImageBlock>
        </Margin60>
        <SecondaryModal show={showComponent0} onHide={() => setShowComponent0(false)} />
        <MyButton name={"Розрахувати вартість"} onClick={() => setShowComponent0(true)}></MyButton>
        <CategoryBlock>
          <BlockName>Cхожі моделі</BlockName>
          <SliderBlock>
            <ScrollBlock>
              <Slider {...settings}>
                {similarProducts.map((p) => (<WindowsillCard key={p.id} id={p.id} image={p.image} name={p.name} param1={p.param1} param2={p.param2} param3={p.param3} price={p.price}></WindowsillCard>))}
              </Slider>
            </ScrollBlock>
          </SliderBlock>
        </CategoryBlock>
      </Background>
      <SecondaryActions></SecondaryActions>
      <CallBackForm></CallBackForm>
      <Footer></Footer>
    </div>
  )
}

const CardBlock = ({ product }) => {
  const currentBrandParameters = brandParameters[product.brand];
  const [selectedWidth, setSelectedWidth] = useState('');
  const handleWidthChange = (newWidth) => {
    setSelectedWidth(newWidth);
  };
  return (
    <div>
      <CardBlockMargin>
        <ItemName>{product.name}</ItemName>
        <Margin16>
          <Item style={{ width: '350px' }}>{product.param1}</Item>
          <Item>{product.param2}</Item>
          <Item>{product.param3}</Item>
          <Item>Декор: який на фото</Item>
          <Item>Капінос: {product.param4}</Item>
          {product.param5 && <Item>Німецьке покриття: {product.param5}</Item>}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <Text><TextRed>Важливо!</TextRed>Колір товару може відрізнятися від представленого на екрані.</Text>
          </div>
        </Margin16>
        <Description currentBrandParameters={currentBrandParameters} onWidthChange={handleWidthChange} />
      </CardBlockMargin>
    </div>
  );
}


const Description = ({ currentBrandParameters, onWidthChange }) => {
  const { text1, text2, values, zaglyshkaPrice } = currentBrandParameters;
  const [selectedWidth, setSelectedWidth] = useState('150');

  const handleWidthChange = (newWidth) => {
    setSelectedWidth(newWidth);
    onWidthChange(newWidth);
  };

  const getPriceByWidth = (width) => {
    const selectedValue = values.find((item) => item.width === width);
    return selectedValue ? selectedValue.price : '';
  };

  useEffect(() => {
    handleWidthChange(selectedWidth);
  }, [selectedWidth]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <CardItem>
        <Text style={{ marginBottom: '10px' }}>{text1}</Text>
        <Text style={{ marginBottom: '10px' }}>{text2}</Text>
        {currentBrandParameters.text3 && <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text3}</Text>}
        {currentBrandParameters.text4 && <Text style={{ marginBottom: '10px' }}>{currentBrandParameters.text4}</Text>}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Item>Прайс:</Item>
          <SmallItem>Ширина х м.п</SmallItem>
          <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0px', alignItems: 'center' }}>
            <Dropdown onSelect={handleWidthChange}>
              <Dropdown.Toggle variant="secondary" className="artden-toggle">
                {selectedWidth}
              </Dropdown.Toggle>
              <Dropdown.Menu className="artden-menu">
                {values.map((item) => (
                  <Dropdown.Item key={item.width} eventKey={item.width}>
                    {item.width}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {selectedWidth && (
              <SmallItem style={{ marginLeft: '10px' }}>{getPriceByWidth(selectedWidth)} грн.</SmallItem>
            )}
          </div>
          {zaglyshkaPrice && <SmallItem>Заглушка: {zaglyshkaPrice} грн.</SmallItem>}
        </div>
      </CardItem>
    </div>
  );
};


export const Parameter = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color:#5A5B5D;
`
export const Item = styled(Parameter)`
    font-size: 25px;
`
export const SmallItem = styled(Item)`
    font-size: 20px;
    @media(max-width: 768px){
      font-size: 15px;
      font-weight: 500;
    }
`
export const TextRed = styled(Text)`
    color: #F12B04;
`

