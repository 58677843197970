import styled from "styled-components"

export const Project=({image, name, year})=>{
    return <ProjectItem>
            <Image src={image}></Image>
            <Name>{name}</Name>
            <Year>{year}</Year>
    </ProjectItem>
}

const ProjectItem = styled.div`
    display: flex;
    width: 420px;
    height: 505px;
    background: #F5F3F4;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0px;
    }
    &:hover{
        background-color: white;
        transition: all .3s ease;
    }
    @media (max-width: 768px) {
    width:310px;
    margin-right:10px;
    height: 320px;
    }
`
const Image=styled.img`
    width: 310px;
    height: 360px;
    margin: 28px;
    @media (max-width: 768px) {
    width:290px;
    margin:10px;
    height: 250px;
    }
`
const Name=styled.div`
    display: flex;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color:#5A5B5D;
    margin-left: 28px;
    margin-top: -8px;
    @media (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    margin-left:10px
    }
`
const Year=styled(Name)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
    margin-top:5px
    }
`
