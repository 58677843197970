import React from "react";
import { ButtonText } from "./catalog_page_components";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Button, styled } from '@mui/material';

type ArtDenDrawerProps = {
    children: React.ReactNode;
};

export const ArtDenDrawer: React.FC<ArtDenDrawerProps> = ({ children }) => {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, left: open });
    };
    return (
        <div>
            <div style={{ margin: "-20px 0px 10px -185px" }}>
                <FilterButton onClick={toggleDrawer(true)}><ButtonText>Фільтри</ButtonText></FilterButton>
            </div>
            <StyledDrawer
                anchor="left"
                open={state.left}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    {children}
                    <Divider />
                </Box>
            </StyledDrawer>
        </div>
    );
}

const StyledDrawer = styled(Drawer)`
  @media (max-width: 768px) {
    width: 200px;
  }
`;

export const FilterButton = styled(Button)({
    color: "#5A5B5D",
    border: "1px solid #5A5B5D",
    width: "100px",
});