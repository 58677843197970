import './button.scss';

export const MyButton = ({name, onClick, disabled = false}) => {
    const sendData = (e) => {
        const value = e.target.value
        onClick(value)
    }
    return (
        <button name={name} onClick={sendData} disabled={disabled} className='mybutton'>
            Розрахувати вартість
        </button>
    );
};

export const Modal_Button = ({name, onClick, disabled = false}) => {
    const sendDataModal = (e) => {
        const value = e.target.value
        onClick(value)
    }
    return (
        <button name={name} onClick={sendDataModal} disabled={disabled} className='modalbutton'>
            Розрахувати вартість
        </button>
    );
};