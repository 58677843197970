import Slider from "react-slick";
import styled from "styled-components"
import { Name } from "../../../landing_page/products/product_card"
import { useState } from "react"
import { SlickArrowLeft, SlickArrowRight } from "../../../shared/slider/slider_arrows";
import { kriplennyaData } from "./kriplennya_data";


export const Kriplennya = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideCount = KripItem.length;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        nextArrow: <SlickArrowRight currentSlide={currentSlide} slideCount={slideCount} />,
        prevArrow: <SlickArrowLeft currentSlide={currentSlide} slideCount={slideCount} />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <div>
            <SliderBlobk>
                <ScrollBlock >
                    <Slider {...settings}>
                        {kriplennyaData.map((p) => (<KripItem key={p.id} name={p.name} image={p.image} ></KripItem>))}
                    </Slider>
                </ScrollBlock>
            </SliderBlobk>
        </div>
    )
}
export const KripItem = ({ image, name }) => {
    return (
        <KriplennyaItem>
            <Name>{name}</Name>
            <Img src={image} alt={name} />
        </KriplennyaItem>
    );
};

const KriplennyaItem = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 300px;
    margin:0px 20px;
    &:last-child{
        margin-bottom:0px;
    }
    @media (max-width:768px){
        margin:0px 30px;
        width:200px;
        align-items:center;
    }
`
const Img = styled.img`
    width:300px;
    @media (max-width:768px){
        width:200px;
    }
`
export const ScrollBlock = styled.div`
    width: 355px;
    @media (max-width: 768px) {
    width: 260px;
    }
`
const SliderBlobk = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    width:410px;
    @media (max-width: 768px) {
    width: 320px;
    }
`