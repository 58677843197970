import product1 from "../../images/product_card/1.jpg"
import product2 from "../../images/product_card/wds400.jpg"
import product3 from "../../images/product_card/rehau.webp"
import product4 from "../../images/product_card/5sjpg.jpeg"
import product5 from "../../images/product_card/rehau70.webp"
import product6 from "../../images/product_card/7s.jpg"
import product7 from "../../images/product_card/8s.png"
import product8 from "../../images/product_card/geneo.jpg"
import product9 from "../../images/product_card/synego.jpg"
import a1 from "../../images/catalog/windows/белій.png"
import a2 from "../../images/catalog/windows/золотой_дуб.jpeg"
import a3 from "../../images/catalog/windows/антрацит.jpeg"
import a4 from "../../images/catalog/windows/вишня.jpeg"
import a5 from "../../images/catalog/windows/горіх.jpeg"
import a6 from "../../images/catalog/windows/дубшеффілд.jpeg"
import a7 from "../../images/catalog/windows/дубмонтана.jpeg"
import a8 from "../../images/catalog/windows/дуб_натуральнйи.jpeg"
import a9 from "../../images/catalog/windows/сосна.jpeg"
import a10 from "../../images/catalog/windows/дуб_рустикал.jpeg"
import a11 from "../../images/catalog/windows/червоний_осінній.jpeg"
import a12 from "../../images/catalog/windows/зелений_лісовий.jpeg"
import a13 from "../../images/catalog/windows/сіній_морський.jpeg"


export enum WindowBrands {
    WDS = 'WDS',
    REHAU = 'Rehau'
}
export const defaultWindowBrands = [
    { value: WindowBrands.WDS, label: 'WDS' },
    { value: WindowBrands.REHAU, label: 'Rehau' }
]


export enum WindowType {
    TREECAMERS = '3 повітряні камери ',
    FIVECAMERS = '5 повітряних камер ',
    SIXCAMERS = '6 повітряних камер ',
    SEVENCAMERS = '7 повітряних камер',
}

export const defaultWindowType = [
    { value: WindowType.TREECAMERS, label: '3 повітряні камери' },
    { value: WindowType.FIVECAMERS, label: '5 повітряних камер' },
    { value: WindowType.SIXCAMERS, label: '6 повітряних камер' },
    { value: WindowType.SEVENCAMERS, label: '7 повітряних камер' }
]
export const windowPrices = { min: 5000, max: 14000 };

export const windowProducts = [
    { id:5000,image: product1, name: "WDS Galaxy", brand: WindowBrands.WDS, param1: 'Монтажна ширина: 70 мм', param2: 'Склопакет 24-32 мм', type: WindowType.TREECAMERS, param3: '3 камери', price: '5402', text1:'WDS дає змогу споживачам встановлювати якісні вікна за приємною ціною. Найбюджетніший варіант - це профіль Galaxy.',text2:'Galaxy – це хороший варіант для квартир, комірних та гаражних приміщень.', about:'Монтажна глибина 60 мм. TPV-ущільнювач призначений для забезпечення водостійкості і щільності, що перешкоджає проникненню вітру та шум. Можлива установка скла товщиною 6 мм і склопакетів розміром 24-32 мм для підвищення шумо- і теплоізоляції. Гладкий притвор між рамою і стулкою має 2° нахил для кращого відведення води і легкого очищення. Армуючий профіль в рамі, стулці і імпості забезпечує міцність конструкції. Опір теплопередачі 0,704-0,717 м2C/Вт.' },
    { id:5001,image: product2, name: "WDS 5S", brand: WindowBrands.WDS, param1: 'Монтажна ширина: 60 мм', param2: 'Склопакет 24-32 мм', type: WindowType.FIVECAMERS, param3: '5 камер', price: '5539' , text1:'Приємна ціна за стандартне вікно. Високий показник тепло та шумоізоляції для конструкції даного класу.', text2:'Профільна система 5S підійде для скління квартир в багатоквартирних будинках, виготовлення балконних дверей і офісних перегородок.', about:'Встановлення вікон на базі серії WDS 5S забезпечує ефективне енергозбереження. Монтажна глибина 60 мм. TPV-ущільнювач призначений для забезпечення водостійкості і щільності, що перешкоджає проникненню вітру та шум. Склопакетів розміром 24-32 мм для підвищення шумо- і теплоізоляції. Армуючий профіль в рамі, стулці і імпості забезпечує міцність конструкції. Також можна обрати посилене армування Опір теплопередачі 0,80-0,87 м2C/Вт.'},
    { id:5002,image: product3, name: "Rehau Euro 60", brand: WindowBrands.REHAU, param1: 'Монтажна ширина: 60 мм', param2: 'Склопакет 24-33 мм', type: WindowType.TREECAMERS, param3: '3 камери', price: '6061' , text1:'Стандартна профільна система Euro 60 для скління приміщень, де тепло- та шумоізоляціє не є пріоритетом.', text2:'Бюджетний варіант з хорошою характеристикою.', about:'Монтажна глибина 60 мм. Максимальна товщина склопакету до 33 мм - дозволяє встановити двокамерний склопакет TPV-ущільнювач призначений для забезпечення водостійкості і щільності, що перешкоджає проникненню вітру та шум. Коефіцієнт опору теплопередачі Rf = 0,65 м²К/Вт - клас Б2 Зламобезпека до класу RC2 Звукоізоляція - Rw,P = 41 дБ.'},
    { id:5003,image: product4, name: "WDS 6S", brand: WindowBrands.WDS, param1: 'Монтажна ширина: 70 мм', param2: 'Склопакет 24-33 мм', type: WindowType.FIVECAMERS, param3: '5 камер', price: '5776' , text1:'Покращена версія. Пропускають більше світла, 6 камер надійно оберігають тепло, стильно виглядають у Вашій квартирі.', text2:'Ще більше тепла в оселі. Мінімізує тепло втрати. Вклад в майбутнє, адже це зменшить (комунальні) показники💲.', about:'Wds 6s – оптимальний варіант для багатоповерхових будинків. 6 повітряних камер профілю покращена енергоефективність. Збільшена проща склопакету добавляє кімнаті більше світла та  візуально простору. Характеристики профілю і його відповідність вимогам ДСТУ дозволяють без обмежень встановлювати металопластикові вікна з WDS 6S в житлові приміщення в Україні.'},
    { id:5004,image: product5, name: "Rehau Euro 70", brand: WindowBrands.REHAU, param1: 'Монтажна ширина: 70 мм', param2: 'Склопакет 24-44 мм', type: WindowType.FIVECAMERS, param3: '5 камер', price: '6583', text1:'Euro 70 ідеальний варіант для квартир. 5-ти камерна профільна система.',text2:'Профільна система з хорошими показниками енергоефективності за відносно незначні кошти.',about:'Максимальна товщина склопакету до 44 мм - такий склопакет дозволяє досягнути енергоефективності класу А+++ всієї конструкції. Широкий вибір ламінацій. Також можна обрати фурнітуру, яка буде найбільше вас задовільняти. Стандартний вибів – Axor українського виробництва, Siegenia favorit або її покращена версія Siegenia titan. У даному профілі Коефіцієнт опору теплопередачі профілю* Rf = 0,77-0,80 м²K/Вт Звукоізоляція Rw,P = 43 дБ Зламобезпека до класу RC2 - базова система безпеки.' },
    { id:5005,image: product6, name: "WDS 7S", brand: WindowBrands.WDS, param1: 'Монтажна ширина: 70 мм', param2: 'Склопакет 24-40 мм', type: WindowType.SIXCAMERS, param3: '6 камер', price: '6504' , text1:'Еталонне енергозберігаюче вікно. 3 з 6 повітряних камер розташовані з фасадної сторони.', text2:'Максимальний захист від промерзання. Шумоізоляція на вищому рівні – тиша та спокій у Вашому домі.', about:'Wds 7s –Хороший вибір для будинків та квартир. Завдяки тому, що система спроектована по формулі 70/40/6, з новим вікном ви отримаєте теплоізоляцію найвищого рівня та надійний захист від промерзань. Дизайн вікна можна обрати контретно під інтер’єр. Вибір квадратного або заокругленого штапіка підтримає ваші дизайнерські рішення.'},
    { id:5006,image: product7, name: "WDS 8S", brand: WindowBrands.WDS, param1: 'Монтажна ширина: 82 мм', param2: 'Склопакет 32-44 мм', type: WindowType.SIXCAMERS, param3: '6 камер', price: '9116' , text1:'Першокласні вікна для вашої оселі. В 2,5 разів тепла зберігається більше. Шумоізоляція на найвищому рівні.', text2:'Узимку - тепло, а влітку – прохолодно.'},
    { id:5007,image: product8, name: "Rehau Geneo", brand: WindowBrands.REHAU, param1: 'Монтажна ширина: 86 мм', param2: 'Склопакет 32-51 мм', type: WindowType.SEVENCAMERS, param3: '7 камер', price: '12680', text1:'Новий рівень профільних систем, який поєднує в собі унікальні характеристики. Більше тепла, світла та архітектурних можливостей.', text2:'Надсучасна рецептура профілю! Надвеликі розміри! Надзвичайна енергоефективність!', about:'GENEO RAU-FIPRO X — це новий рівень профільних систем, який поєднує в собі унікальні характеристики. Ще більше тепла, світла та архітектурних можливостей. Система преміумкласу на основі інноваційного фіброармування поєднує легкість та максимальну міцність. Більшість конструкцій не потребують сталевого армування. Надсучасна рецептура профілю! Надвеликі розміри! Надзвичайна енергоефективність! Надзвичайна міцність: +50% скловолокна для максимальної міцності та жорсткості конструкцій Geneo. Ще більше світла: екстравеликі розміри для нових архітектурних можливостей. Клас енергоефективності рівня Passive House.' },
    { id:5008,image: product9, name: "Rehau Synego", brand: WindowBrands.REHAU, param1: 'Монтажна ширина: 80 мм', param2: 'Склопакет 32-53 мм', type: WindowType.SIXCAMERS, param3: '6 камер', price: '10540' , text1:'Металопластикові конструкції з цього профілю мають значно вищі показники енергоефективності, шумоізоляції та безпеки.', about:'Sinego 80 - преміальна профільна система, яка задовольняє найвищі технічні та естетичні вимоги елітного будівництва. Металопластикові конструкції з цього профілю мають значно вищі показники енергоефективності, шумоізоляції та безпеки. Особлива поверхня профілю - HDF надає особливий, наче глянцевий, вигляд вікнам та незвичну легкість в очищені від бруду. Обираючи вікна чи двері з такої профільної системи ви приймаєте відмінно продумане інвестиційне рішення та отримуєте економію на опаленні до 50% Звукоізоляція до 46 дБ, яка буквально «вимикає» будь-який шум/ Зламобезпека до класу RC3 – підвищена система безпеки.'},
];
interface Option {
    id: string;
    label: string;
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    image?: string;
  }
export const windowColors : Option[]= [
    { id: "i1", label: "Білий", image:a1 },
    { id: "i2", label: "Золотий дуб", image:a2},
    { id: "i3", label: "Антрацит", image:a3 },
    { id: "i4", label: "Темна вишня", image:a4 },
    { id: "i5", label: "Горіх", image:a5},
    { id: "i6", label: "Дуб шеффілд", image:a6 },
    { id: "i7", label: "Дуб монтана", image:a7 },
    { id: "i8", label: "Дуб натуральний", image:a8 },
    { id: "i9", label: "Сосна", image:a9 },
    { id: "i10", label: "Дуб рустикал", image:a10 },
    { id: "i11", label: "Червоний осінній", image:a11 },
    { id: "i12", label: "Зелений лісовий", image:a12 },
    { id: "i13", label: "Синій морський", image:a13 },
  ] 