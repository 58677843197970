import door1 from "../../images/catalog/flat_doors/vashidveri/nitstsa-pg.jpeg"
import door2 from "../../images/catalog/flat_doors/vashidveri/florenciya_Po.jpg"
import door3 from "../../images/catalog/flat_doors/vashidveri/nitstsa-po.jpeg"
import door4 from "../../images/catalog/flat_doors/vashidveri/thumb_nitstsa-poo.jpeg"
import door5 from "../../images/catalog/flat_doors/vashidveri/thumb_florenciya_pg.jpg"
import door6 from "../../images/catalog/flat_doors/vashidveri/rim-poo.jpeg"
import door7 from "../../images/catalog/flat_doors/vashidveri/florenciya_Poo.jpeg"
import door8 from "../../images/catalog/flat_doors/vashidveri/london-pg.jpeg"
import door9 from "../../images/catalog/flat_doors/vashidveri/ft3-s.jpeg"
import door10 from "../..//images/catalog/flat_doors/vashidveri/ft9-s.jpeg"
import door11 from "../../images/catalog/flat_doors/vashidveri/a1.jpeg"
import door12 from "../../images/catalog/flat_doors/vashidveri/a2-1s.jpeg"
import door13 from "../../images/catalog/flat_doors/vashidveri/a2-3s.jpeg"
import door14 from "../../images/catalog/flat_doors/vashidveri/a3-2s.jpeg"
import door15 from "../../images/catalog/flat_doors/vashidveri/a4s.jpeg"
import door16 from "../../images/catalog/flat_doors/vashidveri/a12-1s.jpeg"
import door17 from "../../images/catalog/flat_doors/vashidveri/ft2-s.jpeg"
import door18 from "../../images/catalog/flat_doors/vashidveri/a2-2s.jpeg"
import door19 from "../../images/catalog/flat_doors/vashidveri/a5-4s.jpeg"
import door20 from "../../images/catalog/flat_doors/vashidveri/a8s.jpeg"
import door21 from "../../images/catalog/flat_doors/vashidveri/a22s.jpeg"
import door22 from "../../images/catalog/flat_doors/vashidveri/a23f.jpeg"
import door23 from "../../images/catalog/flat_doors/vashidveri/fl5.jpeg"
import door24 from "../../images/catalog/flat_doors/vashidveri/fl13.jpeg"
import door26 from "../../images/catalog/flat_doors/grando_porte/gp-19.png"
import door27 from "../../images/catalog/flat_doors/grando_porte/gp-22.png"
import door28 from "../../images/catalog/flat_doors/grando_porte/gp-67.png"
import door29 from "../../images/catalog/flat_doors/grando_porte/gp-69.png"
import door30 from "../../images/catalog/flat_doors/grando_porte/gp-16.png"
import door31 from "../../images/catalog/flat_doors/grando_porte/gp-25.png"
import door32 from "../../images/catalog/flat_doors/grando_porte/gp-26.png"
import door33 from "../../images/catalog/flat_doors/grando_porte/gp-10.png"
import door34 from "../../images/catalog/flat_doors/grando_porte/gp-11.png"
import door35 from "../../images/catalog/flat_doors/grando_porte/gp-17.png"
import door36 from "../../images/catalog/flat_doors/zahid_doors/elite-1.jpeg"
import door37 from "../../images/catalog/flat_doors/zahid_doors/forest1.jpeg"
import door38 from "../../images/catalog/flat_doors/zahid_doors/forest2.jpeg"
import door39 from "../../images/catalog/flat_doors/zahid_doors/GRAND2D1..3.jpeg"
import door40 from "../../images/catalog/flat_doors/zahid_doors/lines-4.jpeg"
import door41 from "../../images/catalog/flat_doors/zahid_doors/lines8.jpeg"
import door42 from "../../images/catalog/flat_doors/zahid_doors/lines-7.jpeg"
import door43 from "../../images/catalog/flat_doors/zahid_doors/molding2d.jpeg"
import door44 from "../../images/catalog/flat_doors/zahid_doors/MOLDING3В.jpeg"
import door45 from "../../images/catalog/flat_doors/zahid_doors/molding4g.png"
import door46 from "../../images/catalog/flat_doors/zahid_doors/glass.jpeg"
import door47 from "../../images/catalog/flat_doors/zahid_doors/ultra4.jpeg"
import door48 from "../../images/catalog/flat_doors/zahid_doors/ultra1.jpeg"
import door49 from "../../images/catalog/flat_doors/zahid_doors/glad.jpeg"
import door50 from "../../images/catalog/flat_doors/zahid_doors/elite2.jpeg"
import door51 from "../../images/catalog/flat_doors/NCD/мадрид.jpeg"
import door52 from "../../images/catalog/flat_doors/NCD/марроко.jpeg"
import door53 from "../../images/catalog/flat_doors/NCD/Монреаль.jpeg"
import door54 from "../../images/catalog/flat_doors/NCD/Прованс.jpeg"
import door55 from "../../images/catalog/flat_doors/NCD/Стелла1.jpeg"
import door56 from "../../images/catalog/flat_doors/NCD/Торонто.jpeg"
import door57 from "../../images/catalog/flat_doors/NCD/Бруклін.jpeg"
import door58 from "../../images/catalog/flat_doors/NCD/Віолла.jpeg"
import door59 from "../../images/catalog/flat_doors/NCD/Голівуд.jpeg"
import door60 from "../../images/catalog/flat_doors/NCD/Дельта.jpeg"
import door61 from "../../images/catalog/flat_doors/NCD/Каїр.jpeg"
import door62 from "../../images/catalog/flat_doors/still_doors/london.jpeg"
import door63 from "../../images/catalog/flat_doors/still_doors/nepal.jpeg"
import door64 from "../../images/catalog/flat_doors/still_doors/sofia.jpeg"
import door65 from "../../images/catalog/flat_doors/still_doors/antalia.jpeg"
import door66 from "../../images/catalog/flat_doors/still_doors/carolina.jpeg"
import door67 from "../../images/catalog/flat_doors/still_doors/loft-aluminium.jpeg"
import door68 from "../../images/catalog/flat_doors/still_doors/loft-glass.jpeg"

//--------Міжкімнатні двері
// Виробник
export enum InteriorDoorBrands {
    VASHIDVERI = 'Vashi Dveri',
    GRANDOPORTE = 'Grando Porte',
    ZAHIDDOORS = 'Zahid doors',
    STILDOOR = 'Stil Doors',
    NSD = 'NSD',
}
export const defaultInteriorDoorBrands = [
    { value: InteriorDoorBrands.VASHIDVERI, label: 'Ваші дрері' },
    { value: InteriorDoorBrands.GRANDOPORTE, label: 'Grando Porte' },
    { value: InteriorDoorBrands.ZAHIDDOORS, label: 'Zahid doors' },
    { value: InteriorDoorBrands.STILDOOR, label: 'Stil Doors' },
    { value: InteriorDoorBrands.NSD, label: 'НСД' },
]

//  Покриття
export enum InteriorDoorCover {
    FARBA = 'farba',
    PLIVKA = 'plivka'
}
export const defaultInteriorDoorCover = [
    { value: InteriorDoorCover.FARBA, label: 'Фарба' },
    { value: InteriorDoorCover.PLIVKA, label: 'Плівка' }
]
// Колір
export enum InteriorDoorColor {
    WHITE = 'biliy',
    BEIGE = 'beige',
    BROWN = 'brown',
    ANTRACIT = 'anthracit',
    GREY = 'grey',
    PINK = 'pink',
    BLUE = 'blue',
    BLACK = 'black',
    RED = 'red',
    GREEN = 'green'
}
export const defaultInteriorDoorColor = [
    { value: InteriorDoorColor.WHITE, label: 'Білий' },
    { value: InteriorDoorColor.BEIGE, label: 'Молочний' },
    { value: InteriorDoorColor.BROWN, label: 'Коричневий' },
    { value: InteriorDoorColor.ANTRACIT, label: 'Антрацит' },
    { value: InteriorDoorColor.GREY, label: 'Сірий' },
    { value: InteriorDoorColor.PINK, label: 'Рожевий' },
    { value: InteriorDoorColor.BLUE, label: 'Блакитний' },
    { value: InteriorDoorColor.BLACK, label: 'Чорно-білий' },
    { value: InteriorDoorColor.RED, label: 'Червоний' },
    { value: InteriorDoorColor.GREEN, label: 'Зелений' }
]
//Тип
export enum InteriorDoorType {
    SKLO = 'sklo',
    ODNOTONNI = 'odnotonni',
    MALUYNOK = 'maluynok',
    DZERKALO = 'dzerkalo',
}
export const defaultInteriorDoorType = [
    { value: InteriorDoorType.SKLO, label: 'Зі склом' },
    { value: InteriorDoorType.ODNOTONNI, label: 'Однотонні' },
    { value: InteriorDoorType.MALUYNOK, label: 'З малюнком' },
    { value: InteriorDoorType.DZERKALO, label: 'З дзеркалом' },
]
export const interiorDoorProducts = [
    //  Ваші двері
    //Прованс
    { id: 1, image: door1, name: "Ніцца ПГ", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7600', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 2, image: door2, name: "Мілан ПО", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10200', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 3, image: door3, name: "Ніцца ПО", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '9900', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.SKLO] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 4, image: door4, name: "Ніцца ПОО", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10300', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.PINK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 5, image: door5, name: "Флоренція ПГ", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7600', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 6, image: door6, name: "Рим ПОО", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10300', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 7, image: door7, name: "Флоренція ПОО", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10300', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 8, image: door8, name: "Лондон ПГ", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7600', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLUE, type: [InteriorDoorType.ODNOTONNI], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    //Aвангард
    { id: 9, image: door9, name: "FT3-S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '14415', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 10, image: door10, name: "FT9-S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '14415', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 11, image: door11, name: "A1 Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5450', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 12, image: door12, name: "A2-1S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8100', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 13, image: door13, name: "A2-3S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8700', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 14, image: door14, name: "A3-2S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7850', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 15, image: door15, name: "A4-1S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10790', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 16, image: door16, name: "A12-1S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '9600', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO && InteriorDoorType.MALUYNOK], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 17, image: door17, name: "FT2-S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '14400', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 18, image: door18, name: "A2-2S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8950', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 19, image: door19, name: "A5-4S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '9490', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 20, image: door20, name: "A8S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '13950', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO && InteriorDoorType.MALUYNOK] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками."},
    { id: 21, image: door21, name: "A22S Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '14960', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO && InteriorDoorType.MALUYNOK], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 22, image: door22, name: "A23F Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8865', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.MALUYNOK], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 23, image: door23, name: "FL5 Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10815', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.RED, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    { id: 24, image: door24, name: "FL13 Авангард", brand: InteriorDoorBrands.VASHIDVERI, param1: 'Виробник: Ваші двері', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10815', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREEN, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні дерев’яні двері, які виготовлені з високоякісної натуральної сировини та шпоновані для досягнення ідеальних зовнішніх характеристик, надійності та екологічності. Хоча вартість може бути високою через високоякісну обробку та складність виробництва, ми також пропонуємо доступну альтернативу - шпоновані двері з натурального та штучного матеріалу. Натуральний шпон забезпечує довговічність за рахунок його тонкої структури, тоді як штучний шпон має малюнок, що імітує дерев'яну текстуру на зовнішньому шарі, пройшовший просочення спеціальним складом. Обидва варіанти є чудовою альтернативою виробам з натурального дерева з важкими зовнішніми характеристиками." },
    //Grando Porte
    { id: 26, image: door26, name: "GP19", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8056', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.DZERKALO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 27, image: door27, name: "GP22", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '6919', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO && InteriorDoorType.DZERKALO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 28, image: door28, name: "GP67", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '6869', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 29, image: door29, name: "GP69", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '6869', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 30, image: door30, name: "GP16", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5947', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.DZERKALO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 31, image: door31, name: "GP25", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5526', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною."},
    { id: 32, image: door32, name: "GP26", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5526', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною."},
    { id: 33, image: door33, name: "GP10", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '6336', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO], description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною." },
    { id: 34, image: door34, name: "GP11", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7600', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною."},
    { id: 35, image: door35, name: "GP17F", brand: InteriorDoorBrands.GRANDOPORTE, param1: 'Виробник: Grando Porte', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5526', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO && InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] , description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:null, text:"Міжкімнатні двері Grando Porte виготовлені з високоякісного матеріалу, що складається з каркасу бруса та МДФ накладок, а покриття ламінованої ПВХ плівкою, що забезпечує міцність та довговічність. Ці двері мають високу шумоізоляцію, що робить їх ідеальним вибором для тих, хто цінує тишу та спокій у приміщенні. Виробництво відбувається в Україні, це висока якість та відповідність стандартам безпеки та екології. Grando Porte є гарним вибором для тих, хто шукає якісні та надійні міжкімнатні двері за доступною ціною."},
    //ZahidDoors
    { id: 36, image: door36, name: "Elite 1", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10420', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні." },
    { id: 37, image: door37, name: "Forest 1", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8657', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.MALUYNOK],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні." },
    { id: 38, image: door38, name: "Forest 2", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '9309', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.ANTRACIT, type: [InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 39, image: door39, name: "Grand 2D 1..3", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8657', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 40, image: door40, name: "Lines 4.1", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7270', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 41, image: door41, name: "Lines 8", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7270', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.ANTRACIT, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 42, image: door42, name: "Lines 7", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7270', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.ODNOTONNI && InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 43, image: door43, name: "Molding 2Г", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7300', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 44, image: door44, name: "Molding 3В", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '8100', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 45, image: door45, name: "Molding 4Г", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7690', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 46, image: door46, name: "Glass", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '6330', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 47, image: door47, name: "Ultra4", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '12847', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.BLACK, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 48, image: door48, name: "Ultra1", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '9215', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 49, image: door49, name: "Гладь", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    { id: 50, image: door50, name: "Elite2", brand: InteriorDoorBrands.ZAHIDDOORS, param1: 'Виробник: Zahid Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '10520', cover: InteriorDoorCover.FARBA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Zahid Doors стануть вигідним доповненням для інтер'єру, оформленого в світлих пастельних тонах. Ці двері мають елегантний дизайн з чіткими рівними лініями, вставкою з матового скла, яка має цікаву форму та вишуканий білий колір, що надає їм класичний вигляд. Марка Zahid Doors використовує лише високоякісну сировину та матеріали на виробництві міжкімнатних дверей, тому двері є відмінним вибором для тих, хто цінує якість та зовнішній вигляд своїх дверей. Вони забезпечують довговічність та зберігають свій привабливий зовнішній вигляд навіть при інтенсивному використанні."},
    //HCD
    { id: 51, image: door51, name: "Мадрид", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BEIGE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS."},
    { id: 52, image: door52, name: "Марокко", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7603', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 53, image: door53, name: "Монреаль", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7603', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 54, image: door54, name: "Прованс", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7402', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 55, image: door55, name: "Стелла 1", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 56, image: door56, name: "Торонто", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7603', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 57, image: door57, name: "Бруклін", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 58, image: door58, name: "Віолла", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 59, image: door59, name: "Голівуд", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7603', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 60, image: door60, name: "Дельта", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '5585', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.BROWN, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    { id: 61, image: door61, name: "Каїр", brand: InteriorDoorBrands.NSD, param1: 'Виробник:НСД', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '7402', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від НСД - це вибір тих, хто цінує високу якість та надійність. Завдяки використанню екологічно чистих матеріалів, вони не тільки безпечні для здоров'я, але й стійкі до впливу зовнішніх факторів.Технологічне обладнання, що використовується на фабриці, є найсучаснішим і відповідає європейським стандартам. Це забезпечує високу якість та точність виготовлення дверей, а також значно скорочує терміни їх виготовлення.Вибираючи міжкімнатні двері , ви отримуєте стильний та сучасний дизайн, що стане чудовим доповненням до будь-якого інтер'єру. Тож, якщо ви хочете мати надійні та якісні двері за доступною ціною, то вибір очевидний - міжкімнатні двері від НСД DOORS." },
    //StilDoors
    { id: 62, image: door62, name: "London", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '3819', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO],description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!" },
    { id: 63, image: door63, name: "Nepal", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '3546', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
    { id: 64, image: door64, name: "Sofia", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '4085', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
    { id: 65, image: door65, name: "Antalia", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '3770', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.GREY, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
    { id: 66, image: door66, name: "Carolina ", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '4198', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.ODNOTONNI] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
    { id: 67, image: door67, name: "Loft Aluminium", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '4199', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.MALUYNOK] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
    { id: 68, image: door68, name: "Loft Glass", brand: InteriorDoorBrands.STILDOOR, param1: 'Виробник:Stil Doors', param2: 'Висота полотна: 2000 мм', param3: 'Ширина полотна: на вибір', price: '4724', cover: InteriorDoorCover.PLIVKA, color: InteriorDoorColor.WHITE, type: [InteriorDoorType.SKLO] ,description1:"Ширина полотна: 600 / 700 / 800 / 900мм.", description2:"Розмір лиштви: 80х2200мм.", description3:"Розмір коробки: 75*2070мм.", text:"Міжкімнатні двері від Stil Doors стануть вигідним доповненням для інтер'єру, величезна різноманітність кольорів і відтінків дозволяє всіляко експериментувати з контрастами, що дозволяє легко вибрати оптимальний тип дверей для того чи іншого інтер'єру. Подібні вироби вдалим чином передають усю красу кольору, фактури та рельєфності натуральної деревини. Що стосується догляду за ними, то в порівнянні зі справжніми дерев'яними дверима тут все набагато простіше і практичніше. На більшість міжкімнатних дверей з нашого каталогу дверей ми підтримуємо постійний складський запас, тому Вам не доведеться чекати на замовлення кілька тижнів!"},
]
export const flatDoorPrices = { min: 3000, max: 15000 };
