import axios from "axios";

export const sendEmail = async (name, phone) => {
    return axios.post('https://www.artden.if.ua/wp-json/artden/v1/send_email', {
        name,
        phone,
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.data)
}