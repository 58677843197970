import styled from "styled-components"
import { BlockName } from "../../shared/blockName/blockName";
import partner1 from "../../images/partners/pehau1.png";
import partner2 from "../../images/partners/wds1.png";
import partner3 from "../../images/partners/veka.png";

export const Partners=()=>{
    return(
        <CategoryBlock>
            <BlockName> Наші партнери </BlockName>
            <Block>
                <Partner>
                    <ImageWrapper><Image image={partner1}></Image></ImageWrapper>
                    <ImageWrapper><Margin></Margin><Image image={partner3}></Image></ImageWrapper>
                    <ImageWrapper><Image image={partner2}></Image></ImageWrapper>
                </Partner>  
             </Block>   
        </CategoryBlock>
        
    );
};

const CategoryBlock=styled.div`
    display:flex;
    width: 100%;
    background-size: cover;
    background: #ffffff;
    justify-content: center;
    flex-direction: column;
`
const Partner=styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`
const Block=styled.div`
    display:flex;
    justify-content: center;
    margin-bottom: 60px;
    @media (max-width: 768px) {
        margin-bottom: 30px;
    }
`
const Margin=styled.div`
    margin: 0px 40px;
    @media (max-width: 768px) {
        margin: 0px;
    }
`
const Image=styled.div<{image: string}>`
    height: 100%;
    width: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.image});
`
const ImageWrapper=styled.div`
    width: 220px;
    height: 110px;
    @media (max-width: 768px) {
    width:110px;
    height: 55px;
    }
`