import styled from "styled-components";
import logo1 from "./../../images/logo.svg";

export const Logo = () => {
    return (
        <LogoContainer>
            <Img src={logo1} alt="Logo" />
        </LogoContainer>
    );
};

const LogoContainer = styled.div`
    width: 80px;
    @media (max-width: 768px){
        width:50px;
        height: 50px;
    }
`;

const Img = styled.img`
    @media (max-width: 768px){
        width: 50px;
    }
`